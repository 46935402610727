import React from 'react'
import { useNavigate, Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import {
	CRow,
	CCol,
	CContainer,
	CForm,
	CFormInput,
	CInputGroup,
	CInputGroupText,
	CButton,
	CImage,
	CLink,
	CWidgetStatsD,CWidgetStatsB,CWidgetStatsC,CWidgetStatsF,
	CCard,CCardHeader,CCardBody,CCardText,
	CProgress,
	CSpinner,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { CChart,CChartLine } from '@coreui/react-chartjs';
import { getStyle, hexToRgba } from '@coreui/utils';
import { isMobile } from "react-device-detect";
import {
	MyDialogview
} from '../components/index'
import { Konfirm } from '../helpers/onConfirm'
import { UFunc } from '../helpers/functions'

const pjson 	= require('../../package.json')
const Dashboard = (props) => {
	const {
		setLoading,showToast,prosesExpired,setSessionaktif
	} = props; 

	//--DOM--/
	const uNavigate 			= useNavigate();
	const uDispatch 			= useDispatch();
	//--END DOM--/

	const uBahasaObj  				= useSelector(state => state.listBahasa);
	const uActiveroute  			= useSelector(state => state.activeRoute);
	const uSettingObj 				= useSelector(state => state.gListUserSetting);
	const uIsScrollBottom			= useSelector(state => state.gIsScrollBottom);
	const uEfekapp					= useSelector(state => state.effectApp);
	const [uEfekview,setEfekview] 	= React.useState(false);
	const uMaxData					= isMobile ? (uSettingObj.jml_mobile || 10) : (uSettingObj.jml_tabel || 100);
	const uTokenObj					= JSON.parse(localStorage.getItem("token")||"{}");
	const [uHtml500msg,setHtml500msg]	= React.useState(); 
	const uKeywordObj				= JSON.parse(localStorage.getItem("listkeyword")) || {};
	
	const uHeaderActionObj  = useSelector(state => state.gInitHeaderAction);
	const uHandelView 		= uHeaderActionObj.isHeaderView || false;
	const uHandelTambah 	= uHeaderActionObj.isHeaderTambah || false;

	//--VIEWS_VARS--/
	const [uEfekview2,setEfekview2] 	= React.useState(false);
	const uDatacabangArr	= JSON.parse(localStorage.getItem("cabanglist")||"[]");
	const [uDatamainArr,setDatamainArr]		= React.useState([]);
	const [uISShowdokterfee,setShowdokterfee] 	= React.useState(false);

	//--TIDAK_DIPAKAI--/
	const [uDatawidgetObj,setDatawidgetObj]	= React.useState({});
	const [uDatagrafikArr,setDatagrafikArr]	= React.useState([]);
	const [uLoadingObj,setLoadingObj]		= React.useState({});
	const [uMsgvarObj,setMsgvarObj]			= React.useState({});
	//--END TIDAK_DIPAKAI--*/
	//--END VIEWS_VARS--*/

	//--DLG_VIEW--/
	const [uISDlgviewshow,setDlgviewshow]	= React.useState(false);
	const [uDlgviewObj,setDlgviewObj]		= React.useState({});
	//--END DLG_VIEW--/

	const uElcabang		= document.getElementById("inpcabangid")
	const uElperiode	= document.getElementById("inpperiode")

	const uTglnowDT		= UFunc.toDate()
	const uTglnowStr	= uTglnowDT.getFullYear()+"-"+UFunc.leadZero(uTglnowDT.getMonth()+1)+"-01";
	const uISMonthnotNOW	= ((uKeywordObj.filter_dashboard||{}).tgl_filter !== uTglnowStr)

	const uContentgrafikObj	= (uDatamainArr.filter(vItems=>
		(vItems.nama_vars||"").toUpperCase()===("grafik_omzet").toUpperCase()
	)||[])[0] || {};
	const uContentbirthdayObj	= (uDatamainArr.filter(vItems=>
		(vItems.nama_vars||"").toUpperCase()===("widget_birthday").toUpperCase()
	)||[])[0] || {};

	//--HANDEL--/
	const hdlKlikDetilBirthday = () => {
		if(uISDlgviewshow) { setDlgviewshow(false); return }
		if(uHtml500msg) return;

		const vContentObj	= (uDatamainArr.filter(vItems=>
			(vItems.nama_vars||"").toUpperCase()===("widget_birthday").toUpperCase()
			)||[])[0] || {};

		const vDataObj	= vContentObj.data_obj || {};
		const vDataArr	= vDataObj.data_arr || [];
		if(vDataArr.length <= 0) return;

		const vTglFilterDT = UFunc.toDate((uKeywordObj.filter_dashboard||{}).tgl_filter);
		const vTglfilterNowDT = UFunc.toDate(
			vTglFilterDT.getFullYear()+"-"+
			UFunc.leadZero(vTglFilterDT.getMonth()+1)+"-"+
			UFunc.leadZero(uTglnowDT.getDate())
			);

		uDlgviewObj.size 	= "";
		uDlgviewObj.headers = "Pasien Berulang Tahun: "+UFunc.HariAngka(vTglfilterNowDT);
		setDlgviewshow(true);
	}
	const hdlKlikRefreshparsial = (_NAMACARD) => {
		const vNamavars = _NAMACARD || "";

		//--HAPUS_LOCALSTORAGE--/
		const vKeywordObj		= JSON.parse(localStorage.getItem("listkeyword")) || {};
		const vFilterObj 		= vKeywordObj.filter_dashboard;
		const vTglfilter 		= vFilterObj.tgl_filter;
		const vCabangid 		= parseInt(vFilterObj.id_cabang);
		let vDatadashboardObj	= JSON.parse(localStorage.getItem("dashboard_data") || "{}");
		let vDataArr			= vDatadashboardObj.data_arr || [];

		const vDataidx 	= vDataArr.findIndex(vItems => {
			return (vItems.tgl_filter === vTglfilter
				&& vItems.nama_vars === vNamavars
				&& (parseInt(vItems.id_cabang)||0) === vCabangid
				&& (parseInt(vItems.id_dokter)||0) === (parseInt(uTokenObj.user_dokterid)||0)
				)
		});
		if(vDataidx >= 0) {
			vDataArr.splice(vDataidx,1)
			vDatadashboardObj.data_arr = vDataArr;

			localStorage.setItem("dashboard_data",JSON.stringify(vDatadashboardObj));
		}
		//--END HAPUS_LOCALSTORAGE--/

		//console.log("(Dashboard - hdlKlikRefreshparsial) _NAMACARD = "+_NAMACARD);
		apiLoadpartial(_NAMACARD);
	}
	//--END HANDEL--/

	//--INIT_FUNCTION--/
	const initRemoveexpired = () => {
		//--DIATUR_TIAP_TIGA_JAM_DIHAPUS_DATAFILTERNYA--/
		const vDatadashboardObj	= JSON.parse(localStorage.getItem("dashboard_data") || "{}");
		const vTimestampTS		= vDatadashboardObj.timestampTS;
		if(!vTimestampTS) return;

		const vThreehoursTS		= vTimestampTS + (3 * 60 * 60 * 1000);//--TIGA_JAM
		//const vThreehoursTS		= vTimestampTS + (60 * 1000);//--SATU_MENIT_TESTING
		const vTimenowTS		= (new Date()).valueOf();

		if(vTimenowTS > vThreehoursTS) {
			const vDataArr	= vDatadashboardObj.data_arr || [];
			const vTglsatuDT= new Date(uTglnowDT.getFullYear(),uTglnowDT.getMonth(),1);
			const vTglsatuTS= vTglsatuDT.valueOf();

			const vFilterArr = vDataArr.filter(vItems => {
				const vTglfilterTS 	= UFunc.toDate(vItems.tgl_filter).valueOf();
				return vTglfilterTS !== vTglsatuTS;
			})

			vDatadashboardObj.timestampTS	= undefined;
			vDatadashboardObj.data_arr 		= vFilterArr;
			localStorage.setItem("dashboard_data",JSON.stringify(vDatadashboardObj));
		}
		//--DITARUH_DIAWAL_APILOADDATA--/
	}

	//--TIDAK_DIPAKAI--/
	const initResetdata	= () => {
		setHtml500msg();
		setDatagrafikArr([]);
		//setDatawidgetObj({});

		uDatawidgetObj.pasien = null
		uDatawidgetObj.user = null
		uDatawidgetObj.antrian = null
		uDatawidgetObj.omzet = null
		uDatawidgetObj.omzetgrafik = null
		uDatawidgetObj.dokter = null
		uDatawidgetObj.nurse = null
		uDatawidgetObj.terapi = null

		uMsgvarObj.pasien = null
		uMsgvarObj.user = null
		uMsgvarObj.antrian = null
		uMsgvarObj.omzet = null
		uMsgvarObj.omzetgrafik = null
		uMsgvarObj.dokter = null
		uMsgvarObj.nurse = null
		uMsgvarObj.terapi = null

		uLoadingObj.pasien = false
		uLoadingObj.user = false
		uLoadingObj.antrian = false
		uLoadingObj.omzet = false
		uLoadingObj.omzetgrafik = false
		uLoadingObj.dokter = false
		uLoadingObj.nurse = false
		uLoadingObj.terapi = false
	}
	const initRespasien = (_OUTPUTOBJ) => {
		//setLoadingObj({...uLoadingObj,pasien:false})
		//console.log("(Dashboard - initRespasien) _OUTPUTOBJ : "+_OUTPUTOBJ);// return;
		uLoadingObj.pasien	= false;
		//uMsgvarObj.pasien	= _OUTPUTOBJ.info; return;

		if(_OUTPUTOBJ.tampil) {
			uDatawidgetObj.pasienobj = JSON.parse(_OUTPUTOBJ.datalist||"{}");
		} else if(_OUTPUTOBJ.info) {
			//setMsgvarObj({...uMsgvarObj,pasien: _OUTPUTOBJ.info})
			uMsgvarObj.pasien = _OUTPUTOBJ.info;
		} else if(_OUTPUTOBJ.errors) {
			console.log("(Dashboard - initRespasien) _OUTPUTOBJ.errors : "+_OUTPUTOBJ.errors);
			const vMsg = (pjson.mydefault.environment=="development")
				? _OUTPUTOBJ.errors : pjson.mydefault.msgFetchError;
			//setMsgvarObj({...uMsgvarObj,pasien: "<font color='red'>"+vMsg+"</font>"})
			uMsgvarObj.pasien = "<font color='red'>"+vMsg+"</font>";
		} else if(_OUTPUTOBJ.expired) {
			setLoading(true); prosesExpired(); return;
		}
	}
	const initResuser = (_OUTPUTOBJ) => {
		//setLoadingObj({...uLoadingObj,user:false})
		uLoadingObj.user	= false;
		//console.log("(Dashboard - initResuser) _OUTPUTOBJ : "+_OUTPUTOBJ);// return;

		if(_OUTPUTOBJ.tampil) {
			uDatawidgetObj.userobj = JSON.parse(_OUTPUTOBJ.datalist||"{}");
		} else if(_OUTPUTOBJ.info) {
			uMsgvarObj.user = _OUTPUTOBJ.info;
			//setMsgvarObj({...uMsgvarObj,user: _OUTPUTOBJ.info})
		} else if(_OUTPUTOBJ.errors) {
			console.log("(Dashboard - initResuser) _OUTPUTOBJ.errors : "+_OUTPUTOBJ.errors);
			const vMsg = (pjson.mydefault.environment=="development")
				? _OUTPUTOBJ.errors : pjson.mydefault.msgFetchError;
			//setMsgvarObj({...uMsgvarObj,user: "<font color='red'>"+vMsg+"</font>"})
			uMsgvarObj.user = "<font color='red'>"+vMsg+"</font>";
		} else if(_OUTPUTOBJ.expired) {
			setLoading(true); prosesExpired(); return;
		}
	}
	const initResantrian = (_OUTPUTOBJ) => {
		uLoadingObj.antrian	= false;

		if(_OUTPUTOBJ.tampil) {
			uDatawidgetObj.antrianobj = JSON.parse(_OUTPUTOBJ.datalist||"{}");
		} else if(_OUTPUTOBJ.info) {
			uMsgvarObj.antrian = _OUTPUTOBJ.info;
			//setMsgvarObj({...uMsgvarObj,antrian: _OUTPUTOBJ.info})
		} else if(_OUTPUTOBJ.errors) {
			console.log("(Dashboard - initResantrian) _OUTPUTOBJ.errors : "+_OUTPUTOBJ.errors);
			const vMsg = (pjson.mydefault.environment=="development")
				? _OUTPUTOBJ.errors : pjson.mydefault.msgFetchError;
			uMsgvarObj.antrian = "<font color='red'>"+vMsg+"</font>";
			//setMsgvarObj({...uMsgvarObj,antrian: "<font color='red'>"+vMsg+"</font>"})
		} else if(_OUTPUTOBJ.expired) {
			setLoading(true); prosesExpired(); return;
		}
	}
	const initResomzet = (_OUTPUTOBJ) => {
		uLoadingObj.omzet = false;
		if(_OUTPUTOBJ.tampil) {
			uDatawidgetObj.omzethariobj = JSON.parse(_OUTPUTOBJ.datahari||"{}");
			uDatawidgetObj.omzetbulanobj = JSON.parse(_OUTPUTOBJ.databulan||"{}");
		} else if(_OUTPUTOBJ.info) {
			//setMsgvarObj({...uMsgvarObj,omzet: _OUTPUTOBJ.info})
			uMsgvarObj.omzet = _OUTPUTOBJ.info;
		} else if(_OUTPUTOBJ.errors) {
			console.log("(Dashboard - initResomzet) _OUTPUTOBJ.errors : "+_OUTPUTOBJ.errors);
			const vMsg = (pjson.mydefault.environment=="development")
				? _OUTPUTOBJ.errors : pjson.mydefault.msgFetchError;
			//setMsgvarObj({...uMsgvarObj,omzet: "<font color='red'>"+vMsg+"</font>"})
			uMsgvarObj.omzet = "<font color='red'>"+vMsg+"</font>";
		} else if(_OUTPUTOBJ.expired) {
			setLoading(true); prosesExpired(); return;
		}
	}
	const initResomzetgrafik = (_OUTPUTOBJ) => {
		uLoadingObj.omzetgrafik = false;

		if(_OUTPUTOBJ.tampil) {
			uDatawidgetObj.omzetgrafikarr = JSON.parse(_OUTPUTOBJ.datalist||"{}");
		} else if(_OUTPUTOBJ.info) {
			uMsgvarObj.omzetgrafik = _OUTPUTOBJ.info;
			//setMsgvarObj({...uMsgvarObj,omzetgrafik: _OUTPUTOBJ.info})
		} else if(_OUTPUTOBJ.errors) {
			console.log("(Dashboard - initResomzetgrafik) _OUTPUTOBJ.errors : "+_OUTPUTOBJ.errors);
			const vMsg = (pjson.mydefault.environment=="development")
				? _OUTPUTOBJ.errors : pjson.mydefault.msgFetchError;
			uMsgvarObj.omzetgrafik = "<font color='red'>"+vMsg+"</font>";
			//setMsgvarObj({...uMsgvarObj,omzetgrafik: "<font color='red'>"+vMsg+"</font>"})
		} else if(_OUTPUTOBJ.expired) {
			setLoading(true); prosesExpired(); return;
		}
	}
	const initResdokter = (_OUTPUTOBJ) => {
		uLoadingObj.dokter = false;
		
		if(_OUTPUTOBJ.tampil) {
			uDatawidgetObj.dokterobj = JSON.parse(_OUTPUTOBJ.datalist||"{}");
		} else if(_OUTPUTOBJ.info) {
			uMsgvarObj.dokter = _OUTPUTOBJ.info;
			//setMsgvarObj({...uMsgvarObj,dokter: _OUTPUTOBJ.info})
		} else if(_OUTPUTOBJ.errors) {
			console.log("(Dashboard - initResdokter) _OUTPUTOBJ.errors : "+_OUTPUTOBJ.errors);
			const vMsg = (pjson.mydefault.environment=="development")
				? _OUTPUTOBJ.errors : pjson.mydefault.msgFetchError;
			//setMsgvarObj({...uMsgvarObj,dokter: "<font color='red'>"+vMsg+"</font>"})
			uMsgvarObj.dokter = "<font color='red'>"+vMsg+"</font>";
		} else if(_OUTPUTOBJ.expired) {
			setLoading(true); prosesExpired(); return;
		}
		setEfekview(!uEfekview);
	}
	const initResnurse = (_OUTPUTOBJ) => {
		uLoadingObj.nurse = false;

		if(_OUTPUTOBJ.tampil) {
			uDatawidgetObj.nurseobj = JSON.parse(_OUTPUTOBJ.datalist||"{}");
		} else if(_OUTPUTOBJ.info) {
			uMsgvarObj.nurse = _OUTPUTOBJ.info;
			//setMsgvarObj({...uMsgvarObj,nurse: _OUTPUTOBJ.info})
		} else if(_OUTPUTOBJ.errors) {
			console.log("(Dashboard - initResnurse) _OUTPUTOBJ.errors : "+_OUTPUTOBJ.errors);
			const vMsg = (pjson.mydefault.environment=="development")
				? _OUTPUTOBJ.errors : pjson.mydefault.msgFetchError;
			//setMsgvarObj({...uMsgvarObj,nurse: "<font color='red'>"+vMsg+"</font>"})
			uMsgvarObj.nurse = "<font color='red'>"+vMsg+"</font>";
		} else if(_OUTPUTOBJ.expired) {
			setLoading(true); prosesExpired(); return;
		}
	}
	const initResterapi = (_OUTPUTOBJ) => {
		uLoadingObj.terapi = false;

		if(_OUTPUTOBJ.tampil) {
			uDatawidgetObj.terapiobj = JSON.parse(_OUTPUTOBJ.datalist||"{}");
		} else if(_OUTPUTOBJ.info) {
			uMsgvarObj.terapi = _OUTPUTOBJ.info;
			//setMsgvarObj({...uMsgvarObj,terapi: _OUTPUTOBJ.info})
		} else if(_OUTPUTOBJ.errors) {
			console.log("(Dashboard - initResterapi) _OUTPUTOBJ.errors : "+_OUTPUTOBJ.errors);
			const vMsg = (pjson.mydefault.environment=="development")
				? _OUTPUTOBJ.errors : pjson.mydefault.msgFetchError;
			//setMsgvarObj({...uMsgvarObj,terapi: "<font color='red'>"+vMsg+"</font>"})
			uMsgvarObj.terapi = "<font color='red'>"+vMsg+"</font>";
		} else if(_OUTPUTOBJ.expired) {
			setLoading(true); prosesExpired(); return;
		}
	}
	const initResbirthday = (_OUTPUTOBJ) => {
		uLoadingObj.birthday	= false;
		//uMsgvarObj.pasien	= _OUTPUTOBJ.info; return;

		if(_OUTPUTOBJ.tampil) {
			//console.log("_OUTPUTOBJ.datalist => "+_OUTPUTOBJ.datalist);
			uDatawidgetObj.birthday_arr = JSON.parse(_OUTPUTOBJ.datalist||"[]");
		} else if(_OUTPUTOBJ.info) {
			//setMsgvarObj({...uMsgvarObj,pasien: _OUTPUTOBJ.info})
			uMsgvarObj.birthday = _OUTPUTOBJ.info;
		} else if(_OUTPUTOBJ.errors) {
			console.log("(Dashboard - initResbirthday) _OUTPUTOBJ.errors : "+_OUTPUTOBJ.errors);
			const vMsg = (pjson.mydefault.environment=="development")
				? _OUTPUTOBJ.errors : pjson.mydefault.msgFetchError;
			uMsgvarObj.birthday = "<font color='red'>"+vMsg+"</font>";
		} else if(_OUTPUTOBJ.expired) {
			setLoading(true); prosesExpired(); return;
		}
	}
	//--END TIDAK_DIPAKAI--/
	//--END INIT_FUNCTION--/

	//--CONTENT--/
	const contentSpinner = (_COLOR) => {
		_COLOR = _COLOR || "dark";

		return (
			<CCard className="h-100 classbgcard">
			<CCardBody className="d-flex justify-content-between align-items-center">
			<CSpinner color={_COLOR} className="mx-auto my-4"/>
			</CCardBody>
			</CCard>
		)//>
	}
	const contentWidgetreservasi = () => {
		if(uHtml500msg) return "";

		const vNamavars		= "widget_reservasi";
		const vContentObj	= (uDatamainArr.filter(vItems=>
			(vItems.nama_vars||"").toUpperCase()===(vNamavars).toUpperCase()
			)||[])[0] || {};

		if((vContentObj.is_loading||false)===true) return contentSpinner()

		if((vContentObj.msg_error||"")!=="") return(
			<CCard className="h-100 classbgcard">
			<CCardHeader className="py-1 classfontsmaller">
				<small className="fw-bolder">Reservasi</small>

				<span className="ms-1">&middot;</span>

				<CLink onClick={()=>hdlKlikRefreshparsial(vNamavars)} className="classcursorpointer">
				<CIcon icon="cilSync" className="classikonbox" height={18}/>
				</CLink>
			</CCardHeader>
			<CCardBody className="">
				<CCardText className="classpetunjuk p-0">
					<small>{UFunc.renderHTML(vContentObj.msg_error||"")}</small>
				</CCardText>
			</CCardBody>
			</CCard>
		)//>

		const vDataObj		= vContentObj.data_obj || {};
		const vPersenperiksa=( (parseInt(vDataObj.selesai)||0) / (parseInt(vDataObj.total)||1) ) * 100;

		return (
		<CWidgetStatsD
			icon={
				<Link to="/subantrian/antrianpasien">
				<CImage className="my-3" src={pjson.homepage+"api/images/menus_antrianpasien.png"} height={40} />
				</Link>
			}
			values={[
				{ title: "Reservasi", value: vDataObj.total || 0 },
				{ title: "PERIKSA", value: (vDataObj.selesai || 0)+" ("+Math.round(vPersenperiksa)+"%)" },
			]}
			style={{
				"--cui-card-cap-bg": "#FFB200",
				"--cui-card-bg" : "#FFFFFF55",
			}}
			className="h-100"/>
		)//>
	}
	const contentWidgetuser = () => {
		if(uHtml500msg) return "";

		const vNamavars		= "widget_user";
		const vContentObj	= (uDatamainArr.filter(vItems=>
			(vItems.nama_vars||"").toUpperCase()===(vNamavars).toUpperCase()
			)||[])[0] || {};

		if((vContentObj.is_loading||false)===true) return contentSpinner()

		if((vContentObj.msg_error||"")!=="") return(
			<CCard className="h-100 classbgcard">
			<CCardHeader className="py-1 classfontsmaller">
				<small className="fw-bolder">User Online</small>

				<span className="ms-1">&middot;</span>

				<CLink onClick={()=>hdlKlikRefreshparsial(vNamavars)} className="classcursorpointer">
				<CIcon icon="cilSync" className="classikonbox" height={18}/>
				</CLink>
			</CCardHeader>
			<CCardBody className="">
				<CCardText className="classpetunjuk p-0">
					<small>{UFunc.renderHTML(vContentObj.msg_error||"")}</small>
				</CCardText>
			</CCardBody>
			</CCard>
		)//>

		const vDataObj		= vContentObj.data_obj || {};
		const vTglfilterDT 	= UFunc.toDate((uKeywordObj.filter_dashboard||{}).tgl_filter || UFunc.DbDate());
		const vTglsatuDT	= new Date(uTglnowDT.getFullYear(),uTglnowDT.getMonth(),1);
		const vISBulanlalu	= vTglfilterDT.valueOf() < vTglsatuDT.valueOf();

		return (
		<CWidgetStatsD
			icon={
				<Link to="/subdasar/user">
				<CImage className="my-3" src={pjson.homepage+"api/images/menus_user.png"} height={40} />
				</Link>
			}
			values={[
				{ 
					title: vISBulanlalu?"Online per Bulan":"User Online",
					value: vDataObj.value||0
				},
			]}
			className="classbgcard h-100"/>
		)//>
	}
	const contentWidgetsatusehat = () => {
		if(uHtml500msg) return "";

		const vNamavars		= "widget_satusehat";
		const vContentObj	= (uDatamainArr.filter(vItems=>
			(vItems.nama_vars||"").toUpperCase()===(vNamavars).toUpperCase()
			)||[])[0] || {};

		if((vContentObj.is_loading||false)===true) return contentSpinner()

		if((vContentObj.msg_error||"")!=="") return(
			<CCard className="h-100 classbgcard">
			<CCardHeader className="py-1 classfontsmaller">
				<small className="fw-bolder">Status Satusehat</small>

				<span className="ms-1">&middot;</span>

				<CLink onClick={()=>hdlKlikRefreshparsial(vNamavars)} className="classcursorpointer">
				<CIcon icon="cilSync" className="classikonbox" height={18}/>
				</CLink>
			</CCardHeader>
			<CCardBody className="">
				<CCardText className="classpetunjuk p-0">
					<small>{UFunc.renderHTML(vContentObj.msg_error||"")}</small>
				</CCardText>
			</CCardBody>
			</CCard>
		)//>

		const vDataObj		= vContentObj.data_obj || {};
		const vTglfilterDT 	= UFunc.toDate((uKeywordObj.filter_dashboard||{}).tgl_filter || UFunc.DbDate());
		const vTglsatuDT	= new Date(uTglnowDT.getFullYear(),uTglnowDT.getMonth(),1);
		const vISBulanlalu	= vTglfilterDT.valueOf() < vTglsatuDT.valueOf();

		return (
		<CWidgetStatsD
			icon={
				<Link to="/subantrian/statuspasien">
				<CImage className="my-3" src={pjson.homepage+"api/images/icon_satusehat.png"} height={40} />
				</Link>
			}
			values={[
				{ 
					title: (vISBulanlalu?"Kirim Satusehat":"Satusehat Hari Ini"),
					value: UFunc.formatAngka(vDataObj.value)
				},
			]}
			style={{ 
				"--cui-card-cap-bg": "#a5d8dd",
			}}
			className="classbgcard h-100"/>
		)//>
	}
	const contentWidgetwaktu = () => {
		if(uHtml500msg) return "";

		const vNamavars		= "widget_waktu";
		const vContentObj	= (uDatamainArr.filter(vItems=>
			(vItems.nama_vars||"").toUpperCase()===(vNamavars).toUpperCase()
			)||[])[0] || {};

		if((vContentObj.is_loading||false)===true) return contentSpinner()

		if((vContentObj.msg_error||"")!=="") return(
			<CCard className="h-100 classbgcard">
			<CCardHeader className="py-1 classfontsmaller">
				<small className="fw-bolder">Waktu Kunjungan</small>

				<span className="ms-1">&middot;</span>

				<CLink onClick={()=>hdlKlikRefreshparsial(vNamavars)} className="classcursorpointer">
				<CIcon icon="cilSync" className="classikonbox" height={18}/>
				</CLink>
			</CCardHeader>
			<CCardBody className="">
				<CCardText className="classpetunjuk p-0">
					<small>{UFunc.renderHTML(vContentObj.msg_error||"")}</small>
				</CCardText>
			</CCardBody>
			</CCard>
		)//>

		const vDataObj		= vContentObj.data_obj || {};
		const vProgress		= (((parseInt(vDataObj.total_detik)||0) / (parseInt(vDataObj.jml_kunjungan)||0) ) / (parseInt(vDataObj.total_detik)||0) ) * 100;
		const vRata2detik	= Math.round( (parseInt(vDataObj.total_detik)||0) / (parseInt(vDataObj.jml_kunjungan)||0) )

		const vRatacaption 	= 
			(Math.floor(vRata2detik / 3600) > 0 ? Math.floor(vRata2detik / 3600)+" jam " : "")+
			(Math.floor(vRata2detik % 3600 / 60) > 0 ? Math.floor(vRata2detik % 3600 / 60) + " menit" : "")+
			"";
		const vWaktuterlama 	= 
			(Math.floor((parseInt(vDataObj.max_kunjungan_detik)||0) / 3600) > 0 ? Math.floor((parseInt(vDataObj.max_kunjungan_detik)||0) / 3600)+" jam " : "") +
			(Math.floor((parseInt(vDataObj.max_kunjungan_detik)||0) % 3600 / 60) > 0 ? Math.floor((parseInt(vDataObj.max_kunjungan_detik)||0) % 3600 / 60) + " menit" : "")+
			"";

		return (
			<CWidgetStatsB
				inverse
				progress={{value: vProgress}}
				value={
					<>
					{(vRatacaption===""?"UNDF":vRatacaption)}

					<CLink onClick={()=>hdlKlikRefreshparsial(vNamavars)} style={{top:-3,right:5}} 
						className="classcursorpointer position-absolute">
					<CIcon icon="cilSync" className="classikonbox bg-light" height={18}/>
					</CLink>
					</>
				}
				title="Rata2 Waktu Kunjungan"
				text={"Terlama: "+(vWaktuterlama===""?"UNDF":vWaktuterlama)}
				style={{
					"backgroundColor":"#127b3e"
				}}
				className="h-100"/>
		)

		return (
		<CWidgetStatsD
			icon={<CIcon icon="cilClock" className="my-3 p-1 text-white" height={40} />}
			values={[
				{ 
					title: "Rata2 Waktu Layanan",
					value: (vDataObj.value_jam|| "0")+" jam "+(vDataObj.value_menit|| "0")+" menit"
				},
			]}
			style={{
				"--cui-card-cap-bg": "#1e8449",
				"--cui-card-bg" : "#FFFFFF55",
			}}
			className="classbgcard h-100"/>
		)//>
	}
	const contentGrafikomzet = () => {
		if(uHtml500msg) return "";

		const vNamavars		= "grafik_omzet";
		const vContentObj	= (uDatamainArr.filter(vItems=>
			(vItems.nama_vars||"").toUpperCase()===(vNamavars).toUpperCase()
			)||[])[0] || {};

		if((vContentObj.is_loading||false)===true) return contentSpinner()

		if((vContentObj.msg_error||"")!=="") return(
			<div className="classpetunjuk p-3 py-lg-5">{UFunc.renderHTML(vContentObj.msg_error||"")}</div>
		)//>

		const vDataObj		= vContentObj.data_obj || {};
		//console.log("(Dashboard - contentGrafikomzet) vDataObj.label_arr => "+JSON.stringify(vDataObj.label_arr));
		if(!vDataObj.label_arr) return "";

		/*vLabelXArr	= ["2023-02-01","2023-02-02","2023-02-03","2023-02-04","2023-02-05",
			"2023-02-06","2023-02-07","2023-02-08","2023-02-09","2023-02-01"];
		vValueArr	= [3000000,1000000,5000000,6000000,2000000,7000000,4000000,10000000,7000000,9000000];
		vRataArr 	= [5200000,5200000,5200000,5200000,5200000,5200000,5200000,5200000,5200000,5200000]
		//-*/

		return (
			<CChart type="line" 
				data={{
					labels: vDataObj.label_arr || [],
					datasets: [
					{
						label: uBahasaObj.word_pendapatan||"Omzet",
						borderColor: getStyle('--cui-primary'),
						backgroundColor: hexToRgba(getStyle('--cui-primary'), 5),
						pointHoverBackgroundColor: getStyle('--cui-primary'),
			        	borderWidth: 2,
			        	fill: true,
						data: vDataObj.value_arr || [],						
					},
					{
						label: uBahasaObj.caption_rata || "Rata2",// obBahasa.word_barang||"Barang",
						borderColor: getStyle("--cui-danger"),
						pointHoverBackgroundColor: getStyle("--cui-danger"),
						backgroundColor: getStyle("--cui-danger"),
			        	borderWidth: 1,
			        	fill: false,
						borderDash: [8, 5],
						data: vDataObj.rata_arr || [],
					}],
				}}
				options={{
					animation : false,
					maintainAspectRatio: true,
					plugins: {
						legend: { display: false },
						tooltip:{
							callbacks: {
								intersect: false,
								mode: 'index',
								title: (tooltipItem) => {
									return UFunc.HariAngka(UFunc.DbDate(new Date(parseInt(tooltipItem[0].label))));
									//return UFunc.HariAngka(tooltipItem[0].label);
								},
								//-*/
								label: (tooltipItem) => {									
									//-->tooltipItem.dataset={"label":"Pendapatan","borderColor":"#321fdb","backgroundColor":"rgba(50, 31, 219, 0.05)","pointHoverBackgroundColor":"#321fdb","borderWidth":2,"fill":true,"data":["1250000","3500000","2500000","1500000","4500000","7500000","4500000","2500000","0","6500000","1500000","6500000","3500000"]}
									//console.log("label.tooltipItem = "+JSON.stringify(tooltipItem.dataset));//-*/

									let vlabels = tooltipItem.dataset.label || '';
			                        if (vlabels) vlabels += ': ';
			                        if (tooltipItem.value !== null) {
			                        	vlabels += "Rp"+UFunc.formatAngka(tooltipItem.parsed.y);
			                        }//-*/

									return vlabels;
								},
							},
						},
					},
					elements: {
						line: {
							tension: 0.4,
						},
						point: {
							radius: 0,
							hitRadius: 10,
							hoverRadius: 4,
							hoverBorderWidth: 3
						}
					},
					scales: {
						y: {
							ticks : {
								beginAtZero: true,
								maxTicksLimit: 5,
								stepSize: Math.ceil(250 / 5),
								max: 100000,
								callback: (label, index, labels) => {
									return UFunc.formatAngka(label/1000000)+'M'
								},
							},
							scaleLabel: {
								display:true,
							},
						},
						x:{
							ticks : {
								callback: (label, index, labels) => {
									//console.log("vDataObj.label_arr || [] = "+JSON.stringify(vDataObj.label_arr || []))
									//const vTmptglDT = new Date(value);
									//return UFunc.TglAngka(UFunc.DbDate(vTmptglDT));
									return UFunc.TglAngka( UFunc.DbDate( new Date(parseInt((vDataObj.label_arr || [])[index]))) ).substr(0,5);

									//return ((new Date( parseInt((vDataObj.label_arr || [])[index] || 0) )) )
								},
							},//-*/
							grid: { drawOnChartArea: false }
						},
					},
					layout: {
						padding: {
							bottom: 1
						}
					},
				}}
			id="idgrafikomzet"/>
		)
	}
	const contentWidgetomzethari = () => {
		if(uHtml500msg) return "";

		const vContentObj	= (uDatamainArr.filter(vItems=>
			(vItems.nama_vars||"").toUpperCase()===("grafik_omzet").toUpperCase()
			)||[])[0] || {};

		if((vContentObj.is_loading||false)===true) return (
			<CCard className="classbgcard mb-3">
			<CCardBody className="d-flex justify-content-between align-items-center">
				<CSpinner color="dark" className="mx-auto my-4"/>
			</CCardBody>
			</CCard>
		)//>

		if((vContentObj.msg_error||"")!=="") return "";

		const vDataObj		= vContentObj.data_obj || {};
		const vTglfilterDT 	= UFunc.toDate((uKeywordObj.filter_dashboard||{}).tgl_filter || UFunc.DbDate());
		const vTglsatuDT	= new Date(uTglnowDT.getFullYear(),uTglnowDT.getMonth(),1);
		const vISBulanlalu	= vTglfilterDT.valueOf() < vTglsatuDT.valueOf();

		const vTotals 		= parseFloat(vDataObj.totals)||0;
		const vTmpValueArr 	= ([...(vDataObj.value_arr || [])]);//.sort((a,b) => b-a);
		const vOmzetmax		= vDataObj.value_arr ? Math.max(...(vDataObj.value_arr || [])) : 0;
		const vIdxmax		= (vDataObj.value_arr || []).indexOf(vOmzetmax);
		const vTglmax		= UFunc.TglAngka(UFunc.DbDate(new Date(
			(vDataObj.label_arr || [])[vIdxmax]
			)))//-*/
		//console.log("(Dashboard - contentWidgetomzethari) vIdxmax => "+(vDataObj.label_arr || [])[vIdxmax]);
		if(vISBulanlalu) {
			const vNilairata	= parseFloat((vDataObj.rata_arr||[])[0])||0;
			const vProgress 	= Math.round((vNilairata/vTotals) * 100);

			//--CARI_NILAI_MAX--/
			//--END CARI_NILAI_MAX--/

			return (
				<CWidgetStatsB
					color=""
					progress={{ color:"danger", value: vProgress }}
					value={"Rp"+UFunc.formatAngka(vNilairata)}
					title={"Rata2 Omzet Harian"}
					style={{backgroundColor:"#fdfced"}}
					text={vDataObj.label_arr ? "Tertinggi: Rp"+UFunc.formatAngka(vOmzetmax)+" ("+vTglmax+")" : ""}
					className="my-3 mt-md-0 text-danger border-danger"/>
			)
		}

		const vTglnowDT 	= new Date(uTglnowDT.getFullYear(),
			uTglnowDT.getMonth(),uTglnowDT.getDate());
		const vIdxnow		= (vDataObj.label_arr || []).findIndex(vItems =>{
			return (parseInt(vItems) === vTglnowDT.valueOf() );
		});
		
		//console.log("(Dashboard - contentWidgetomzethari) vIdxnow => "+vIdxnow);
		const vNilaihariini = parseFloat((vDataObj.value_arr || [])[vIdxnow]) || 0; 
		const vProgress 	= Math.round((vNilaihariini/vTotals) * 100);
		return (
			<CWidgetStatsB
				progress={{ color: "primary", value: vProgress }}
				value={"Rp"+UFunc.formatAngka(vNilaihariini)}
				title={"Omzet Hari Ini"}
				text={vDataObj.label_arr ? "Tertinggi: Rp"+UFunc.formatAngka(vOmzetmax)+" ("+vTglmax+")" : ""}
				style={{backgroundColor:"#fdfced99"}}
				className="my-3 mt-md-0 text-primary border-primary"/>
		)//>
	}
	const contentWidgetomzettotal = () => {
		if(uHtml500msg) return "";

		const vContentObj	= (uDatamainArr.filter(vItems=>
			(vItems.nama_vars||"").toUpperCase()===("grafik_omzet").toUpperCase()
			)||[])[0] || {};

		if((vContentObj.is_loading||false)===true) return (
			<CCard className="classbgcard my-3 mt-md-0">
			<CCardBody className="d-flex justify-content-between align-items-center">
				<CSpinner color="dark" className="mx-auto my-4"/>
			</CCardBody>
			</CCard>
		)//>

		if((vContentObj.msg_error||"")!=="") return "";

		const vDataObj		= vContentObj.data_obj || {};
		const vTotals 		= parseFloat(vDataObj.totals)||0;

		return (
			<CWidgetStatsB
				className=""
				color="primary"
				inverse
				progress={{ value: 75 }}
				text="" title="Omzet per Bulan" value={"Rp"+UFunc.formatAngka(vTotals)}
				/>
		)//>
	}
	const contentProgressdokter = () => {
		if(uHtml500msg) return "";

		const vNamavars 	= "progress_feedokter";
		const vContentObj	= (uDatamainArr.filter(vItems=>
			(vItems.nama_vars||"").toUpperCase()===(vNamavars).toUpperCase()
			)||[])[0] || {};

		if((vContentObj.is_loading||false)===true) return contentSpinner()

		if((vContentObj.msg_error||"")!=="") return(
			<CCard className="h-100 classbgcard">
			<CCardHeader className="py-1 classfontsmaller">
				<small className="fw-bolder">Top 5 Fee Dokter</small>

				<span className="ms-1">&middot;</span>

				<CLink onClick={()=>hdlKlikRefreshparsial(vNamavars)} className="classcursorpointer">
				<CIcon icon="cilSync" className="classikonbox" height={18}/>
				</CLink>
			</CCardHeader>
			<CCardBody className="">
				<CCardText className="classpetunjuk p-0">
					<small>{UFunc.renderHTML(vContentObj.msg_error||"")}</small>
				</CCardText>
			</CCardBody>
			</CCard>
		)//>

		const vDataObj		= vContentObj.data_obj || {};
		const vDataArr 		= vDataObj.data_arr || [];

		return (
		<CCard className="h-100 classbgcard">
			<CCardHeader className="d-flex justify-content-between classfontsmaller">
				<div>
					<small className="fw-bolder text-primary">Top 5 FEE DOKTER</small>

					<span className="ms-1">&middot;</span>

					<CLink onClick={()=>hdlKlikRefreshparsial(vNamavars)} className="classcursorpointer">
					<CIcon icon="cilSync" className="classikonbox" height={18}/>
					</CLink>
				</div>

				<Link className="text-decoration-none text-muted" to={"/subklinik/lapfeedokter"}>
					<small>Lihat Detil<CIcon icon="cilArrowRight" className="align-bottom"/></small>
				</Link>
			</CCardHeader>
			
			<CCardBody>
			{vDataArr.length <= 0 ? (
				<div className="d-flex align-items-center justify-content-center h-100">
				<div align="center">
					<CImage src={pjson.homepage+"images/logo_empty.png"} 
						className="border p-2 rounded-3 bg-light" height={70}/>
					<div className="mt-2 text-muted fst-italic classfontsmaller">
					&middot;&middot; Data Empty &middot;&middot;
					</div>
				</div>
				</div>
			) : (
			<>
			{vDataArr.map((vItems,vKeys)=>{
				return (
				<div className="progress-group" key={vKeys}>
				<div className="progress-group-header">
					<CImage src={pjson.homepage+"api/images/menus_dokter.png"} className="me-1" height={20}/>
					<small className="fst-italic">{UFunc.Usercaptionfilter((vItems.dokter_caption||""),4)}</small>
					<span className="ms-auto fw-semibold classfontsmaller">
						<span className="text-primary">{UFunc.formatAngka(((parseFloat(vItems.nilai)||0)/1000000))}M</span>
						<span className="ms-1 text-medium-emphasis"><small>({Math.round(vItems.persen)}%)</small></span>
					</span>
				</div>
					<div className="progress-group-bars">
					<CProgress thin color="primary" variant="striped" value={(parseFloat(vItems.persen)||0)}/>
				</div>
				</div>
				);
			})}
			</>
			)}
			</CCardBody>
		</CCard>
		)//>
	}
	const contentProgressnurse = () => {
		if(uHtml500msg) return "";

		const vNamavars 	= "progress_nurse";
		const vContentObj	= (uDatamainArr.filter(vItems=>
			(vItems.nama_vars||"").toUpperCase()===(vNamavars).toUpperCase()
			)||[])[0] || {};

		if((vContentObj.is_loading||false)===true) return contentSpinner()

		if((vContentObj.msg_error||"")!=="") return(
			<CCard className="h-100 classbgcard">
			<CCardHeader className="py-1 classfontsmaller">
				<small className="fw-bolder">Top 5 Poin PERAWAT</small>

				<span className="ms-1">&middot;</span>

				<CLink onClick={()=>hdlKlikRefreshparsial(vNamavars)} className="classcursorpointer">
				<CIcon icon="cilSync" className="classikonbox" height={18}/>
				</CLink>
			</CCardHeader>
			<CCardBody className="">
				<CCardText className="classpetunjuk p-0">
					<small>{UFunc.renderHTML(vContentObj.msg_error||"")}</small>
				</CCardText>
			</CCardBody>
			</CCard>
		)//>

		const vDataObj		= vContentObj.data_obj || {};
		const vDataArr 		= vDataObj.data_arr || [];

		return (
		<CCard className="h-100 classbgcard">
			<CCardHeader className="d-flex justify-content-between classfontsmaller">
				<div>
					<small className="fw-bolder text-danger">Top 5 POIN PERAWAT</small>

					<span className="ms-1">&middot;</span>

					<CLink onClick={()=>hdlKlikRefreshparsial(vNamavars)} className="classcursorpointer">
					<CIcon icon="cilSync" className="classikonbox" height={18}/>
					</CLink>
				</div>

				<Link to="/subklinik/lapperawat" className="text-decoration-none text-muted">
					<small>Lihat Detil<CIcon icon="cilArrowRight" className="align-bottom"/></small>
				</Link>
			</CCardHeader>
			
			<CCardBody>
			{vDataArr.length <= 0 ? (
				<div className="d-flex align-items-center justify-content-center h-100">
				<div align="center">
					<CImage src={pjson.homepage+"images/logo_empty.png"} 
						className="border p-2 rounded-3 bg-light" height={70}/>
					<div className="mt-2 text-muted fst-italic classfontsmaller">
					&middot;&middot; Data Empty &middot;&middot;
					</div>
				</div>
				</div>
			) : (
			<>
			{vDataArr.map((vItems,vKeys)=>{
				return (
				<div className="progress-group" key={vKeys}>
				<div className="progress-group-header">
					<CImage src={pjson.homepage+"api/images/menus_perawat.png"} className="me-1" height={23}/>
					<small className="fst-italic">{UFunc.Usercaptionfilter((vItems.nurse_caption||""),4)}</small>
					<span className="ms-auto fw-semibold classfontsmaller">
						<span className="text-danger">{UFunc.formatAngka(vItems.nilai)}</span>POIN
						<span className="ms-1 text-medium-emphasis"><small>({Math.round(vItems.persen)}%)</small></span>
					</span>
				</div>
					<div className="progress-group-bars">
					<CProgress thin color="danger" variant="striped" value={(parseFloat(vItems.persen)||0)}/>
				</div>
				</div>
				);
			})}
			</>
			)}
			</CCardBody>
		</CCard>
		)//>
	}
	const contentProgressterapi = () => {
		if(uHtml500msg) return "";

		const vNamavars 	= "progress_terapi";
		const vContentObj	= (uDatamainArr.filter(vItems=>
			(vItems.nama_vars||"").toUpperCase()===(vNamavars).toUpperCase()
			)||[])[0] || {};

		if((vContentObj.is_loading||false)===true) return contentSpinner()

		if((vContentObj.msg_error||"")!=="") return(
			<CCard className="h-100 classbgcard">
			<CCardHeader className="py-1 classfontsmaller">
				<small className="fw-bolder">Top 5 TERAPI</small>

				<span className="ms-1">&middot;</span>

				<CLink onClick={()=>hdlKlikRefreshparsial(vNamavars)} className="classcursorpointer">
				<CIcon icon="cilSync" className="classikonbox" height={18}/>
				</CLink>
			</CCardHeader>
			<CCardBody className="">
				<CCardText className="classpetunjuk p-0">
					<small>{UFunc.renderHTML(vContentObj.msg_error||"")}</small>
				</CCardText>
			</CCardBody>
			</CCard>
		)//>

		const vDataObj		= vContentObj.data_obj || {};
		const vDataArr		= vDataObj.data_arr || [];

		return (
		<CCard className="h-100 classbgcard">
			<CCardHeader className="d-flex justify-content-between classfontsmaller">
				<div>
					<small className="fw-bolder text-success">Top 5 TERAPI</small>

					<span className="ms-1">&middot;</span>

					<CLink onClick={()=>hdlKlikRefreshparsial(vNamavars)} className="classcursorpointer">
					<CIcon icon="cilSync" className="classikonbox" height={18}/>
					</CLink>
				</div>

				<Link className="text-decoration-none text-muted" to={"/subklinik/lapterapi"}>
					<small>Lihat Detil<CIcon icon="cilArrowRight" className="align-bottom"/></small>
				</Link>
			</CCardHeader>
			
			<CCardBody>
			{vDataArr.length <= 0 ? (
				<div className="d-flex align-items-center justify-content-center h-100">
				<div align="center">
					<CImage src={pjson.homepage+"images/logo_empty.png"} 
						className="border p-2 rounded-3 bg-light" height={70}/>
					<div className="mt-2 text-muted fst-italic classfontsmaller">
					&middot;&middot; Data Empty &middot;&middot;
					</div>
				</div>
				</div>
			) : (
			<>
			{vDataArr.map((vItems,vKeys)=>{
				return (
				<div className="progress-group" key={vKeys}>
				<div className="progress-group-header">
					<CImage src={pjson.homepage+"api/images/menus_terapi.png"} className="me-1" height={20}/>
					<small className="fst-italic">{UFunc.Usercaptionfilter((vItems.terapi_caption||""),4)}</small>
					<span className="ms-auto fw-semibold classfontsmaller">
						<span className="text-success">{UFunc.formatAngka(Math.round((parseFloat(vItems.nilai)||0)/1000000))}M</span>
						<span className="ms-1 text-medium-emphasis"><small>({Math.round(vItems.persen)}%)</small></span>
					</span>
				</div>
					<div className="progress-group-bars">
					<CProgress thin color="success" variant="striped" value={(parseFloat(vItems.persen)||0)}/>
				</div>
				</div>
				);
			})}
			</>
			)}
			</CCardBody>
		</CCard>
		)//>
	}
	const contentPieusia = () => {
		if(uHtml500msg) return "";

		const vNamavars 	= "pie_usia";
		const vContentObj	= (uDatamainArr.filter(vItems=>
			(vItems.nama_vars||"").toUpperCase()===(vNamavars).toUpperCase()
			)||[])[0] || {};

		if((vContentObj.is_loading||false)===true) return contentSpinner()

		if((vContentObj.msg_error||"")!=="") return(
			<CCard className="h-100 classbgcard">
			<CCardHeader className="py-1 classfontsmaller">
				<small className="fw-bolder">Usia Pasien</small>

				<span className="ms-1">&middot;</span>

				<CLink onClick={()=>hdlKlikRefreshparsial(vNamavars)} className="classcursorpointer">
				<CIcon icon="cilSync" className="classikonbox" height={18}/>
				</CLink>
			</CCardHeader>
			<CCardBody className="">
				<CCardText className="classpetunjuk p-0">
					<small>{UFunc.renderHTML(vContentObj.msg_error||"")}</small>
				</CCardText>
			</CCardBody>
			</CCard>
		)//>

		const vDataObj		= vContentObj.data_obj || {};

		return (
		<CCard className="h-100 classbgcard">
			<CCardBody className="p-1">
			<div className="mb-2 px-2">
				<Link className="text-decoration-none text-black">
				<small className="fw-bolder">Usia Pasien</small>
				</Link>

				<span className="ms-1">&middot;</span>

				<CLink onClick={()=>hdlKlikRefreshparsial(vNamavars)} className="classcursorpointer">
				<CIcon icon="cilSync" className="classikonbox" height={18}/>
				</CLink>
			</div>
			<CChart type="doughnut" className=""
				data={{
					labels: vDataObj.label_arr || [],
					datasets: [
					{
						backgroundColor: vDataObj.bgcolor_arr || [],
						data: vDataObj.value_arr || [],
					},
					],
				}}
				options={{
					plugins: {
						legend: {
							display: true,
							position: "right",
							align: "start",
							labels: {
								boxWidth: 13,
								color: getStyle('--cui-body-color'),
							}
						},
					},
					aspectRatio: 1.5,
					layout: {
						padding: {
							left:1,top: 1,right: 1,bottom: 1,
						}
					},
				}}
			id="idpieusia"/>
			</CCardBody>
		</CCard>
		)//>
	}
	const contentWidgetgender = () => {
		if(uHtml500msg) return "";

		const vNamavars 	= "widget_gender";
		const vContentObj	= (uDatamainArr.filter(vItems=>
			(vItems.nama_vars||"").toUpperCase()===(vNamavars).toUpperCase()
			)||[])[0] || {};

		if((vContentObj.is_loading||false)===true) return contentSpinner()

		if((vContentObj.msg_error||"")!=="") return(
			<CCard className="h-100 classbgcard">
			<CCardHeader className="py-1 classfontsmaller">
				<small className="fw-bolder">Gender Pasien</small>

				<span className="ms-1">&middot;</span>

				<CLink onClick={()=>hdlKlikRefreshparsial(vNamavars)} className="classcursorpointer">
				<CIcon icon="cilSync" className="classikonbox" height={18}/>
				</CLink>
			</CCardHeader>
			<CCardBody className="">
				<CCardText className="classpetunjuk p-0">
					<small>{UFunc.renderHTML(vContentObj.msg_error||"")}</small>
				</CCardText>
			</CCardBody>
			</CCard>
		)//>

		const vDataObj		= vContentObj.data_obj || {};
		const vPersenmale	= Math.round((parseInt(vDataObj.pasien_male)||0)/(parseInt(vDataObj.pasien_total)||1) * 100);
		const vPersenfemale	= (parseInt(vDataObj.pasien_female)||0) > 0 ? 100 - vPersenmale : 0;
		//console.log("(Dashboard - contentWidgetlamabaru) vDataObj => "+JSON.stringify(vDataObj))

		return (
		<CWidgetStatsD
			icon={
				<>
				<CIcon icon="cilWc" className="my-4 text-white" height={40}/>

				<CLink onClick={()=>hdlKlikRefreshparsial(vNamavars)} style={{top:3,right:5}} 
					className="classcursorpointer position-absolute">
				<CIcon icon="cilSync" className="classikonbox bg-light" height={18}/>
				</CLink>
				</>
			}
			values={[
				{ title: "Laki-laki", value: UFunc.formatAngka(vDataObj.pasien_male)+" ("+UFunc.formatAngka(vPersenmale)+"%)" },
				{ title: "Perempuan", color:"red", value: UFunc.formatAngka(vDataObj.pasien_female)+" ("+UFunc.formatAngka(vPersenfemale)+"%)" },
			]}
			style={{ 
				"--cui-card-cap-bg": "#faa8fa",
			}}
			className="classbgcard h-100"/>
		)
	}
	const contentWidgetlamabaru = () => {
		if(uHtml500msg) return "";

		const vNamavars 	= "widget_barulama";
		const vContentObj	= (uDatamainArr.filter(vItems=>
			(vItems.nama_vars||"").toUpperCase()===(vNamavars).toUpperCase()
			)||[])[0] || {};

		if((vContentObj.is_loading||false)===true) return contentSpinner()

		if((vContentObj.msg_error||"")!=="") return(
			<CCard className="h-100 classbgcard">
			<CCardHeader className="py-1 classfontsmaller">
				<small className="fw-bolder">Pasien Baru/LAMA</small>

				<span className="ms-1">&middot;</span>

				<CLink onClick={()=>hdlKlikRefreshparsial(vNamavars)} className="classcursorpointer">
				<CIcon icon="cilSync" className="classikonbox" height={18}/>
				</CLink>
			</CCardHeader>
			<CCardBody className="">
				<CCardText className="classpetunjuk p-0">
					<small>{UFunc.renderHTML(vContentObj.msg_error||"")}</small>
				</CCardText>
			</CCardBody>
			</CCard>
		)//>

		const vDataObj		= vContentObj.data_obj || {};
		const vPersenlama	= Math.round((parseInt(vDataObj.pasien_lama)||0)/(parseInt(vDataObj.pasien_total)||0) * 100);
		const vPersenbaru	= 100 - vPersenlama;
		//console.log("(Dashboard - contentWidgetlamabaru) vDataObj => "+JSON.stringify(vDataObj))

		return (
		<CWidgetStatsD
			icon={
				<>
				<CIcon icon="cilPeople" className={"text-white "+(uTokenObj.userhak==="FREEROLE"||uTokenObj.userhak==="ADMIN"?"my-4":"my-3")} height={40}/>

				<CLink onClick={()=>hdlKlikRefreshparsial(vNamavars)} style={{top:3,right:5}} 
					className="classcursorpointer position-absolute">
				<CIcon icon="cilSync" className="classikonbox bg-light" height={18}/>
				</CLink>
				</>
			}
			values={[
				{ title: "Pasien Lama", value: UFunc.formatAngka(vDataObj.pasien_lama)+" ("+UFunc.formatAngka(vPersenlama)+"%)" },
				{ title: "Baru", value: UFunc.formatAngka(vDataObj.pasien_baru)+" ("+UFunc.formatAngka(vPersenbaru)+"%)" },
			]}
			style={{ 
				"--cui-card-cap-bg": "#0091d5",
			}}
			className="classbgcard h-100"/>
		)
	}
	const contentWidgetaktif = () => {
		if(uHtml500msg) return "";

		const vNamavars 	= "widget_aktif";
		const vContentObj	= (uDatamainArr.filter(vItems=>
			(vItems.nama_vars||"").toUpperCase()===(vNamavars).toUpperCase()
			)||[])[0] || {};

		if((vContentObj.is_loading||false)===true) return contentSpinner()

		if((vContentObj.msg_error||"")!=="") return(
			<CCard className="h-100 classbgcard">
			<CCardHeader className="py-1 classfontsmaller">
				<small className="fw-bolder">Pasien Aktif</small>

				<span className="ms-1">&middot;</span>

				<CLink onClick={()=>hdlKlikRefreshparsial(vNamavars)} className="classcursorpointer">
				<CIcon icon="cilSync" className="classikonbox" height={18}/>
				</CLink>
			</CCardHeader>
			<CCardBody className="">
				<CCardText className="classpetunjuk p-0">
					<small>{UFunc.renderHTML(vContentObj.msg_error||"")}</small>
				</CCardText>
			</CCardBody>
			</CCard>
		)//>

		const vDataObj		= vContentObj.data_obj || {};
		const vPersenaktif	= parseInt((parseInt(vDataObj.pasien_aktif)||0)/(parseInt(vDataObj.pasien_total)||1) * 100);
		//console.log("(Dashboard - contentWidgetlamabaru) vDataObj => "+JSON.stringify(vDataObj))

		return (
		<CWidgetStatsD
			icon={
			<>
				<CIcon icon="cilAddressBook" className="my-4 text-white" height={40}/>
				
				<CLink onClick={()=>hdlKlikRefreshparsial(vNamavars)} style={{top:3,right:5}} 
					className="classcursorpointer position-absolute">
				<CIcon icon="cilSync" className="classikonbox bg-light" height={18}/>
				</CLink>
			</>
			}
			values={[
				{ title: "Terdaftar", value: UFunc.formatAngka(vDataObj.pasien_total)},
				{ title: "Pasien Aktif", value: UFunc.formatAngka(vDataObj.pasien_aktif)+" ("+UFunc.formatAngka(vPersenaktif)+"%)" },
			]}
			style={{ 
				"--cui-card-cap-bg": "#fb6f73",
			}}
			className="classbgcard h-100"/>
		)
	}
	const contentGrafikpasien = () => {
		if(uHtml500msg) return "";

		const vContentObj	= (uDatamainArr.filter(vItems=>
			(vItems.nama_vars||"").toUpperCase()===("grafik_pasien").toUpperCase()
			)||[])[0] || {};

		if((vContentObj.is_loading||false)===true) return contentSpinner()

		if((vContentObj.msg_error||"")!=="") return(
			<div className="classpetunjuk px-2 py-lg-5">{UFunc.renderHTML(vContentObj.msg_error||"")}</div>
		)//>

		const vDataObj		= vContentObj.data_obj || {};
		//console.log("(Dashboard - contentGrafikomzet) vDataObj.label_arr => "+JSON.stringify(vDataObj.label_arr));
		if(!vDataObj.label_arr) return "";

		/*vLabelXArr	= ["2023-02-01","2023-02-02","2023-02-03","2023-02-04","2023-02-05",
			"2023-02-06","2023-02-07","2023-02-08","2023-02-09","2023-02-01"];
		vValueArr	= [3000000,1000000,5000000,6000000,2000000,7000000,4000000,10000000,7000000,9000000];
		vRataArr 	= [5200000,5200000,5200000,5200000,5200000,5200000,5200000,5200000,5200000,5200000]
		//-*/

		return (
			<CChart type="line"
				className="mb-3"
				data={{
					labels: vDataObj.label_arr || [],
					datasets: [
					{
						label: uBahasaObj.word_jumlah||"Jumlah",
						borderColor: getStyle('--cui-primary'),
						backgroundColor: hexToRgba(getStyle('--cui-primary'), 5),
						pointHoverBackgroundColor: getStyle('--cui-primary'),
			        	borderWidth: 2,
			        	fill: true,
						data: vDataObj.value_arr || [],								
					},
					{
						label: uBahasaObj.caption_rata || "Rata2",
						borderColor: getStyle("--cui-danger"),
						pointHoverBackgroundColor: getStyle("--cui-danger"),
						backgroundColor: getStyle("--cui-danger"),
			        	borderWidth: 1,
			        	fill: false,
						borderDash: [8, 5],
						data: vDataObj.rata_arr || [],						
					}],
				}}
				options={{
					animation : false,
					maintainAspectRatio: true,
					plugins: {
						legend: { display: false },
						tooltip:{
							callbacks: {
								intersect: false,
								mode: 'index',
								title: (tooltipItem) => {
									return UFunc.HariAngka(UFunc.DbDate(new Date(parseInt(tooltipItem[0].label))));
									//return UFunc.HariAngka(tooltipItem[0].label);
								},
								//-*/
								label: (tooltipItem) => {									
									//-->tooltipItem.dataset={"label":"Pendapatan","borderColor":"#321fdb","backgroundColor":"rgba(50, 31, 219, 0.05)","pointHoverBackgroundColor":"#321fdb","borderWidth":2,"fill":true,"data":["1250000","3500000","2500000","1500000","4500000","7500000","4500000","2500000","0","6500000","1500000","6500000","3500000"]}
									//console.log("label.tooltipItem = "+JSON.stringify(tooltipItem.dataset));//-*/

									let vlabels = tooltipItem.dataset.label || '';
			                        if (vlabels) vlabels += ': ';
			                        if (tooltipItem.value !== null) {
			                        	vlabels += UFunc.formatAngka(tooltipItem.parsed.y)+" Pasien";
			                        }//-*/

									return vlabels;
								},
							},
						},
					},
					elements: {
						line: {
							tension: 0.4,
						},
						point: {
							radius: 0,
							hitRadius: 10,
							hoverRadius: 4,
							hoverBorderWidth: 3
						}
					},
					scales: {
						y: {
							ticks : {
								beginAtZero: true,
								maxTicksLimit: 5,
								stepSize: uTokenObj.userhak==="DOKTER" ? 5 : 25,
								max: uTokenObj.userhak==="DOKTER" ? 50 : 100,
								/*callback: (label, index, labels) => {
									//return UFunc.formatAngka(label/1000000)+'M'
								},*/
							},
							scaleLabel: {
								display:true,
							},
						},
						x:{
							ticks : {
								callback: (label, index, labels) => {
									//console.log("labels = "+JSON.stringify(labels))
									//console.log("labels = "+labels)
									//return JSON.stringify(labels);//[index];
									return UFunc.TglAngka( UFunc.DbDate( new Date(parseInt((vDataObj.label_arr || [])[index]))) ).substr(0,5);
								},
							},
							grid: { drawOnChartArea: false }
						},
					},
				}}
			id="idgrafikpasien"/>
		)
	}
	const contentWidgetpasienhari = () => {
		if(uHtml500msg) return "";

		const vContentObj	= (uDatamainArr.filter(vItems=>
			(vItems.nama_vars||"").toUpperCase()===("grafik_pasien").toUpperCase()
			)||[])[0] || {};

		if((vContentObj.is_loading||false)===true) return (
			<CCard className="classbgcard mb-3">
			<CCardBody className="d-flex justify-content-between align-items-center">
				<CSpinner color="dark" className="mx-auto my-4"/>
			</CCardBody>
			</CCard>
		)//>

		if((vContentObj.msg_error||"")!=="") return "";

		const vDataObj		= vContentObj.data_obj || {};
		const vTglfilterDT 	= UFunc.toDate((uKeywordObj.filter_dashboard||{}).tgl_filter || UFunc.DbDate());
		const vTglsatuDT	= new Date(uTglnowDT.getFullYear(),uTglnowDT.getMonth(),1);
		const vISBulanlalu	= vTglfilterDT.valueOf() < vTglsatuDT.valueOf();

		const vTotals 		= parseFloat(vDataObj.totals)||0;
		const vTmpValueArr 	= ([...(vDataObj.value_arr || [])]);//.sort((a,b) => b-a);
		const vJumlahmax	= vDataObj.value_arr ? Math.max(...(vDataObj.value_arr || [])) : 0;
		const vIdxmax		= (vDataObj.value_arr || []).indexOf(vJumlahmax);
		const vTglmax		= UFunc.TglAngka(UFunc.DbDate(new Date(
			(vDataObj.label_arr || [])[vIdxmax]
			)))//-*/
		//console.log("(Dashboard - contentWidgetomzethari) vIdxmax => "+(vDataObj.label_arr || [])[vIdxmax]);
		if(vISBulanlalu) {
			const vJumlahrata	= parseFloat((vDataObj.rata_arr||[])[0])||0;
			const vProgress 	= Math.round((vJumlahrata/vTotals) * 100);

			return (
				<CWidgetStatsB
					color=""
					progress={{ color:"danger", value: vProgress }}
					value={UFunc.formatAngka(vJumlahrata)+" Pasien"}
					title={"Rata2 Kunjungan Harian"}
					style={{backgroundColor:"#fdfced"}}
					text={vDataObj.label_arr ? "Terbanyak: "+UFunc.formatAngka(vJumlahmax)+" ("+vTglmax+")" : ""}
					className="my-3 mt-md-0 text-danger border-danger"/>
			)

		}

		const vIdxnow		= (vDataObj.label_arr || []).findIndex(vItems =>{
			return (parseInt(vItems) === uTglnowDT.valueOf() );
		});
		const vJumlahhariini = parseInt((vDataObj.value_arr || [])[vIdxnow]) || 0; 
		const vProgress 	= Math.round((vJumlahhariini/vTotals) * 100);
		return (
			<CWidgetStatsB
				progress={{ color: "primary", value: vProgress }}
				value={UFunc.formatAngka(vJumlahhariini)+" Pasien"}
				title={"Kunjungan Pasien Hari Ini"}
				text={vDataObj.label_arr ? "Terbanyak: "+UFunc.formatAngka(vJumlahmax)+" Pasien ("+vTglmax+")" : ""}
				style={{backgroundColor:"#fdfced99"}}
				className="my-3 mt-md-0 text-primary border-primary"/>
		)//>
	}
	const contentWidgetpasientotal = () => {
		if(uHtml500msg) return "";

		const vContentObj	= (uDatamainArr.filter(vItems=>
			(vItems.nama_vars||"").toUpperCase()===("grafik_pasien").toUpperCase()
			)||[])[0] || {};

		if((vContentObj.is_loading||false)===true) return (
			<CCard className="classbgcard my-3 mt-md-0">
			<CCardBody className="d-flex justify-content-between align-items-center">
				<CSpinner color="dark" className="mx-auto my-4"/>
			</CCardBody>
			</CCard>
		)//>

		if((vContentObj.msg_error||"")!=="") return "";

		const vDataObj		= vContentObj.data_obj || {};
		const vTotals 		= parseFloat(vDataObj.totals)||0;

		return (
			<CWidgetStatsB
				className=""
				color="primary"
				inverse
				progress={{ value: 75 }}
				text="" title="Kunjungan per Bulan" value={UFunc.formatAngka(vTotals)+" Pasien"}
				/>
		)//>
	}
	const contentWidgetbirthday = () => {
		if(uHtml500msg) return "";

		const vContentObj	= (uDatamainArr.filter(vItems=>
			(vItems.nama_vars||"").toUpperCase()===("widget_birthday").toUpperCase()
			)||[])[0] || {};

		if((vContentObj.is_loading||false)===true) return (
		<center>
			<CSpinner color="dark" className="mx-auto my-4"/>
		</center>
		)//>

		if((vContentObj.msg_error||"")!=="") return(
			<div className="classpetunjuk p-0 py-md-4">
				<small>{UFunc.renderHTML(vContentObj.msg_error||"")}</small>
			</div>
		)//>

		const vDataObj	= vContentObj.data_obj || {};
		const vDataArr	= (vDataObj.data_arr || []).filter((_,vKeys)=>vKeys<5);
		//console.log("vDataArr => "+JSON.stringify(vDataArr));

		return (
		<table className="table table-borderless table-hover">
		<thead>
			<tr className="classfontsmaller">
			<th width={30} className="p-0"/>
			<th className="p-0"/>
			<th width="20%" className="p-0"/>
			</tr>
		</thead>

		<tbody>
		{vDataArr.map((vItems,vKeys)=>{
			const {
				nama_pasien,umur,sex,
				id,
			} = vItems;

			return (
			<tr key={vKeys} className="classborderbottom">
				<td className="text-start">{(()=>{
					if(sex === "L")
					return (
						<CImage src={pjson.homepage+"api/images/icon_male.png"} height={25}/>
					);

					return (
						<CImage src={pjson.homepage+"api/images/icon_female.png"} height={25}/>
					);
				})()}</td>
				<td className="text-start">{nama_pasien||"Undf"}</td>
				<td className="text-end classfontsmaller"><b>{UFunc.formatAngka(umur)}</b> th</td>
			</tr>
			)
		})}
		</tbody>
		</table>
		)//>
	}
	const contentPiepayment = () => {
		if(uHtml500msg) return "";

		const vNamavars 	= "pie_payment";
		const vContentObj	= (uDatamainArr.filter(vItems=>
			(vItems.nama_vars||"").toUpperCase()===(vNamavars).toUpperCase()
			)||[])[0] || {};

		if((vContentObj.is_loading||false)===true) return contentSpinner()

		if((vContentObj.msg_error||"")!=="") return(
			<CCard className="h-100 classbgcard">
			<CCardHeader className="py-1 classfontsmaller">
				<small className="fw-bolder">Penerimaan Kasir</small>

				<span className="ms-1">&middot;</span>

				<CLink onClick={()=>hdlKlikRefreshparsial(vNamavars)} className="classcursorpointer">
				<CIcon icon="cilSync" className="classikonbox" height={18}/>
				</CLink>
			</CCardHeader>
			<CCardBody className="">
				<CCardText className="classpetunjuk p-0 py-md-4">
					<small>{UFunc.renderHTML(vContentObj.msg_error||"")}</small>
				</CCardText>
			</CCardBody>
			</CCard>
		)//>

		const vDataObj		= vContentObj.data_obj || {};
		const vTglfilterDT 	= UFunc.toDate((uKeywordObj.filter_dashboard||{}).tgl_filter || UFunc.DbDate());
		const vTglsatuDT	= new Date(uTglnowDT.getFullYear(),uTglnowDT.getMonth(),1);
		const vISBulanlalu	= vTglfilterDT.valueOf() < vTglsatuDT.valueOf();

		return (
		<CCard className="h-100 classbgcard">
			<CCardBody className="p-2">
			<div className="fw-bolder mb-2 px-2">
				<Link to="/suboperasional/lapkasir" 
					className="text-decoration-none text-black">
				<small>Penerimaan Kasir {vISBulanlalu?"per Bulan":"Hari Ini"}</small>
				</Link>

				<span className="ms-1">&middot;</span>

				<CLink onClick={()=>hdlKlikRefreshparsial(vNamavars)} className="classcursorpointer">
				<CIcon icon="cilSync" className="classikonbox" height={18}/>
				</CLink>
			</div>
			<CChart type="doughnut" className=""
				data={{
					labels: vDataObj.label_arr || [],
					datasets: [
					{
						backgroundColor: vDataObj.bgcolor_arr || [],
						data: vDataObj.value_arr || [],
					},
					],
				}}
				options={{
					plugins: {
						legend: {
							display: true,
							position: "right",
							align: "start",
							labels: {
								boxWidth: 13,
								color: getStyle('--cui-body-color'),
							}
						},
					},
					aspectRatio: 2.5,
					layout: {
						padding: {
							left:1,top: 1,right: 1,bottom: 1,
						}
					},
				}}
			id="idpiepayment"/>
			</CCardBody>
		</CCard>
		)//>
	}
	const contentCardfeedokter = () => {
		if(uHtml500msg) return "";

		const vNamavars 	= "card_feedokter";
		const vContentObj	= (uDatamainArr.filter(vItems=>
			(vItems.nama_vars||"").toUpperCase()===(vNamavars).toUpperCase()
			)||[])[0] || {};

		if((vContentObj.is_loading||false)===true) return contentSpinner()

		if((vContentObj.msg_error||"")!=="") return(
			<CCard className="h-100 classbgcard">
			<CCardHeader className="py-1 classfontsmaller">
				<small className="fw-bolder">Fee Dokter</small>

				<span className="ms-1">&middot;</span>

				<CLink onClick={()=>hdlKlikRefreshparsial(vNamavars)} className="classcursorpointer">
				<CIcon icon="cilSync" className="classikonbox" height={18}/>
				</CLink>
			</CCardHeader>

			<CCardBody className="">
				<CCardText className="classpetunjuk p-0">
					<small>{UFunc.renderHTML(vContentObj.msg_error||"")}</small>
				</CCardText>
			</CCardBody>
			</CCard>
		)//>

		const vDataObj		= vContentObj.data_obj || {};

		const vTglfilterDT 	= UFunc.toDate((uKeywordObj.filter_dashboard||{}).tgl_filter || UFunc.DbDate());
		const vTglsatuDT	= new Date(uTglnowDT.getFullYear(),uTglnowDT.getMonth(),1);
		const vISBulanlalu	= vTglfilterDT.valueOf() < vTglsatuDT.valueOf();

		return (
		<CCard className="h-100 classbgcard">
			<CCardHeader style={{backgroundColor:"#0091d5"}} className="text-center">
				<Link to="/subklinik/lapfeedokter" className="classcursorpointer">
				<CImage src={pjson.homepage+"api/images/menus_lapfeedokter.png"} className="mx-autio my-3" height={40}/>
				</Link>

				<CLink onClick={()=>hdlKlikRefreshparsial(vNamavars)} style={{top:5,right:5}} 
					className="classcursorpointer position-absolute">
				<CIcon icon="cilSync" className="classikonbox bg-light" height={18}/>
				</CLink>
			</CCardHeader>
			<CCardBody className="d-flex justify-content-between">
				<div>
					<div className="classfontsmaller">
						<small>Fee Dokter {vISBulanlalu ? "per Bulan" : "Bulan Ini"}</small>
					</div>

					{uISShowdokterfee===true ? (
					<big className="fw-bolder" style={{color:"#0091d5"}}>Rp{UFunc.formatAngka(vDataObj.nilai)}</big>
					) : (
					<big className="fw-bolder text-muted">********</big>
					)}
				</div>
				<CLink onClick={()=>{
					setShowdokterfee(!uISShowdokterfee);
					}} className="ps-1 classcursorpointer text-decoration-none">
				<CIcon icon="cilLowVision" className={uISShowdokterfee===true?"text-info":"text-muted"} height={28}/>
				</CLink>
			</CCardBody>
		</CCard>
		)//>
	}
	const contentDlgview = () => {
		if(!uISDlgviewshow) return (<></>)//>

		const vContentObj	= (uDatamainArr.filter(vItems=>
			(vItems.nama_vars||"").toUpperCase()===("widget_birthday").toUpperCase()
			)||[])[0] || {};
		
		const vDataObj	= vContentObj.data_obj || {};
		const vDataArr	= vDataObj.data_arr || [];
		
		return (
		<table className="table table-borderless table-hover">
		<thead>
			<tr className="classfontsmaller align-top">
			<th width={30} className="">&middot;</th>
			<th className="text-start">Nama Pasien</th>
			<th width="15%" className="text-end">Umur</th>
			</tr>
		</thead>

		<tbody>
		{vDataArr.map((vItems,vKeys)=>{
			const {
				nama_pasien,umur,sex,cabang_caption,telp_hp,
				id,
			} = vItems;

			return (
			<tr key={vKeys} className="classborderbottom">
				<td className="text-start">{(()=>{
					if(sex === "L")
					return (
						<CImage src={pjson.homepage+"api/images/icon_male.png"} height={25}/>
					);

					return (
						<CImage src={pjson.homepage+"api/images/icon_female.png"} height={25}/>
					);
				})()}</td>

				<td className="text-start">
					{nama_pasien||"Undf"}
					<div className="classfontsmaller">
					{(telp_hp||"")!=="" && (
						<small className="text-success">Telp/HP: <b>{telp_hp}</b></small>
					)}

					{((telp_hp||"")!=="" && uDatacabangArr.length > 1 ) && (
						<span className="mx-1">&middot;</span>
					)}

					{uDatacabangArr.length > 1 && (
					<>
						<small>{cabang_caption}</small>
					</>
					)}
					</div>
				</td>

				<td className="text-end classfontsmaller"><b>{UFunc.formatAngka(umur)}</b> th</td>
			</tr>
			)
		})}
		</tbody>
		</table>
		)//>
	}

	const contentWidgetlamabaruhari = () => {//--TIDAK_JADI--/
		if((uLoadingObj.omzet||false)===true) 
			return (<>{contentSpinner("primary")}</>)

		if(uMsgvarObj.omzet) return (
			<CCard className="classbgcard mb-3">
			<CCardBody className="py-5 bg-info text-white">
				<div className="my-3 classfontsmaller">
				<small>{UFunc.renderHTML(uMsgvarObj.omzet)}</small>
				</div>
			</CCardBody>
			</CCard>
		)

		const vDataObj	= uDatawidgetObj.omzethariobj || {};

		return (
			<CWidgetStatsB
				color="info"
				inverse
				progress={{ value: parseFloat(vDataObj.progressvalue) }}
				value={UFunc.formatAngka(vDataObj.value)+" Pasien"}
				title="Pasien per Hari"
				text={"Input Terakhir "+((vDataObj.tgl_lastinput||"")!==""?UFunc.TglAngka(vDataObj.tgl_lastinput):"UNDF")}
				className="mb-3"/>
		)//>
	}
	const contentWidgetlamabarubulan = () => {//--TIDAK_JADI--/
		if((uLoadingObj.omzet||false)===true) return (<></>)
		if(uMsgvarObj.omzet) return (<></>)

		const vDataObj	= uDatawidgetObj.omzetbulanobj || {};

		return (
			<CWidgetStatsB
				color="info"
				inverse
				progress={{ value: parseFloat(vDataObj.progressvalue) }}
				value={UFunc.formatAngka(vDataObj.value)+" Pasien"}
				title="Pasien per Bulan"
				className="mb-3"/>
		)//>
	}
	//--END CONTENT--/

	//--REST_API--/
	const apiLoadreservasi = (_HEADEROBJ) => {
		const vNamavars	= "widget_reservasi";
		let vArridx		= UFunc.getObjectIndeks(uDatamainArr,vNamavars,"nama_vars");

		if(vArridx < 0) {
			const vTmpObj	= {
				nama_vars: vNamavars,
			}
				
			uDatamainArr.push(vTmpObj);
			vArridx = uDatamainArr.length -1;
		} 
		uDatamainArr[vArridx].data_obj = undefined;

		//--CEK_DATA_LOCALSTORAGE--/
		const vKeywordObj		= JSON.parse(localStorage.getItem("listkeyword")) || {};
		const vFilterObj 		= vKeywordObj.filter_dashboard;
		const vTglfilter 		= vFilterObj.tgl_filter;
		const vCabangid 		= parseInt(vFilterObj.id_cabang);
		let vDatadashboardObj	= JSON.parse(localStorage.getItem("dashboard_data") || "{}");
		let vDataArr			= vDatadashboardObj.data_arr || [];

		const vDataidx 	= vDataArr.findIndex(vItems => {
			return (vItems.tgl_filter === vTglfilter
				&& vItems.nama_vars === vNamavars
				&& (parseInt(vItems.id_cabang)||0) === vCabangid
				&& (parseInt(vItems.id_dokter)||0) === (parseInt(uTokenObj.user_dokterid)||0)
				)
		});
		//--END CEK_DATA_LOCALSTORAGE--/

		const vFunctionNext = (_OUTPUTOBJ) => {
			console.log("(Dashboard - apiLoadreservasi) JamMenitDetik : "+UFunc.JamMenitDetik());
			//console.log("(Dashboard - apiLoadreservasi) _OUTPUTOBJ 2 : "+JSON.stringify(_OUTPUTOBJ));
			if(_OUTPUTOBJ.tampil) { 
				uDatamainArr[vArridx].data_obj		= JSON.parse(_OUTPUTOBJ.dataobjek_str || "{}");

				const vTglfilterDT 	= UFunc.toDate(vTglfilter);
				const vTglsatuDT	= new Date(uTglnowDT.getFullYear(),uTglnowDT.getMonth(),1);
				const vISBulanlalu	= vTglfilterDT.valueOf() < vTglsatuDT.valueOf();

				if(vISBulanlalu) {
					if(vDataidx < 0) {
						vDatadashboardObj	= JSON.parse(localStorage.getItem("dashboard_data") || "{}");
						vDataArr			= vDatadashboardObj.data_arr || [];
						const vTmpObj 	= {
							tgl_filter: vTglfilter,
							nama_vars: vNamavars,
							id_cabang: vCabangid,
							id_dokter: uTokenObj.user_dokterid,
							output_string: (_OUTPUTOBJ),
						}

						vDataArr.push(vTmpObj);
					} else vDataArr[vDataidx].output_string	= (_OUTPUTOBJ);

					vDatadashboardObj.data_arr = vDataArr;
					localStorage.setItem("dashboard_data",JSON.stringify(vDatadashboardObj));
				}//--JIKA_BULAN_LALU_JANGAN_DISAVE-/
			} else if(_OUTPUTOBJ.info) {
				uDatamainArr[vArridx].msg_error = (_OUTPUTOBJ.info);
			} else if(_OUTPUTOBJ.errors) {
				console.log("(Dashboard - apiLoadreservasi) output_string.errors : "+_OUTPUTOBJ.errors);
				const vMsg 	= (pjson.mydefault.environment==="development")
					? _OUTPUTOBJ.errors : pjson.mydefault.msgFetchError;
				uDatamainArr[vArridx].msg_error = ("<font color='red'>"+vMsg+"</font>");
			} else if(_OUTPUTOBJ.expired) {
				prosesExpired();
			}
			setEfekview2(!uEfekview2);
		}

		return new Promise((onResolve,onReject)=>{
			if(vDataidx >= 0) {
				vFunctionNext(vDataArr[vDataidx].output_string || {});
				onResolve();
				return;
			}

			uDatamainArr[vArridx].msg_error 	= undefined;
			uDatamainArr[vArridx].is_loading 	= true;
			uDatamainArr[vArridx].init_prosesapi = "YA";
			//console.log("(Dashboard - apiLoadreservasi) uDatamainArr : "+JSON.stringify(uDatamainArr));
			setEfekview(!uEfekview);
		
			const vURLs		= pjson.homepage+"api/api_dashboard/ld_reservasi";
			const vHeaderObj= _HEADEROBJ || {};
			
			fetch(vURLs,vHeaderObj
			).then((response)=> { if (response.status === 200) { return response.json(); }}
			).then((output_string) => {
				uDatamainArr[vArridx].is_loading	= false;
				vFunctionNext(output_string);
				onResolve()
			}).catch((errors)=>{
				uDatamainArr[vArridx].is_loading = false;
				uDatamainArr[vArridx].msg_error 	= pjson.mydefault.msg500str.join(" ");
				console.log("(Dashboard - apiLoadreservasi) catch-error: "+errors);
				setEfekview2(!uEfekview2);
				onReject(errors);
			});
		})
	}
	const apiLoadonline = (_HEADEROBJ) => {
		const vNamavars	= "widget_user";
		let vArridx		= UFunc.getObjectIndeks(uDatamainArr,vNamavars,"nama_vars");

		if(vArridx < 0) {
			const vTmpObj	= {
				nama_vars: vNamavars,
			}
				
			uDatamainArr.push(vTmpObj);
			vArridx = uDatamainArr.length -1;
		} 
		uDatamainArr[vArridx].data_obj = undefined;

		//--CEK_DATA_LOCALSTORAGE--/
		const vKeywordObj		= JSON.parse(localStorage.getItem("listkeyword")) || {};
		const vFilterObj 		= vKeywordObj.filter_dashboard;
		const vTglfilter 		= vFilterObj.tgl_filter;
		const vCabangid 		= parseInt(vFilterObj.id_cabang);
		let vDatadashboardObj	= JSON.parse(localStorage.getItem("dashboard_data") || "{}");
		let vDataArr			= vDatadashboardObj.data_arr || [];

		const vDataidx 	= vDataArr.findIndex(vItems => {
			return (vItems.tgl_filter === vTglfilter
				&& vItems.nama_vars === vNamavars
				&& (parseInt(vItems.id_cabang)||0) === vCabangid
				//&& (parseInt(vItems.id_dokter)||0) === (parseInt(uTokenObj.user_dokterid)||0)
				)
		});
		//--END CEK_DATA_LOCALSTORAGE--/

		const vFunctionNext = (_OUTPUTOBJ) => {
			console.log("(Dashboard - apiLoadonline) JamMenitDetik : "+UFunc.JamMenitDetik());
			if(_OUTPUTOBJ.tampil) { 
				uDatamainArr[vArridx].data_obj		= JSON.parse(_OUTPUTOBJ.dataobjek_str || "{}");

				const vTglfilterDT 	= UFunc.toDate(vTglfilter);
				const vTglsatuDT	= new Date(uTglnowDT.getFullYear(),uTglnowDT.getMonth(),1);
				const vISBulanlalu	= vTglfilterDT.valueOf() < vTglsatuDT.valueOf();

				if(vISBulanlalu) { 
					if(vDataidx < 0) {
						vDatadashboardObj	= JSON.parse(localStorage.getItem("dashboard_data") || "{}");
						vDataArr			= vDatadashboardObj.data_arr || [];
						const vTmpObj 	= {
							tgl_filter: vTglfilter,
							nama_vars: vNamavars,
							id_cabang: vCabangid,
							output_string: (_OUTPUTOBJ),
						}

						vDataArr.push(vTmpObj);
					} else vDataArr[vDataidx].output_string	= (_OUTPUTOBJ);

					vDatadashboardObj.data_arr = vDataArr;
					localStorage.setItem("dashboard_data",JSON.stringify(vDatadashboardObj));
				}//--JIKA_BULAN_LALU_JANGAN_DISAVE-/
			} else if(_OUTPUTOBJ.info) {
				uDatamainArr[vArridx].msg_error = (_OUTPUTOBJ.info);
			} else if(_OUTPUTOBJ.errors) {
				console.log("(Dashboard - apiLoadonline) output_string.errors : "+_OUTPUTOBJ.errors);
				const vMsg 	= (pjson.mydefault.environment==="development")
					? _OUTPUTOBJ.errors : pjson.mydefault.msgFetchError;
				uDatamainArr[vArridx].msg_error = ("<font color='red'>"+vMsg+"</font>");
			} else if(_OUTPUTOBJ.expired) {
				prosesExpired();
			}
			setEfekview2(!uEfekview2);
		}

		return new Promise((onResolve,onReject)=>{
			if(vDataidx >= 0) {
				vFunctionNext(vDataArr[vDataidx].output_string || {});
				onResolve();
				return;
			}

			uDatamainArr[vArridx].msg_error 	= undefined;
			uDatamainArr[vArridx].is_loading 	= true;
			uDatamainArr[vArridx].init_prosesapi = "YA";
			//console.log("(Dashboard - apiLoadonline) uDatamainArr : "+JSON.stringify(uDatamainArr));
			setEfekview(!uEfekview);
		
			const vURLs		= pjson.homepage+"api/api_dashboard/ld_user";
			const vHeaderObj= _HEADEROBJ || {};
			/*//--LAMA--/
			onResolve(
				fetch(vURLs,vHeaderObj
				).then((response)=> { if (response.status === 200) {return response.json(); }}
				).then((output_string) => {
					//console.log("(Dashboard - apiLoadonline) output_string : "+JSON.stringify(output_string));
					uDatamainArr[vArridx].is_loading	= false;
					vFunctionNext(output_string);
				})
			)
			//--END LAMA--*/
			fetch(vURLs,vHeaderObj
			).then((response)=> { if (response.status === 200) { return response.json(); }}
			).then((output_string) => {
				uDatamainArr[vArridx].is_loading	= false;
				vFunctionNext(output_string);
				onResolve()
			}).catch((errors)=>{
				uDatamainArr[vArridx].is_loading = false;
				uDatamainArr[vArridx].msg_error = pjson.mydefault.msg500str.join(" ");
				console.log("(Dashboard - apiLoadonline) catch-error: "+errors);
				setEfekview2(!uEfekview2);
				onReject(errors);
			});
		})
	}
	const apiLoadsatusehat = (_HEADEROBJ) => {
		const vNamavars	= "widget_satusehat";
		let vArridx		= UFunc.getObjectIndeks(uDatamainArr,vNamavars,"nama_vars");

		if(vArridx < 0) {
			const vTmpObj	= {
				nama_vars: vNamavars,
			}
				
			uDatamainArr.push(vTmpObj);
			vArridx = uDatamainArr.length -1;
		} 
		uDatamainArr[vArridx].data_obj = undefined;

		//--CEK_DATA_LOCALSTORAGE--/
		const vKeywordObj		= JSON.parse(localStorage.getItem("listkeyword")) || {};
		const vFilterObj 		= vKeywordObj.filter_dashboard;
		const vTglfilter 		= vFilterObj.tgl_filter;
		const vCabangid 		= parseInt(vFilterObj.id_cabang);
		let vDatadashboardObj	= JSON.parse(localStorage.getItem("dashboard_data") || "{}");
		let vDataArr			= vDatadashboardObj.data_arr || [];

		const vDataidx 	= vDataArr.findIndex(vItems => {
			return (vItems.tgl_filter === vTglfilter
				&& vItems.nama_vars === vNamavars
				&& (parseInt(vItems.id_cabang)||0) === vCabangid
				&& (parseInt(vItems.id_dokter)||0) === (parseInt(uTokenObj.user_dokterid)||0)
				)
		});
		//--END CEK_DATA_LOCALSTORAGE--/

		const vFunctionNext = (_OUTPUTOBJ) => {
			console.log("(Dashboard - apiLoadsatusehat) JamMenitDetik : "+UFunc.JamMenitDetik());
			if(_OUTPUTOBJ.tampil) { 
				uDatamainArr[vArridx].data_obj		= JSON.parse(_OUTPUTOBJ.dataobjek_str || "{}");

				const vTglfilterDT 	= UFunc.toDate(vTglfilter);
				const vTglsatuDT	= new Date(uTglnowDT.getFullYear(),uTglnowDT.getMonth(),1);
				const vISBulanlalu	= vTglfilterDT.valueOf() < vTglsatuDT.valueOf();

				if(vISBulanlalu) { 
					if(vDataidx < 0) {
						vDatadashboardObj	= JSON.parse(localStorage.getItem("dashboard_data") || "{}");
						vDataArr			= vDatadashboardObj.data_arr || [];
						const vTmpObj 	= {
							tgl_filter: vTglfilter,
							nama_vars: vNamavars,
							id_cabang: vCabangid,
							id_dokter: uTokenObj.user_dokterid,
							output_string: (_OUTPUTOBJ),
						}

						vDataArr.push(vTmpObj);
					} else vDataArr[vDataidx].output_string	= (_OUTPUTOBJ);

					vDatadashboardObj.data_arr = vDataArr;
					localStorage.setItem("dashboard_data",JSON.stringify(vDatadashboardObj));
				}//--JIKA_BULAN_LALU_JANGAN_DISAVE-/
			} else if(_OUTPUTOBJ.info) {
				uDatamainArr[vArridx].msg_error = (_OUTPUTOBJ.info);
			} else if(_OUTPUTOBJ.errors) {
				console.log("(Dashboard - apiLoadsatusehat) output_string.errors : "+_OUTPUTOBJ.errors);
				const vMsg 	= (pjson.mydefault.environment==="development")
					? _OUTPUTOBJ.errors : pjson.mydefault.msgFetchError;
				uDatamainArr[vArridx].msg_error = ("<font color='red'>"+vMsg+"</font>");
			} else if(_OUTPUTOBJ.expired) {
				prosesExpired();
			}
			setEfekview2(!uEfekview2);
		}

		return new Promise((onResolve,onReject)=>{
			if(vDataidx >= 0) {
				vFunctionNext(vDataArr[vDataidx].output_string || {});
				onResolve();
				return;
			}

			uDatamainArr[vArridx].msg_error 	= undefined;
			uDatamainArr[vArridx].is_loading 	= true;
			uDatamainArr[vArridx].init_prosesapi = "YA";
			//console.log("(Dashboard - apiLoadsatusehat) uDatamainArr : "+JSON.stringify(uDatamainArr));
			setEfekview(!uEfekview);
		
			const vURLs		= pjson.homepage+"api/api_dashboard/ld_satusehat";
			const vHeaderObj= _HEADEROBJ || {};
			
			/*//--LAMA--/
			onResolve(
				fetch(vURLs,vHeaderObj
				).then((response)=> { if (response.status === 200) {return response.json(); }}
				).then((output_string) => {
					//console.log("(Dashboard - apiLoadsatusehat) output_string : "+JSON.stringify(output_string));
					uDatamainArr[vArridx].is_loading	= false;
					vFunctionNext(output_string);
				})
			)
			//--END LAMA--*/
			fetch(vURLs,vHeaderObj
			).then((response)=> { if (response.status === 200) { return response.json(); }}
			).then((output_string) => {
				uDatamainArr[vArridx].is_loading	= false;
				vFunctionNext(output_string);
				onResolve()
			}).catch((errors)=>{
				uDatamainArr[vArridx].is_loading 	= false;
				uDatamainArr[vArridx].msg_error 	= pjson.mydefault.msg500str.join(" ");
				console.log("(Dashboard - apiLoadsatusehat) catch-error: "+errors);
				setEfekview2(!uEfekview2);
				onReject(errors);
			});
		})
	}
	const apiLoadwaktu = (_HEADEROBJ) => {
		const vNamavars	= "widget_waktu";
		let vArridx		= UFunc.getObjectIndeks(uDatamainArr,vNamavars,"nama_vars");

		if(vArridx < 0) {
			const vTmpObj	= {
				nama_vars: vNamavars,
			}
				
			uDatamainArr.push(vTmpObj);
			vArridx = uDatamainArr.length -1;
		} 
		uDatamainArr[vArridx].data_obj = undefined;

		//--CEK_DATA_LOCALSTORAGE--/
		const vKeywordObj		= JSON.parse(localStorage.getItem("listkeyword")) || {};
		const vFilterObj 		= vKeywordObj.filter_dashboard;
		const vTglfilter 		= vFilterObj.tgl_filter;
		const vCabangid 		= parseInt(vFilterObj.id_cabang);
		let vDatadashboardObj	= JSON.parse(localStorage.getItem("dashboard_data") || "{}");
		let vDataArr			= vDatadashboardObj.data_arr || [];

		const vDataidx 	= vDataArr.findIndex(vItems => {
			return (vItems.tgl_filter === vTglfilter
				&& vItems.nama_vars === vNamavars
				&& (parseInt(vItems.id_cabang)||0) === vCabangid
				&& (parseInt(vItems.id_dokter)||0) === (parseInt(uTokenObj.user_dokterid)||0)
				)
		});
		//--END CEK_DATA_LOCALSTORAGE--/

		const vFunctionNext = (_OUTPUTOBJ) => {
			console.log("(Dashboard - apiLoadwaktu) JamMenitDetik : "+UFunc.JamMenitDetik());
			//console.log("(Dashboard - apiLoadwaktu) _OUTPUTOBJ 2 : "+JSON.stringify(_OUTPUTOBJ));
			if(_OUTPUTOBJ.tampil) { 
				const vDataObj 					= JSON.parse(_OUTPUTOBJ.dataobjek_str || "{}");
				uDatamainArr[vArridx].data_obj	= vDataObj;
				const vISDatavalid = vDataObj && (parseInt(vDataObj.total_detik) > 0 && parseInt(vDataObj.max_kunjungan_detik) > 0);

				/*const vTglfilterDT 	= UFunc.toDate(vTglfilter);
				const vTglsatuDT	= new Date(uTglnowDT.getFullYear(),uTglnowDT.getMonth(),1);
				const vISBulanlalu	= vTglfilterDT.valueOf() < vTglsatuDT.valueOf();//-*/

				if(vISDatavalid) {
					if(vDataidx < 0) {
						vDatadashboardObj	= JSON.parse(localStorage.getItem("dashboard_data") || "{}");
						vDataArr			= vDatadashboardObj.data_arr || [];
						const vTmpObj 	= {
							tgl_filter: vTglfilter,
							nama_vars: vNamavars,
							id_cabang: vCabangid,
							id_dokter: uTokenObj.user_dokterid,
							output_string: (_OUTPUTOBJ),
						}

						vDataArr.push(vTmpObj);
					} else vDataArr[vDataidx].output_string	= (_OUTPUTOBJ);

					vDatadashboardObj.data_arr = vDataArr;
					localStorage.setItem("dashboard_data",JSON.stringify(vDatadashboardObj));
				}
			} else if(_OUTPUTOBJ.info) {
				uDatamainArr[vArridx].msg_error = (_OUTPUTOBJ.info);
			} else if(_OUTPUTOBJ.errors) {
				console.log("(Dashboard - apiLoadwaktu) output_string.errors : "+_OUTPUTOBJ.errors);
				const vMsg 	= (pjson.mydefault.environment==="development")
					? _OUTPUTOBJ.errors : pjson.mydefault.msgFetchError;
				uDatamainArr[vArridx].msg_error = ("<font color='red'>"+vMsg+"</font>");
			} else if(_OUTPUTOBJ.expired) {
				prosesExpired();
			}
			setEfekview2(!uEfekview2);
		}

		return new Promise((onResolve,onReject)=>{
			if(vDataidx >= 0) {
				vFunctionNext(vDataArr[vDataidx].output_string || {})
				onResolve()
				return;
			}

			uDatamainArr[vArridx].msg_error 	= undefined;
			uDatamainArr[vArridx].is_loading 	= true;
			//console.log("(Dashboard - apiLoadwaktu) uDatamainArr : "+JSON.stringify(uDatamainArr));
			uDatamainArr[vArridx].init_prosesapi = "YA";
			setEfekview(!uEfekview);
		
			const vURLs		= pjson.homepage+"api/api_dashboard/ld_waktu";
			const vHeaderObj= _HEADEROBJ || {};
			
			fetch(vURLs,vHeaderObj
			).then((response)=> { if (response.status === 200) { setSessionaktif(false); return response.json(); }}
			).then((output_string) => {
				//console.log("(Dashboard - apiLoadwaktu) output_string : "+JSON.stringify(output_string));
				setSessionaktif(true);
				uDatamainArr[vArridx].is_loading	= false;
				vFunctionNext(output_string);
				onResolve();
			}).catch((errors)=>{
				uDatamainArr[vArridx].is_loading	= false;
				uDatamainArr[vArridx].msg_error 	= pjson.mydefault.msg500str.join(" ");
				if(errors) console.log("(Dashboard - apiLoadwaktu) catch-error: "+errors);
				setEfekview2(!uEfekview2);
				onReject(errors);
			});
		})
	}
	const apiLoadgrafikomzet = (_HEADEROBJ) => {
		const vNamavars	= "grafik_omzet";
		let vArridx		= UFunc.getObjectIndeks(uDatamainArr,vNamavars,"nama_vars");
		if(vArridx < 0) {
			const vTmpObj	= {
				nama_vars: vNamavars,
			}
				
			uDatamainArr.push(vTmpObj);
			vArridx = uDatamainArr.length - 1;
		} 
		uDatamainArr[vArridx].data_obj = undefined;

		//--CEK_DATA_LOCALSTORAGE--/
		const vKeywordObj		= JSON.parse(localStorage.getItem("listkeyword")) || {};
		const vFilterObj 		= vKeywordObj.filter_dashboard;		
		const vTglfilter 		= vFilterObj.tgl_filter;
		const vCabangid 		= parseInt(vFilterObj.id_cabang);
		let vDatadashboardObj	= JSON.parse(localStorage.getItem("dashboard_data") || "{}");
		let vDataArr			= vDatadashboardObj.data_arr || [];

		const vDataidx 	= vDataArr.findIndex(vItems => {
			return (vItems.tgl_filter === vTglfilter
				&& vItems.nama_vars === vNamavars
				&& (parseInt(vItems.id_cabang)||0) === vCabangid
			)
		});
		//--END CEK_DATA_LOCALSTORAGE--/

		const vFunctionNext = (_OUTPUTOBJ) => {
			console.log("(Dashboard - apiLoadgrafikomzet) JamMenitDetik : "+UFunc.JamMenitDetik());
			if(_OUTPUTOBJ.tampil) { 
				//console.log("(Dashboard - apiLoadgrafikomzet) _OUTPUTOBJ.dataarr_str : "+_OUTPUTOBJ.dataarr_str);
				const vTmpArr		= JSON.parse(_OUTPUTOBJ.dataarr_str || "[]");
				const vAllZeroBl 	= vTmpArr.every(vItems => (parseFloat(vItems.nilai_omzet)||0) === 0);

				const vTmpTotals 	= vTmpArr.reduce((vSUM,vItems)=>vSUM + (parseFloat(vItems.nilai_omzet)||0),0);
				const vTmpRata2 	= Math.round(vTmpTotals / (parseInt(vTmpArr.length)||1));
				
				const vLabelXArr	= vTmpArr.map(vItems=> UFunc.toDate(vItems.tanggal).valueOf() );
				const vTmpValueArr	= vTmpArr.map(vItems=> (parseFloat(vItems.nilai_omzet)||0));
				const vTmpRataArr	= vTmpArr.map(vItems=> vTmpRata2);

				const vDatatmpObj	= {
					label_arr: vLabelXArr || [],
					value_arr: vTmpValueArr || [],
					rata_arr: vTmpRataArr || [],
					totals: vTmpTotals,
				}

				const vTimeout	= setTimeout(()=>{
					clearTimeout(vTimeout);

					uDatamainArr[vArridx].data_obj = vDatatmpObj;
					setEfekview2(!uEfekview2);
				},100);

				if(!vAllZeroBl) {
					if(vDataidx < 0) {
						vDatadashboardObj	= JSON.parse(localStorage.getItem("dashboard_data") || "{}");
						vDataArr			= vDatadashboardObj.data_arr || [];
						const vTmpObj 	= {
							tgl_filter: vTglfilter,
							nama_vars: vNamavars,
							id_cabang: vCabangid,
							output_string: (_OUTPUTOBJ),
						}
						vDataArr.push(vTmpObj);
					} else vDataArr[vDataidx].output_string	= (_OUTPUTOBJ);

					vDatadashboardObj.data_arr = vDataArr;
					localStorage.setItem("dashboard_data",JSON.stringify(vDatadashboardObj));
				}//-*/
			} else if(_OUTPUTOBJ.info) {
				uDatamainArr[vArridx].msg_error = (_OUTPUTOBJ.info);
			} else if(_OUTPUTOBJ.errors) {
				console.log("(Dashboard - apiLoadgrafikomzet) output_string.errors : "+_OUTPUTOBJ.errors);
				const vMsg 	= (pjson.mydefault.environment==="development")
					? _OUTPUTOBJ.errors : pjson.mydefault.msgFetchError;
				uDatamainArr[vArridx].msg_error = ("<font color='red'>"+vMsg+"</font>");
			} else if(_OUTPUTOBJ.expired) {
				prosesExpired();
			}
		}

		return new Promise((onResolve,onReject)=>{
			if(vDataidx >= 0) {
				vFunctionNext(vDataArr[vDataidx].output_string || {})
				onResolve()
				return;
			}
			//console.log("(Dashboard - apiLoadgrafikomzet) vArridx : "+(vArridx));

			uDatamainArr[vArridx].data_obj 		= undefined;
			uDatamainArr[vArridx].msg_error 	= undefined;
			uDatamainArr[vArridx].is_loading 	= true;
			uDatamainArr[vArridx].init_prosesapi = "YA";
			setEfekview(!uEfekview);
		
			const vURLs		= pjson.homepage+"api/api_dashboard/ld_omzet";
			const vHeaderObj= _HEADEROBJ || {};
			
			fetch(vURLs,vHeaderObj
			).then((response)=> { if (response.status === 200) { setSessionaktif(false); return response.json(); }}
			).then((output_string) => {
				//console.log("(Dashboard - apiLoadgrafikomzet) output_string : "+JSON.stringify(output_string));
				setSessionaktif(true);
				uDatamainArr[vArridx].is_loading	= false;
				vFunctionNext(output_string);
				onResolve();
			}).catch((errors)=>{
				uDatamainArr[vArridx].is_loading	= false;
				uDatamainArr[vArridx].msg_error 	= pjson.mydefault.msg500str.join(" ");
				if(errors) console.log("(Dashboard - apiLoadgrafikomzet) catch-error: "+errors);
				setEfekview2(!uEfekview2);
				onReject(errors);
			});
		})
	}
	const apiLoadfeedokter = (_HEADEROBJ) => {
		const vNamavars	= "progress_feedokter";
		let vArridx		= UFunc.getObjectIndeks(uDatamainArr,vNamavars,"nama_vars");
		if(vArridx < 0) {
			const vTmpObj	= {
				nama_vars: vNamavars,
			}
				
			uDatamainArr.push(vTmpObj);
			vArridx = uDatamainArr.length - 1;
		} 
		uDatamainArr[vArridx].data_obj = undefined;

		//--CEK_DATA_LOCALSTORAGE--/
		const vKeywordObj		= JSON.parse(localStorage.getItem("listkeyword")) || {};
		const vFilterObj 		= vKeywordObj.filter_dashboard;
		const vTglfilter 		= vFilterObj.tgl_filter;
		const vCabangid 		= parseInt(vFilterObj.id_cabang);
		let vDatadashboardObj	= JSON.parse(localStorage.getItem("dashboard_data") || "{}");
		let vDataArr			= vDatadashboardObj.data_arr || [];

		const vDataidx 	= vDataArr.findIndex(vItems => {
			return (vItems.tgl_filter === vTglfilter
				&& vItems.nama_vars === vNamavars
				&& (parseInt(vItems.id_cabang)||0) === vCabangid
			)
		});
		//--END CEK_DATA_LOCALSTORAGE--/

		const vFunctionNext = (_OUTPUTOBJ) => {
			console.log("(Dashboard - apiLoadfeedokter) JamMenitDetik : "+UFunc.JamMenitDetik());
			if(_OUTPUTOBJ.tampil) { 
				//console.log("(Dashboard - apiLoadfeedokter) _OUTPUTOBJ.dataarr_str : "+_OUTPUTOBJ.dataarr_str);
				const vTmpArr		= JSON.parse(_OUTPUTOBJ.dataarr_str || "[]");
				const vTotals 	 	= vTmpArr.reduce((vSUM,vItems) => vSUM + (parseFloat(vItems.nilai)||0),0);
				const vAllZeroBl 	= vTotals <= 0;

				const vFilterviewArr= vTmpArr.filter((_,vKeys)=> vKeys<5);

				vFilterviewArr.map(vItems=>vItems.persen = 
					((parseFloat(vItems.nilai)||0) / (parseFloat(vTotals)||1) )*100 
					);

				uDatamainArr[vArridx].data_obj = {
					data_arr: vFilterviewArr,
					totals: vTotals,
				}

				if(!vAllZeroBl) {
					if(vDataidx < 0) {
						vDatadashboardObj	= JSON.parse(localStorage.getItem("dashboard_data") || "{}");
						vDataArr			= vDatadashboardObj.data_arr || [];
						const vTmpObj 	= {
							tgl_filter: vTglfilter,
							nama_vars: vNamavars,
							id_cabang: vCabangid,
							output_string: (_OUTPUTOBJ),
						}
						vDataArr.push(vTmpObj);
					} else vDataArr[vDataidx].output_string	= (_OUTPUTOBJ);

					vDatadashboardObj.data_arr = vDataArr;
					localStorage.setItem("dashboard_data",JSON.stringify(vDatadashboardObj));
				} else {
					uDatamainArr[vArridx].data_obj = {
						label_arr: ["No Data"],
						bgcolor_arr: ["#DDDDDD"],
						value_arr: [1],
					}
				}
			} else if(_OUTPUTOBJ.info) {
				uDatamainArr[vArridx].msg_error = (_OUTPUTOBJ.info);
			} else if(_OUTPUTOBJ.errors) {
				console.log("(Dashboard - apiLoadfeedokter) output_string.errors : "+_OUTPUTOBJ.errors);
				const vMsg 	= (pjson.mydefault.environment==="development")
					? _OUTPUTOBJ.errors : pjson.mydefault.msgFetchError;
				uDatamainArr[vArridx].msg_error = ("<font color='red'>"+vMsg+"</font>");
			} else if(_OUTPUTOBJ.expired) {
				prosesExpired();
			}
		}

		return new Promise((onResolve,onReject)=>{
			if(vDataidx >= 0) {
				vFunctionNext(vDataArr[vDataidx].output_string || {})
				onResolve()
				return;
			}
			//console.log("(Dashboard - apiLoadfeedokter) vArridx : "+(vArridx));

			uDatamainArr[vArridx].data_obj 		= undefined;
			uDatamainArr[vArridx].msg_error 	= undefined;
			uDatamainArr[vArridx].is_loading 	= true;
			uDatamainArr[vArridx].init_prosesapi = "YA";
			setEfekview(!uEfekview);
		
			const vURLs		= pjson.homepage+"api/api_dashboard/ld_dokterpie";
			const vHeaderObj= _HEADEROBJ || {};
			
			fetch(vURLs,vHeaderObj
			).then((response)=> { if (response.status === 200) { setSessionaktif(false);return response.json(); }}
			).then((output_string) => {
				//console.log("(Dashboard - apiLoadfeedokter) output_string : "+JSON.stringify(output_string));
				setSessionaktif(true);
				uDatamainArr[vArridx].is_loading	= false;
				vFunctionNext(output_string);
				onResolve();
			}).catch((errors)=>{
				uDatamainArr[vArridx].is_loading	= false;
				uDatamainArr[vArridx].msg_error 	= pjson.mydefault.msg500str.join(" ");
				if(errors) console.log("(Dashboard - apiLoadfeedokter) catch-error: "+errors);
				setEfekview2(!uEfekview2);
				onReject(errors);
			});//-*/
		})
	}
	const apiLoadpoinperawat = (_HEADEROBJ) => {
		const vNamavars	= "progress_nurse";
		let vArridx		= UFunc.getObjectIndeks(uDatamainArr,vNamavars,"nama_vars");
		if(vArridx < 0) {
			const vTmpObj	= {
				nama_vars: vNamavars,
			}
				
			uDatamainArr.push(vTmpObj);
			vArridx = uDatamainArr.length - 1;
		} 
		uDatamainArr[vArridx].data_obj = undefined;

		//--CEK_DATA_LOCALSTORAGE--/
		const vKeywordObj		= JSON.parse(localStorage.getItem("listkeyword")) || {};
		const vFilterObj 		= vKeywordObj.filter_dashboard;
		const vTglfilter 		= vFilterObj.tgl_filter;
		const vCabangid 		= parseInt(vFilterObj.id_cabang);
		let vDatadashboardObj	= JSON.parse(localStorage.getItem("dashboard_data") || "{}");
		let vDataArr			= vDatadashboardObj.data_arr || [];

		const vDataidx 	= vDataArr.findIndex(vItems => {
			return (vItems.tgl_filter === vTglfilter
				&& vItems.nama_vars === vNamavars
				&& (parseInt(vItems.id_cabang)||0) === vCabangid
			)
		});
		//--END CEK_DATA_LOCALSTORAGE--/

		const vFunctionNext = (_OUTPUTOBJ) => {
			console.log("(Dashboard - apiLoadpoinperawat) JamMenitDetik : "+UFunc.JamMenitDetik());
			if(_OUTPUTOBJ.tampil) { 
				//console.log("(Dashboard - apiLoadpoinperawat) _OUTPUTOBJ.dataarr_str : "+_OUTPUTOBJ.dataarr_str);
				const vTmpArr		= JSON.parse(_OUTPUTOBJ.dataarr_str || "[]");
				const vTotals 	 	= vTmpArr.reduce((vSUM,vItems) => vSUM + (parseFloat(vItems.nilai)||0),0);
				const vAllZeroBl 	= vTotals <= 0;

				const vFilterviewArr= vTmpArr.filter((_,vKeys)=> vKeys<5);

				vFilterviewArr.map(vItems=>vItems.persen = 
					((parseFloat(vItems.nilai)||0) / (parseFloat(vTotals)||1) )*100 
					);

				uDatamainArr[vArridx].data_obj = {
					data_arr: vFilterviewArr,
					totals: vTotals,
				}

				if(!vAllZeroBl) {
					if(vDataidx < 0) {
						vDatadashboardObj	= JSON.parse(localStorage.getItem("dashboard_data") || "{}");
						vDataArr			= vDatadashboardObj.data_arr || [];
						const vTmpObj 	= {
							tgl_filter: vTglfilter,
							nama_vars: vNamavars,
							id_cabang: vCabangid,
							output_string: (_OUTPUTOBJ),
						}
						vDataArr.push(vTmpObj);
					} else vDataArr[vDataidx].output_string	= (_OUTPUTOBJ);

					vDatadashboardObj.data_arr = vDataArr;
					localStorage.setItem("dashboard_data",JSON.stringify(vDatadashboardObj));
				} else {
					uDatamainArr[vArridx].data_obj = {
						label_arr: ["No Data"],
						bgcolor_arr: ["#DDDDDD"],
						value_arr: [1],
					}
				}
			} else if(_OUTPUTOBJ.info) {
				uDatamainArr[vArridx].msg_error = (_OUTPUTOBJ.info);
			} else if(_OUTPUTOBJ.errors) {
				console.log("(Dashboard - apiLoadpoinperawat) output_string.errors : "+_OUTPUTOBJ.errors);
				const vMsg 	= (pjson.mydefault.environment==="development")
					? _OUTPUTOBJ.errors : pjson.mydefault.msgFetchError;
				uDatamainArr[vArridx].msg_error = ("<font color='red'>"+vMsg+"</font>");
			} else if(_OUTPUTOBJ.expired) {
				prosesExpired();
			}
		}

		return new Promise((onResolve,onReject)=>{
			if(vDataidx >= 0) {
				vFunctionNext(vDataArr[vDataidx].output_string || {})
				onResolve()
				return;
			}
			//console.log("(Dashboard - apiLoadpoinperawat) vArridx : "+(vArridx));

			uDatamainArr[vArridx].data_obj 		= undefined;
			uDatamainArr[vArridx].msg_error 	= undefined;
			uDatamainArr[vArridx].is_loading 	= true;
			uDatamainArr[vArridx].init_prosesapi = "YA";
			setEfekview(!uEfekview);
		
			const vURLs		= pjson.homepage+"api/api_dashboard/ld_nursepie";
			const vHeaderObj= _HEADEROBJ || {};
			
			fetch(vURLs,vHeaderObj
			).then((response)=> { if (response.status === 200) { setSessionaktif(false);return response.json(); }}
			).then((output_string) => {
				//console.log("(Dashboard - apiLoadpoinperawat) output_string : "+JSON.stringify(output_string));
				setSessionaktif(true);
				uDatamainArr[vArridx].is_loading	= false;
				vFunctionNext(output_string);
				onResolve();
			}).catch((errors)=>{
				uDatamainArr[vArridx].is_loading	= false;
				uDatamainArr[vArridx].msg_error 	= pjson.mydefault.msg500str.join(" ");
				if(errors) console.log("(Dashboard - apiLoadpoinperawat) catch-error: "+errors);
				setEfekview2(!uEfekview2);
				onReject(errors);
			});//-*/
		})//-*/
	}
	const apiLoadtopterapi = (_HEADEROBJ) => {
		const vNamavars	= "progress_terapi";
		let vArridx		= UFunc.getObjectIndeks(uDatamainArr,vNamavars,"nama_vars");
		if(vArridx < 0) {
			const vTmpObj	= {
				nama_vars: vNamavars,
			}
				
			uDatamainArr.push(vTmpObj);
			vArridx = uDatamainArr.length - 1;
		} 
		uDatamainArr[vArridx].data_obj = undefined;

		//--CEK_DATA_LOCALSTORAGE--/
		const vKeywordObj		= JSON.parse(localStorage.getItem("listkeyword")) || {};
		const vFilterObj 		= vKeywordObj.filter_dashboard;
		const vTglfilter 		= vFilterObj.tgl_filter;
		const vCabangid 		= parseInt(vFilterObj.id_cabang);
		let vDatadashboardObj	= JSON.parse(localStorage.getItem("dashboard_data") || "{}");
		let vDataArr			= vDatadashboardObj.data_arr || [];

		const vDataidx 	= vDataArr.findIndex(vItems => {
			return (vItems.tgl_filter === vTglfilter
				&& vItems.nama_vars === vNamavars
				&& (parseInt(vItems.id_cabang)||0) === vCabangid
			)
		});
		//--END CEK_DATA_LOCALSTORAGE--/

		const vFunctionNext = (_OUTPUTOBJ) => {
			console.log("(Dashboard - apiLoadtopterapi) JamMenitDetik : "+UFunc.JamMenitDetik());
			if(_OUTPUTOBJ.tampil) { 
				//console.log("(Dashboard - apiLoadtopterapi) _OUTPUTOBJ.dataarr_str : "+_OUTPUTOBJ.dataarr_str);
				const vTmpArr		= JSON.parse(_OUTPUTOBJ.dataarr_str || "[]");
				const vTotals 	 	= vTmpArr.reduce((vSUM,vItems) => vSUM + (parseFloat(vItems.nilai)||0),0);
				const vAllZeroBl 	= vTotals <= 0;

				const vFilterviewArr= vTmpArr.filter((_,vKeys)=> vKeys<5);

				vFilterviewArr.map(vItems=>vItems.persen = 
					((parseFloat(vItems.nilai)||0) / (parseFloat(vTotals)||1) )*100 
					);

				uDatamainArr[vArridx].data_obj = {
					data_arr: vFilterviewArr,
					totals: vTotals,
				}

				if(!vAllZeroBl) {
					if(vDataidx < 0) {
						vDatadashboardObj	= JSON.parse(localStorage.getItem("dashboard_data") || "{}");
						vDataArr			= vDatadashboardObj.data_arr || [];
						const vTmpObj 	= {
							tgl_filter: vTglfilter,
							nama_vars: vNamavars,
							id_cabang: vCabangid,
							output_string: (_OUTPUTOBJ),
						}
						vDataArr.push(vTmpObj);
					} else vDataArr[vDataidx].output_string	= (_OUTPUTOBJ);

					vDatadashboardObj.data_arr = vDataArr;
					localStorage.setItem("dashboard_data",JSON.stringify(vDatadashboardObj));
				} else {
					uDatamainArr[vArridx].data_obj = {
						label_arr: ["No Data"],
						bgcolor_arr: ["#DDDDDD"],
						value_arr: [1],
					}
				}
			} else if(_OUTPUTOBJ.info) {
				uDatamainArr[vArridx].msg_error = (_OUTPUTOBJ.info);
			} else if(_OUTPUTOBJ.errors) {
				console.log("(Dashboard - apiLoadtopterapi) output_string.errors : "+_OUTPUTOBJ.errors);
				const vMsg 	= (pjson.mydefault.environment==="development")
					? _OUTPUTOBJ.errors : pjson.mydefault.msgFetchError;
				uDatamainArr[vArridx].msg_error = ("<font color='red'>"+vMsg+"</font>");
			} else if(_OUTPUTOBJ.expired) {
				prosesExpired();
			}
		}

		return new Promise((onResolve,onReject)=>{
			if(vDataidx >= 0) {
				vFunctionNext(vDataArr[vDataidx].output_string || {})
				onResolve()
				return;
			}
			//console.log("(Dashboard - apiLoadtopterapi) vArridx : "+(vArridx));

			uDatamainArr[vArridx].data_obj 		= undefined;
			uDatamainArr[vArridx].msg_error 	= undefined;
			uDatamainArr[vArridx].is_loading 	= true;
			uDatamainArr[vArridx].init_prosesapi = "YA";
			setEfekview(!uEfekview);
		
			const vURLs		= pjson.homepage+"api/api_dashboard/ld_terapipie";
			const vHeaderObj= _HEADEROBJ || {};
			
			fetch(vURLs,vHeaderObj
			).then((response)=> { if (response.status === 200) { setSessionaktif(false);return response.json(); }}
			).then((output_string) => {
				//console.log("(Dashboard - apiLoadtopterapi) output_string : "+JSON.stringify(output_string));
				setSessionaktif(true);
				uDatamainArr[vArridx].is_loading	= false;
				vFunctionNext(output_string);
				onResolve();
			}).catch((errors)=>{
				uDatamainArr[vArridx].is_loading	= false;
				uDatamainArr[vArridx].msg_error 	= pjson.mydefault.msg500str.join(" ");
				if(errors) console.log("(Dashboard - apiLoadtopterapi) catch-error: "+errors);
				setEfekview2(!uEfekview2);
				onReject(errors);
			});//-*/
		})//-*/
	}
	const apiLoadpieusia = (_HEADEROBJ) => {
		const vNamavars	= "pie_usia";
		let vArridx		= UFunc.getObjectIndeks(uDatamainArr,vNamavars,"nama_vars");
		if(vArridx < 0) {
			const vTmpObj	= {
				nama_vars: vNamavars,
			}
				
			uDatamainArr.push(vTmpObj);
			vArridx = uDatamainArr.length - 1;
		} 
		uDatamainArr[vArridx].data_obj = undefined;

		//--CEK_DATA_LOCALSTORAGE--/
		const vKeywordObj		= JSON.parse(localStorage.getItem("listkeyword")) || {};
		const vFilterObj 		= vKeywordObj.filter_dashboard;
		const vTglfilter 		= vFilterObj.tgl_filter;
		const vCabangid 		= parseInt(vFilterObj.id_cabang);
		let vDatadashboardObj	= JSON.parse(localStorage.getItem("dashboard_data") || "{}");
		let vDataArr			= vDatadashboardObj.data_arr || [];

		const vDataidx 	= vDataArr.findIndex(vItems => {
			return (vItems.tgl_filter === vTglfilter
				&& vItems.nama_vars === vNamavars
				&& (parseInt(vItems.id_cabang)||0) === vCabangid
			)
		});
		//--END CEK_DATA_LOCALSTORAGE--/

		const vFunctionNext = (_OUTPUTOBJ) => {
			console.log("(Dashboard - apiLoadpieusia) JamMenitDetik : "+UFunc.JamMenitDetik());
			if(_OUTPUTOBJ.tampil) { 
				//console.log("(Dashboard - apiLoadpieusia) _OUTPUTOBJ.dataarr_str : "+_OUTPUTOBJ.dataarr_str);
				const vTmpArr		= JSON.parse(_OUTPUTOBJ.dataarr_str || "[]");
				const vTotals 	 	= vTmpArr.reduce((vSUM,vItems) => vSUM + (parseFloat(vItems.nilai)||0),0);
				const vAllZeroBl 	= vTotals <= 0;

				vTmpArr.map(vItems=> {
					switch((parseInt(vItems.usia_idx)||0)) {
						case 2:
							vItems.terapi_caption = "6 s.d 13";
							break;
						case 3:
							vItems.terapi_caption = "14 s.d 21";
							break;
						case 4:
							vItems.terapi_caption = "22 s.d 44";
							break;
						case 5:
							vItems.terapi_caption = "45 s.d 59";
							break;
						case 6:
							vItems.terapi_caption = "60 th Keatas";
							break;
						default:
							vItems.terapi_caption = "5 th Kebawah";
					}
				});
				const vLabelXArr	= vTmpArr.map(vItems=>vItems.terapi_caption);
				const vBgColorArr	= vTmpArr.map(vItems=>UFunc.getRandomColor());
				const vValueArr		= vTmpArr.map(vItems=>(parseFloat(vItems.nilai)||0));
				
				uDatamainArr[vArridx].data_obj = {
					label_arr: vLabelXArr,
					bgcolor_arr: vBgColorArr,
					value_arr: vValueArr,
				}

				if(!vAllZeroBl) {
					if(vDataidx < 0) {
						vDatadashboardObj	= JSON.parse(localStorage.getItem("dashboard_data") || "{}");
						vDataArr			= vDatadashboardObj.data_arr || [];
						const vTmpObj 	= {
							tgl_filter: vTglfilter,
							nama_vars: vNamavars,
							id_cabang: vCabangid,
							output_string: (_OUTPUTOBJ),
						}
						vDataArr.push(vTmpObj);
					} else vDataArr[vDataidx].output_string	= (_OUTPUTOBJ);

					vDatadashboardObj.data_arr = vDataArr;
					localStorage.setItem("dashboard_data",JSON.stringify(vDatadashboardObj));
				} else {
					uDatamainArr[vArridx].data_obj = {
						label_arr: ["No Data"],
						bgcolor_arr: ["#DDDDDD"],
						value_arr: [1],
					}
				}
			} else if(_OUTPUTOBJ.info) {
				uDatamainArr[vArridx].msg_error = (_OUTPUTOBJ.info);
			} else if(_OUTPUTOBJ.errors) {
				console.log("(Dashboard - apiLoadtopterapi) output_string.errors : "+_OUTPUTOBJ.errors);
				const vMsg 	= (pjson.mydefault.environment==="development")
					? _OUTPUTOBJ.errors : pjson.mydefault.msgFetchError;
				uDatamainArr[vArridx].msg_error = ("<font color='red'>"+vMsg+"</font>");
			} else if(_OUTPUTOBJ.expired) {
				prosesExpired();
			}
		}

		return new Promise((onResolve,onReject)=>{
			if(vDataidx >= 0) {
				vFunctionNext(vDataArr[vDataidx].output_string || {});
				onResolve();
				return;
			}
			//console.log("(Dashboard - apiLoadpieusia) vArridx : "+(vArridx));

			uDatamainArr[vArridx].data_obj 		= undefined;
			uDatamainArr[vArridx].msg_error 	= undefined;
			uDatamainArr[vArridx].is_loading 	= true;
			uDatamainArr[vArridx].init_prosesapi = "YA";
			setEfekview(!uEfekview);
		
			const vURLs		= pjson.homepage+"api/api_dashboard/ld_pasienusia";
			const vHeaderObj= _HEADEROBJ || {};
			
			/*//--LAMA--/
			onResolve(
				fetch(vURLs,vHeaderObj
				).then((response)=> { if (response.status === 200){ return response.json(); }}
				).then((output_string) => {
					//console.log("(Dashboard - apiLoadpieusia) output_string : "+JSON.stringify(output_string));
					uDatamainArr[vArridx].is_loading	= false;
					vFunctionNext(output_string);
				})
			)
			//--END LAMA--*/
			fetch(vURLs,vHeaderObj
			).then((response)=> { if (response.status === 200) { return response.json(); }}
			).then((output_string) => {
				uDatamainArr[vArridx].is_loading	= false;
				vFunctionNext(output_string);
				onResolve()
			}).catch((errors)=>{
				uDatamainArr[vArridx].is_loading 	= false;
				uDatamainArr[vArridx].msg_error 	= pjson.mydefault.msg500str.join(" ");
				console.log("(Dashboard - apiLoadpieusia) catch-error: "+errors);
				setEfekview2(!uEfekview2);
				onReject(errors);
			});
		})//-*/
	}
	const apiLoadgender = (_HEADEROBJ) => {
		const vNamavars	= "widget_gender";
		let vArridx		= UFunc.getObjectIndeks(uDatamainArr,vNamavars,"nama_vars");

		if(vArridx < 0) {
			const vTmpObj	= {
				nama_vars: vNamavars,
			}
				
			uDatamainArr.push(vTmpObj);
			vArridx = uDatamainArr.length -1;
		} 
		uDatamainArr[vArridx].data_obj = undefined;

		//--CEK_DATA_LOCALSTORAGE--/
		const vKeywordObj		= JSON.parse(localStorage.getItem("listkeyword")) || {};
		const vFilterObj 		= vKeywordObj.filter_dashboard;
		const vTglfilter 		= vFilterObj.tgl_filter;
		const vCabangid 		= parseInt(vFilterObj.id_cabang);
		let vDatadashboardObj	= JSON.parse(localStorage.getItem("dashboard_data") || "{}");
		let vDataArr			= vDatadashboardObj.data_arr || [];

		const vDataidx 	= vDataArr.findIndex(vItems => {
			return (vItems.tgl_filter === vTglfilter
				&& vItems.nama_vars === vNamavars
				&& (parseInt(vItems.id_cabang)||0) === vCabangid
				&& (parseInt(vItems.id_dokter)||0) === (parseInt(uTokenObj.user_dokterid)||0)
				)
		});
		//--END CEK_DATA_LOCALSTORAGE--/

		const vFunctionNext = (_OUTPUTOBJ) => {
			console.log("(Dashboard - apiLoadgender) JamMenitDetik : "+UFunc.JamMenitDetik());
			//console.log("(Dashboard - apiLoadgender) _OUTPUTOBJ 2 : "+JSON.stringify(_OUTPUTOBJ));
			if(_OUTPUTOBJ.tampil) { 
				const vTmpObj		= JSON.parse(_OUTPUTOBJ.dataobjek_str || "{}");

				const vPasientotal	= (parseInt(vTmpObj.pasien_male)||0) + (parseInt(vTmpObj.pasien_female)||0)
				const vAllZeroBl	= vPasientotal === 0
				vTmpObj.pasien_total			= vPasientotal;
				uDatamainArr[vArridx].data_obj 	= vTmpObj;

				const vTglfilterDT 	= UFunc.toDate(vTglfilter);
				const vTglsatuDT	= new Date(uTglnowDT.getFullYear(),uTglnowDT.getMonth(),1);
				const vISBulanlalu	= vTglfilterDT.valueOf() < vTglsatuDT.valueOf();

				if(!vAllZeroBl) {
					if(vDataidx < 0) {
						vDatadashboardObj	= JSON.parse(localStorage.getItem("dashboard_data") || "{}");
						vDataArr			= vDatadashboardObj.data_arr || [];
						const vTmpObj 	= {
							tgl_filter: vTglfilter,
							nama_vars: vNamavars,
							id_cabang: vCabangid,
							id_dokter: uTokenObj.user_dokterid,
							output_string: (_OUTPUTOBJ),
						}

						vDataArr.push(vTmpObj);
					} else vDataArr[vDataidx].output_string	= (_OUTPUTOBJ);

					vDatadashboardObj.data_arr = vDataArr;
					localStorage.setItem("dashboard_data",JSON.stringify(vDatadashboardObj));
				}
			} else if(_OUTPUTOBJ.info) {
				uDatamainArr[vArridx].msg_error = (_OUTPUTOBJ.info);
			} else if(_OUTPUTOBJ.errors) {
				console.log("(Dashboard - apiLoadgender) output_string.errors : "+_OUTPUTOBJ.errors);
				const vMsg 	= (pjson.mydefault.environment==="development")
					? _OUTPUTOBJ.errors : pjson.mydefault.msgFetchError;
				uDatamainArr[vArridx].msg_error = ("<font color='red'>"+vMsg+"</font>");
			} else if(_OUTPUTOBJ.expired) {
				prosesExpired();
			}
			setEfekview2(!uEfekview2);
		}

		return new Promise((onResolve,onReject)=>{
			if(vDataidx >= 0) {
				vFunctionNext(vDataArr[vDataidx].output_string || {})
				onResolve()
				return;
			}

			uDatamainArr[vArridx].msg_error 	= undefined;
			uDatamainArr[vArridx].is_loading 	= true;
			//console.log("(Dashboard - apiLoadgender) uDatamainArr : "+JSON.stringify(uDatamainArr));
			uDatamainArr[vArridx].init_prosesapi = "YA";
			setEfekview(!uEfekview);
		
			const vURLs		= pjson.homepage+"api/api_dashboard/ld_gender";
			const vHeaderObj= _HEADEROBJ || {};
			
			fetch(vURLs,vHeaderObj
			).then((response)=> { if (response.status === 200) { setSessionaktif(false);return response.json(); }}
			).then((output_string) => {
				//console.log("(Dashboard - apiLoadgender) output_string : "+JSON.stringify(output_string));
				setSessionaktif(true);
				uDatamainArr[vArridx].is_loading	= false;
				vFunctionNext(output_string);
				onResolve();
			}).catch((errors)=>{
				uDatamainArr[vArridx].is_loading	= false;
				uDatamainArr[vArridx].msg_error 	= pjson.mydefault.msg500str.join(" ");
				if(errors) console.log("(Dashboard - apiLoadgender) catch-error: "+errors);
				setEfekview2(!uEfekview2);
				onReject(errors);
			});//-*/
		})
	}
	const apiLoadbarulama = (_HEADEROBJ) => {
		const vNamavars	= "widget_barulama";
		let vArridx		= UFunc.getObjectIndeks(uDatamainArr,vNamavars,"nama_vars");

		if(vArridx < 0) {
			const vTmpObj	= {
				nama_vars: vNamavars,
			}
				
			uDatamainArr.push(vTmpObj);
			vArridx = uDatamainArr.length -1;
		} 
		uDatamainArr[vArridx].data_obj = undefined;

		//--CEK_DATA_LOCALSTORAGE--/
		const vKeywordObj		= JSON.parse(localStorage.getItem("listkeyword")) || {};
		const vFilterObj 		= vKeywordObj.filter_dashboard;
		const vTglfilter 		= vFilterObj.tgl_filter;
		const vCabangid 		= parseInt(vFilterObj.id_cabang);
		let vDatadashboardObj	= JSON.parse(localStorage.getItem("dashboard_data") || "{}");
		let vDataArr			= vDatadashboardObj.data_arr || [];

		const vDataidx 	= vDataArr.findIndex(vItems => {
			return (vItems.tgl_filter === vTglfilter
				&& vItems.nama_vars === vNamavars
				&& (parseInt(vItems.id_cabang)||0) === vCabangid
				&& (parseInt(vItems.id_dokter)||0) === (parseInt(uTokenObj.user_dokterid)||0)
				)
		});
		//--END CEK_DATA_LOCALSTORAGE--/

		const vFunctionNext = (_OUTPUTOBJ) => {
			console.log("(Dashboard - apiLoadbarulama) JamMenitDetik : "+UFunc.JamMenitDetik());
			//console.log("(Dashboard - apiLoadbarulama) _OUTPUTOBJ 2 : "+JSON.stringify(_OUTPUTOBJ));
			if(_OUTPUTOBJ.tampil) { 
				const vTmpObj		= JSON.parse(_OUTPUTOBJ.dataobjek_str || "{}");
				uDatamainArr[vArridx].data_obj		= vTmpObj;

				const vPasientotal	= (parseInt(vTmpObj.pasien_total)||0);
				const vAllZeroBl	= vPasientotal === 0

				const vTglfilterDT 	= UFunc.toDate(vTglfilter);
				const vTglsatuDT	= new Date(uTglnowDT.getFullYear(),uTglnowDT.getMonth(),1);
				const vISBulanlalu	= vTglfilterDT.valueOf() < vTglsatuDT.valueOf();

				if(!vAllZeroBl) {
					if(vDataidx < 0) {
						vDatadashboardObj	= JSON.parse(localStorage.getItem("dashboard_data") || "{}");
						vDataArr			= vDatadashboardObj.data_arr || [];
						const vTmpObj 	= {
							tgl_filter: vTglfilter,
							nama_vars: vNamavars,
							id_cabang: vCabangid,
							id_dokter: uTokenObj.user_dokterid,
							output_string: (_OUTPUTOBJ),
						}

						vDataArr.push(vTmpObj);
					} else vDataArr[vDataidx].output_string	= (_OUTPUTOBJ);

					vDatadashboardObj.data_arr = vDataArr;
					localStorage.setItem("dashboard_data",JSON.stringify(vDatadashboardObj));
				}
			} else if(_OUTPUTOBJ.info) {
				uDatamainArr[vArridx].msg_error = (_OUTPUTOBJ.info);
			} else if(_OUTPUTOBJ.errors) {
				console.log("(Dashboard - apiLoadbarulama) output_string.errors : "+_OUTPUTOBJ.errors);
				const vMsg 	= (pjson.mydefault.environment==="development")
					? _OUTPUTOBJ.errors : pjson.mydefault.msgFetchError;
				uDatamainArr[vArridx].msg_error = ("<font color='red'>"+vMsg+"</font>");
			} else if(_OUTPUTOBJ.expired) {
				prosesExpired();
			}
			setEfekview2(!uEfekview2);
		}

		return new Promise((onResolve,onReject)=>{
			if(vDataidx >= 0) {
				vFunctionNext(vDataArr[vDataidx].output_string || {})
				onResolve()
				return;
			}

			uDatamainArr[vArridx].msg_error 	= undefined;
			uDatamainArr[vArridx].is_loading 	= true;
			//console.log("(Dashboard - apiLoadbarulama) uDatamainArr : "+JSON.stringify(uDatamainArr));
			uDatamainArr[vArridx].init_prosesapi = "YA";
			setEfekview(!uEfekview);
		
			const vURLs		= pjson.homepage+"api/api_dashboard/ld_barulama";
			const vHeaderObj= _HEADEROBJ || {};
			
			fetch(vURLs,vHeaderObj
			).then((response)=> { if (response.status === 200) { setSessionaktif(false);return response.json(); }}
			).then((output_string) => {
				//console.log("(Dashboard - apiLoadbarulama) output_string : "+JSON.stringify(output_string));
				setSessionaktif(true);
				uDatamainArr[vArridx].is_loading	= false;
				vFunctionNext(output_string);
				onResolve();
			}).catch((errors)=>{
				uDatamainArr[vArridx].is_loading	= false;
				uDatamainArr[vArridx].msg_error 	= pjson.mydefault.msg500str.join(" ");
				if(errors) console.log("(Dashboard - apiLoadbarulama) catch-error: "+errors);
				setEfekview2(!uEfekview2);
				onReject(errors);
			});//-*/
		})
	}
	const apiLoadpasienaktif = (_HEADEROBJ) => {
		const vNamavars	= "widget_aktif";
		let vArridx		= UFunc.getObjectIndeks(uDatamainArr,vNamavars,"nama_vars");

		if(vArridx < 0) {
			const vTmpObj	= {
				nama_vars: vNamavars,
			}
				
			uDatamainArr.push(vTmpObj);
			vArridx = uDatamainArr.length -1;
		} 
		uDatamainArr[vArridx].data_obj = undefined;

		//--CEK_DATA_LOCALSTORAGE--/
		const vKeywordObj		= JSON.parse(localStorage.getItem("listkeyword")) || {};
		const vFilterObj 		= vKeywordObj.filter_dashboard;
		const vTglfilter 		= vFilterObj.tgl_filter;
		const vCabangid 		= parseInt(vFilterObj.id_cabang);
		let vDatadashboardObj	= JSON.parse(localStorage.getItem("dashboard_data") || "{}");
		let vDataArr			= vDatadashboardObj.data_arr || [];

		const vDataidx 	= vDataArr.findIndex(vItems => {
			return (vItems.tgl_filter === vTglfilter
				&& vItems.nama_vars === vNamavars
				&& (parseInt(vItems.id_cabang)||0) === vCabangid
				)
		});
		//--END CEK_DATA_LOCALSTORAGE--/

		const vFunctionNext = (_OUTPUTOBJ) => {
			console.log("(Dashboard - apiLoadpasienaktif) JamMenitDetik : "+UFunc.JamMenitDetik());
			//console.log("(Dashboard - apiLoadpasienaktif) _OUTPUTOBJ 2 : "+JSON.stringify(_OUTPUTOBJ));
			if(_OUTPUTOBJ.tampil) { 
				const vTmpObj		= JSON.parse(_OUTPUTOBJ.dataobjek_str || "{}");

				const vAllZeroBl	= (parseInt(vTmpObj.pasien_aktif)||0) <= 0 && (parseInt(vTmpObj.pasien_total)||0) <= 0
				uDatamainArr[vArridx].data_obj 	= vTmpObj;

				/*const vTglfilterDT 	= UFunc.toDate(vTglfilter);
				const vTglsatuDT	= new Date(uTglnowDT.getFullYear(),uTglnowDT.getMonth(),1);
				const vISBulanlalu	= vTglfilterDT.valueOf() < vTglsatuDT.valueOf();//-*/

				if(!vAllZeroBl) {
					if(vDataidx < 0) {
						vDatadashboardObj	= JSON.parse(localStorage.getItem("dashboard_data") || "{}");
						vDataArr			= vDatadashboardObj.data_arr || [];
						const vTmpObj 	= {
							tgl_filter: vTglfilter,
							nama_vars: vNamavars,
							id_cabang: vCabangid,
							output_string: (_OUTPUTOBJ),
						}

						vDataArr.push(vTmpObj);
					} else vDataArr[vDataidx].output_string	= (_OUTPUTOBJ);

					vDatadashboardObj.data_arr = vDataArr;
					localStorage.setItem("dashboard_data",JSON.stringify(vDatadashboardObj));
				}
			} else if(_OUTPUTOBJ.info) {
				uDatamainArr[vArridx].msg_error = (_OUTPUTOBJ.info);
			} else if(_OUTPUTOBJ.errors) {
				console.log("(Dashboard - apiLoadpasienaktif) output_string.errors : "+_OUTPUTOBJ.errors);
				const vMsg 	= (pjson.mydefault.environment==="development")
					? _OUTPUTOBJ.errors : pjson.mydefault.msgFetchError;
				uDatamainArr[vArridx].msg_error = ("<font color='red'>"+vMsg+"</font>");
			} else if(_OUTPUTOBJ.expired) {
				prosesExpired();
			}
			setEfekview2(!uEfekview2);
		}

		return new Promise((onResolve,onReject)=>{
			if(vDataidx >= 0) {
				vFunctionNext(vDataArr[vDataidx].output_string || {})
				onResolve()
				return;
			}

			uDatamainArr[vArridx].msg_error 	= undefined;
			uDatamainArr[vArridx].is_loading 	= true;
			//console.log("(Dashboard - apiLoadpasienaktif) uDatamainArr : "+JSON.stringify(uDatamainArr));
			uDatamainArr[vArridx].init_prosesapi = "YA";
			setEfekview(!uEfekview);
		
			const vURLs		= pjson.homepage+"api/api_dashboard/ld_pasienaktif";
			const vHeaderObj= _HEADEROBJ || {};
			
			fetch(vURLs,vHeaderObj
			).then((response)=> { if (response.status === 200) { setSessionaktif(false);return response.json(); }}
			).then((output_string) => {
				//console.log("(Dashboard - apiLoadpasienaktif) output_string : "+JSON.stringify(output_string));
				setSessionaktif(true);
				uDatamainArr[vArridx].is_loading	= false;
				vFunctionNext(output_string);
				onResolve();
			}).catch((errors)=>{
				uDatamainArr[vArridx].is_loading	= false;
				uDatamainArr[vArridx].msg_error 	= pjson.mydefault.msg500str.join(" ");
				if(errors) console.log("(Dashboard - apiLoadpasienaktif) catch-error: "+errors);
				setEfekview2(!uEfekview2);
				onReject(errors);
			});//-*/
		})
	}
	const apiLoadgrafikpasien = (_HEADEROBJ) => {
		const vNamavars	= "grafik_pasien";
		let vArridx		= UFunc.getObjectIndeks(uDatamainArr,vNamavars,"nama_vars");
		if(vArridx < 0) {
			const vTmpObj	= {
				nama_vars: vNamavars,
			}
				
			uDatamainArr.push(vTmpObj);
			vArridx = uDatamainArr.length - 1;
		} 
		uDatamainArr[vArridx].data_obj = undefined;

		//--CEK_DATA_LOCALSTORAGE--/
		const vKeywordObj		= JSON.parse(localStorage.getItem("listkeyword")) || {};
		const vFilterObj 		= vKeywordObj.filter_dashboard;		
		const vTglfilter 		= vFilterObj.tgl_filter;
		const vCabangid 		= parseInt(vFilterObj.id_cabang);
		let vDatadashboardObj	= JSON.parse(localStorage.getItem("dashboard_data") || "{}");
		let vDataArr			= vDatadashboardObj.data_arr || [];

		const vDataidx 	= vDataArr.findIndex(vItems => {
			return (vItems.tgl_filter === vTglfilter
				&& vItems.nama_vars === vNamavars
				&& (parseInt(vItems.id_cabang)||0) === vCabangid
				&& (parseInt(vItems.id_dokter)||0) === (parseInt(uTokenObj.user_dokterid)||0)
			)
		});
		//console.log("(Dashboard - apiLoadgrafikpasien) vDataArr : "+JSON.stringify(vDataArr));
		//console.log("(Dashboard - apiLoadgrafikpasien) uTokenObj.user_dokterid : "+uTokenObj.user_dokterid);
		//--END CEK_DATA_LOCALSTORAGE--/

		const vFunctionNext = (_OUTPUTOBJ) => {
			console.log("(Dashboard - apiLoadgrafikpasien) JamMenitDetik : "+UFunc.JamMenitDetik());
			if(_OUTPUTOBJ.tampil) { 
				//console.log("(Dashboard - apiLoadgrafikpasien) _OUTPUTOBJ.dataarr_str : "+_OUTPUTOBJ.dataarr_str);
				const vTmpArr		= JSON.parse(_OUTPUTOBJ.dataarr_str || "[]");
				const vTmpTotals 	= vTmpArr.reduce((vSUM,vItems)=>vSUM + (parseFloat(vItems.jumlah)||0),0);
				const vAllZeroBl 	= vTmpArr.every(vItems => (parseFloat(vItems.jumlah)||0) === 0);

				const vTmpRata2 	= Math.round(vTmpTotals / (parseInt(vTmpArr.length)||1));
				
				const vLabelXArr	= vTmpArr.map(vItems=> UFunc.toDate(vItems.tanggal).valueOf() );
				const vTmpValueArr	= vTmpArr.map(vItems=> (parseFloat(vItems.jumlah)||0));
				const vTmpRataArr	= vTmpArr.map(vItems=> vTmpRata2);

				const vDatatmpObj	= {
					label_arr: vLabelXArr || [],
					value_arr: vTmpValueArr || [],
					rata_arr: vTmpRataArr || [],
					totals: vTmpTotals,
				}

				const vTimeout	= setTimeout(()=>{
					clearTimeout(vTimeout);

					uDatamainArr[vArridx].data_obj = vDatatmpObj;
					setEfekview2(!uEfekview2);
				},100);

				if(!vAllZeroBl) {
					if(vDataidx < 0) {
						vDatadashboardObj	= JSON.parse(localStorage.getItem("dashboard_data") || "{}");
						vDataArr			= vDatadashboardObj.data_arr || [];
						const vTmpObj 	= {
							tgl_filter: vTglfilter,
							nama_vars: vNamavars,
							id_cabang: vCabangid,
							id_dokter: uTokenObj.user_dokterid,
							output_string: (_OUTPUTOBJ),
						}
						vDataArr.push(vTmpObj);
					} else vDataArr[vDataidx].output_string	= (_OUTPUTOBJ);

					vDatadashboardObj.data_arr = vDataArr;
					localStorage.setItem("dashboard_data",JSON.stringify(vDatadashboardObj));
				}//-*/
			} else if(_OUTPUTOBJ.info) {
				uDatamainArr[vArridx].msg_error = (_OUTPUTOBJ.info);
			} else if(_OUTPUTOBJ.errors) {
				console.log("(Dashboard - apiLoadgrafikpasien) output_string.errors : "+_OUTPUTOBJ.errors);
				const vMsg 	= (pjson.mydefault.environment==="development")
					? _OUTPUTOBJ.errors : pjson.mydefault.msgFetchError;
				uDatamainArr[vArridx].msg_error = ("<font color='red'>"+vMsg+"</font>");
			} else if(_OUTPUTOBJ.expired) {
				prosesExpired();
			}
		}

		return new Promise((onResolve,onReject)=>{
			if(vDataidx >= 0) {
				vFunctionNext(vDataArr[vDataidx].output_string || {})
				onResolve()
				return;
			}
			//console.log("(Dashboard - apiLoadgrafikpasien) vArridx : "+(vArridx));

			uDatamainArr[vArridx].data_obj 		= undefined;
			uDatamainArr[vArridx].msg_error 	= undefined;
			uDatamainArr[vArridx].is_loading 	= true;
			uDatamainArr[vArridx].init_prosesapi = "YA";
			setEfekview(!uEfekview);
		
			const vURLs		= pjson.homepage+"api/api_dashboard/ld_pasienjumlah";
			const vHeaderObj= _HEADEROBJ || {};
			
			fetch(vURLs,vHeaderObj
			).then((response)=> { if (response.status === 200) { setSessionaktif(false);return response.json(); }}
			).then((output_string) => {
				//console.log("(Dashboard - apiLoadgrafikpasien) output_string : "+JSON.stringify(output_string));
				setSessionaktif(true);
				uDatamainArr[vArridx].is_loading	= false;
				vFunctionNext(output_string);
				onResolve();
			}).catch((errors)=>{
				uDatamainArr[vArridx].is_loading	= false;
				uDatamainArr[vArridx].msg_error 	= pjson.mydefault.msg500str.join(" ");
				if(errors) console.log("(Dashboard - apiLoadgrafikpasien) catch-error: "+errors);
				setEfekview2(!uEfekview2);
				onReject(errors);
			});//-*/
		})//-*/
	}
	const apiLoadbirthday = (_HEADEROBJ) => {
		const vNamavars	= "widget_birthday";
		let vArridx		= UFunc.getObjectIndeks(uDatamainArr,vNamavars,"nama_vars");
		if(vArridx < 0) {
			const vTmpObj	= {
				nama_vars: vNamavars,
			}
				
			uDatamainArr.push(vTmpObj);
			vArridx = uDatamainArr.length - 1;
		} 
		uDatamainArr[vArridx].data_obj = undefined;

		//--CEK_DATA_LOCALSTORAGE--/
		const vKeywordObj		= JSON.parse(localStorage.getItem("listkeyword")) || {};
		const vFilterObj 		= vKeywordObj.filter_dashboard;		
		const vTglfilter 		= vFilterObj.tgl_filter;
		const vCabangid 		= parseInt(vFilterObj.id_cabang);
		let vDatadashboardObj	= JSON.parse(localStorage.getItem("dashboard_data") || "{}");
		let vDataArr			= vDatadashboardObj.data_arr || [];

		const vDataidx 	= vDataArr.findIndex(vItems => {
			return (vItems.tgl_filter === vTglfilter
				&& vItems.nama_vars === vNamavars
				&& (parseInt(vItems.id_cabang)||0) === vCabangid
			)
		});
		//--END CEK_DATA_LOCALSTORAGE--/

		const vFunctionNext = (_OUTPUTOBJ) => {
			console.log("(Dashboard - apiLoadbirthday) JamMenitDetik : "+UFunc.JamMenitDetik());
			if(_OUTPUTOBJ.tampil) { 
				//console.log("(Dashboard - apiLoadbirthday) _OUTPUTOBJ.dataarr_str : "+_OUTPUTOBJ.dataarr_str);
				const vTmpArr		= JSON.parse(_OUTPUTOBJ.dataarr_str || "[]");
				const vAllZeroBl 	= false;
				//const vDataviewArr	= vTmpArr.filter((_,vKeys)=>vKeys<5);
				const vDatatmpObj	= {
					data_arr: vTmpArr || [],
				}
				uDatamainArr[vArridx].data_obj = vDatatmpObj;

				const vTglfilterDT 	= UFunc.toDate(vTglfilter);
				const vTglsatuDT	= new Date(uTglnowDT.getFullYear(),uTglnowDT.getMonth(),1);
				const vISBulanlalu	= vTglfilterDT.valueOf() < vTglsatuDT.valueOf();//-*/
				
				if(!vISBulanlalu) {
					if(vDataidx < 0) {
						vDatadashboardObj	= JSON.parse(localStorage.getItem("dashboard_data") || "{}");
						vDataArr			= vDatadashboardObj.data_arr || [];
						const vTmpObj 	= {
							tgl_filter: vTglfilter,
							nama_vars: vNamavars,
							id_cabang: vCabangid,
							output_string: (_OUTPUTOBJ),
						}
						vDataArr.push(vTmpObj);
					} else vDataArr[vDataidx].output_string	= (_OUTPUTOBJ);

					vDatadashboardObj.data_arr = vDataArr;
					localStorage.setItem("dashboard_data",JSON.stringify(vDatadashboardObj));
				}//-*/
			} else if(_OUTPUTOBJ.info) {
				uDatamainArr[vArridx].msg_error = (_OUTPUTOBJ.info);
			} else if(_OUTPUTOBJ.errors) {
				console.log("(Dashboard - apiLoadbirthday) output_string.errors : "+_OUTPUTOBJ.errors);
				const vMsg 	= (pjson.mydefault.environment==="development")
					? _OUTPUTOBJ.errors : pjson.mydefault.msgFetchError;
				uDatamainArr[vArridx].msg_error = ("<font color='red'>"+vMsg+"</font>");
			} else if(_OUTPUTOBJ.expired) {
				prosesExpired();
			}
		}

		return new Promise((onResolve,onReject)=>{
			if(vDataidx >= 0) {
				vFunctionNext(vDataArr[vDataidx].output_string || {})
				onResolve()
				return;
			}
			//console.log("(Dashboard - apiLoadbirthday) vArridx : "+(vArridx));

			uDatamainArr[vArridx].data_obj 		= undefined;
			uDatamainArr[vArridx].msg_error 	= undefined;
			uDatamainArr[vArridx].is_loading 	= true;
			uDatamainArr[vArridx].init_prosesapi = "YA";
			setEfekview(!uEfekview);
		
			const vURLs		= pjson.homepage+"api/api_dashboard/ld_birthday";
			const vHeaderObj= _HEADEROBJ || {};
			
			fetch(vURLs,vHeaderObj
			).then((response)=> { if (response.status === 200) { setSessionaktif(false);return response.json(); }}
			).then((output_string) => {
				//console.log("(Dashboard - apiLoadbirthday) output_string : "+JSON.stringify(output_string));
				setSessionaktif(true);
				uDatamainArr[vArridx].is_loading	= false;
				vFunctionNext(output_string);
				onResolve();
			}).catch((errors)=>{
				uDatamainArr[vArridx].is_loading	= false;
				uDatamainArr[vArridx].msg_error 	= pjson.mydefault.msg500str.join(" ");
				if(errors) console.log("(Dashboard - apiLoadbirthday) catch-error: "+errors);
				setEfekview2(!uEfekview2);
				onReject(errors);
			});//-*/
		})//-*/
	}
	const apiLoadpiepayment = (_HEADEROBJ) => {
		const vNamavars	= "pie_payment";
		let vArridx		= UFunc.getObjectIndeks(uDatamainArr,vNamavars,"nama_vars");
		if(vArridx < 0) {
			const vTmpObj	= {
				nama_vars: vNamavars,
			}
				
			uDatamainArr.push(vTmpObj);
			vArridx = uDatamainArr.length - 1;
		} 
		uDatamainArr[vArridx].data_obj = undefined;

		//--CEK_DATA_LOCALSTORAGE--/
		const vKeywordObj		= JSON.parse(localStorage.getItem("listkeyword")) || {};
		const vFilterObj 		= vKeywordObj.filter_dashboard;
		const vTglfilter 		= vFilterObj.tgl_filter;
		const vCabangid 		= parseInt(vFilterObj.id_cabang);
		let vDatadashboardObj	= JSON.parse(localStorage.getItem("dashboard_data") || "{}");
		let vDataArr			= vDatadashboardObj.data_arr || [];

		const vDataidx 	= vDataArr.findIndex(vItems => {
			return (vItems.tgl_filter === vTglfilter
				&& vItems.nama_vars === vNamavars
				&& (parseInt(vItems.id_cabang)||0) === vCabangid
			)
		});
		//--END CEK_DATA_LOCALSTORAGE--/

		const vFunctionNext = (_OUTPUTOBJ) => {
			console.log("(Dashboard - apiLoadpiepayment) JamMenitDetik : "+UFunc.JamMenitDetik());
			if(_OUTPUTOBJ.tampil) { 
				//console.log("(Dashboard - apiLoadpiepayment) _OUTPUTOBJ.dataarr_str : "+_OUTPUTOBJ.dataarr_str);
				const vTmpArr		= JSON.parse(_OUTPUTOBJ.dataarr_str || "[]");
				const vTotals 	 	= vTmpArr.reduce((vSUM,vItems) => vSUM + (parseFloat(vItems.nilai)||0),0);
				const vAllZeroBl 	= vTotals <= 0;

				const vFilterArr 	= vTmpArr.filter((_,vKeys)=>vKeys < 6);
				const vTotalfilter	= vFilterArr.reduce((vSUM,vItems) => vSUM + (parseFloat(vItems.nilai)||0),0);
				const vNilailainnya	= vTotals - vTotalfilter;

				if(vNilailainnya > 0)
					vFilterArr.push({
						payment_caption: "Metode Lainya",
						nilai: vNilailainnya,
						id_payment: 0,
					});

				const vLabelXArr	= vFilterArr.map(vItems=>vItems.payment_caption);
				const vBgColorArr	= vFilterArr.map(vItems=>UFunc.getRandomColor());
				const vValueArr		= vFilterArr.map(vItems=>(parseFloat(vItems.nilai)||0));
				
				uDatamainArr[vArridx].data_obj = {
					label_arr: vLabelXArr,
					bgcolor_arr: vBgColorArr,
					value_arr: vValueArr,
				}

				if(!vAllZeroBl) {
					if(vDataidx < 0) {
						vDatadashboardObj	= JSON.parse(localStorage.getItem("dashboard_data") || "{}");
						vDataArr			= vDatadashboardObj.data_arr || [];
						const vTmpObj 	= {
							tgl_filter: vTglfilter,
							nama_vars: vNamavars,
							id_cabang: vCabangid,
							output_string: (_OUTPUTOBJ),
						}
						vDataArr.push(vTmpObj);
					} else vDataArr[vDataidx].output_string	= (_OUTPUTOBJ);

					vDatadashboardObj.data_arr = vDataArr;
					localStorage.setItem("dashboard_data",JSON.stringify(vDatadashboardObj));
				} else {
					uDatamainArr[vArridx].data_obj = {
						label_arr: ["No Data"],
						bgcolor_arr: ["#DDDDDD"],
						value_arr: [1],
					}
				}
			} else if(_OUTPUTOBJ.info) {
				uDatamainArr[vArridx].msg_error = (_OUTPUTOBJ.info);
			} else if(_OUTPUTOBJ.errors) {
				console.log("(Dashboard - apiLoadpiepayment) output_string.errors : "+_OUTPUTOBJ.errors);
				const vMsg 	= (pjson.mydefault.environment==="development")
					? _OUTPUTOBJ.errors : pjson.mydefault.msgFetchError;
				uDatamainArr[vArridx].msg_error = ("<font color='red'>"+vMsg+"</font>");
			} else if(_OUTPUTOBJ.expired) {
				prosesExpired();
			}
		}

		return new Promise((onResolve,onReject)=>{
			if(vDataidx >= 0) {
				vFunctionNext(vDataArr[vDataidx].output_string || {})
				onResolve()
				return;
			}
			//console.log("(Dashboard - apiLoadpiepayment) vArridx : "+(vArridx));

			uDatamainArr[vArridx].data_obj 		= undefined;
			uDatamainArr[vArridx].msg_error 	= undefined;
			uDatamainArr[vArridx].is_loading 	= true;
			uDatamainArr[vArridx].init_prosesapi = "YA";
			setEfekview(!uEfekview);
		
			const vURLs		= pjson.homepage+"api/api_dashboard/ld_payment";
			const vHeaderObj= _HEADEROBJ || {};
			
			fetch(vURLs,vHeaderObj
			).then((response)=> { if (response.status === 200) { setSessionaktif(false);return response.json(); }}
			).then((output_string) => {
				//console.log("(Dashboard - apiLoadpiepayment) output_string : "+JSON.stringify(output_string));
				setSessionaktif(true);
				uDatamainArr[vArridx].is_loading	= false;
				vFunctionNext(output_string);
				onResolve();
			}).catch((errors)=>{
				uDatamainArr[vArridx].is_loading	= false;
				uDatamainArr[vArridx].msg_error 	= pjson.mydefault.msg500str.join(" ");
				if(errors) console.log("(Dashboard - apiLoadpiepayment) catch-error: "+errors);
				setEfekview2(!uEfekview2);
				onReject(errors);
			});//-*/
		})//-*/
	}
	const apiLoadfeedoktersingle = (_HEADEROBJ) => {
		const vNamavars	= "card_feedokter";
		let vArridx		= UFunc.getObjectIndeks(uDatamainArr,vNamavars,"nama_vars");

		if(vArridx < 0) {
			const vTmpObj	= {
				nama_vars: vNamavars,
			}
				
			uDatamainArr.push(vTmpObj);
			vArridx = uDatamainArr.length -1;
		} 
		uDatamainArr[vArridx].data_obj = undefined;

		//--CEK_DATA_LOCALSTORAGE--/
		const vKeywordObj		= JSON.parse(localStorage.getItem("listkeyword")) || {};
		const vFilterObj 		= vKeywordObj.filter_dashboard;
		const vTglfilter 		= vFilterObj.tgl_filter;
		const vCabangid 		= parseInt(vFilterObj.id_cabang);
		let vDatadashboardObj	= JSON.parse(localStorage.getItem("dashboard_data") || "{}");
		let vDataArr			= vDatadashboardObj.data_arr || [];

		const vDataidx 	= vDataArr.findIndex(vItems => {
			return (vItems.tgl_filter === vTglfilter
				&& vItems.nama_vars === vNamavars
				&& (parseInt(vItems.id_cabang)||0) === vCabangid
				&& (parseInt(vItems.id_dokter)||0) === (parseInt(uTokenObj.user_dokterid)||0)
				)
		});
		//--END CEK_DATA_LOCALSTORAGE--/

		const vFunctionNext = (_OUTPUTOBJ) => {
			console.log("(Dashboard - apiLoadfeedoktersingle) JamMenitDetik : "+UFunc.JamMenitDetik());
			//console.log("(Dashboard - apiLoadfeedoktersingle) _OUTPUTOBJ 2 : "+JSON.stringify(_OUTPUTOBJ));
			if(_OUTPUTOBJ.tampil) { 
				const vTmpObj		= JSON.parse(_OUTPUTOBJ.dataobjek_str || "{}");

				const vAllZeroBl	= (parseFloat(vTmpObj.nilai)||0) <= 0;
				uDatamainArr[vArridx].data_obj 	= vTmpObj;

				/*const vTglfilterDT 	= UFunc.toDate(vTglfilter);
				const vTglsatuDT	= new Date(uTglnowDT.getFullYear(),uTglnowDT.getMonth(),1);
				const vISBulanlalu	= vTglfilterDT.valueOf() < vTglsatuDT.valueOf();//-*/

				if(!vAllZeroBl) {
					if(vDataidx < 0) {
						vDatadashboardObj	= JSON.parse(localStorage.getItem("dashboard_data") || "{}");
						vDataArr			= vDatadashboardObj.data_arr || [];
						const vTmpObj 	= {
							tgl_filter: vTglfilter,
							nama_vars: vNamavars,
							id_cabang: vCabangid,
							id_dokter: uTokenObj.user_dokterid,
							output_string: (_OUTPUTOBJ),
						}

						vDataArr.push(vTmpObj);
					} else vDataArr[vDataidx].output_string	= (_OUTPUTOBJ);

					vDatadashboardObj.data_arr = vDataArr;
					localStorage.setItem("dashboard_data",JSON.stringify(vDatadashboardObj));
				}
			} else if(_OUTPUTOBJ.info) {
				uDatamainArr[vArridx].msg_error = (_OUTPUTOBJ.info);
			} else if(_OUTPUTOBJ.errors) {
				console.log("(Dashboard - apiLoadfeedoktersingle) output_string.errors : "+_OUTPUTOBJ.errors);
				const vMsg 	= (pjson.mydefault.environment==="development")
					? _OUTPUTOBJ.errors : pjson.mydefault.msgFetchError;
				uDatamainArr[vArridx].msg_error = ("<font color='red'>"+vMsg+"</font>");
			} else if(_OUTPUTOBJ.expired) {
				prosesExpired();
			}
			setEfekview2(!uEfekview2);
		}

		return new Promise((onResolve,onReject)=>{
			if(vDataidx >= 0) {
				vFunctionNext(vDataArr[vDataidx].output_string || {})
				onResolve()
				return;
			}

			uDatamainArr[vArridx].msg_error 	= undefined;
			uDatamainArr[vArridx].is_loading 	= true;
			//console.log("(Dashboard - apiLoadfeedoktersingle) uDatamainArr : "+JSON.stringify(uDatamainArr));
			uDatamainArr[vArridx].init_prosesapi = "YA";
			setEfekview(!uEfekview);
		
			const vURLs		= pjson.homepage+"api/api_dashboard/ld_dokter";
			const vHeaderObj= _HEADEROBJ || {};
			
			fetch(vURLs,vHeaderObj
			).then((response)=> { if (response.status === 200) { setSessionaktif(false);return response.json(); }}
			).then((output_string) => {
				//console.log("(Dashboard - apiLoadfeedoktersingle) output_string : "+JSON.stringify(output_string));
				setSessionaktif(true);
				uDatamainArr[vArridx].is_loading	= false;
				vFunctionNext(output_string);
				onResolve();
			}).catch((errors)=>{
				/*uDatamainArr[vArridx] = {
					...uDatamainArr[vArridx],
					is_loading: false,
					msg_error: pjson.mydefault.msg500str.join(" "),
				}//-*/
				uDatamainArr[vArridx].is_loading	= false;
				uDatamainArr[vArridx].msg_error 	= pjson.mydefault.msg500str.join(" ");
				if(errors) console.log("(Dashboard - apiLoadfeedoktersingle) catch-error: "+errors);
				setEfekview2(!uEfekview2);
				onReject(errors);
			});//-*/
		})
	}

	const apiLoaddata = () => {
		setHtml500msg();//return;
	    initRemoveexpired();

		let vFilterObj = (uKeywordObj.filter_dashboard||{});
		if(JSON.stringify(vFilterObj)==="{}") {
			const vTglNow	= new Date();
			vFilterObj = {
				id_cabang: localStorage.getItem("lastcabangid"),
				tgl_filter: UFunc.DbDate(new Date(vTglNow.getFullYear(),
					vTglNow.getMonth(),1)),
			}
		}
		const vDATAS    = JSON.stringify({
			send_datalist: JSON.stringify(vFilterObj),
			send_tokenauth : uTokenObj.userinit
		});
		const vHeaderObj	= {
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST", mode: "cors",
			cache: "default", body: vDATAS,
		}

		const vElperiode 	= document.getElementById("inpperiode");
		const vElcabang 	= document.getElementById("inpcabangid");
		vElperiode && (vElperiode.disabled = true)
		vElcabang && (vElcabang.disabled = true)
		setLoading(true);
		console.log("(Dashboard - apiLoaddata) => Start LEWAT 1: "+UFunc.JamMenitDetik());
		const vTimeBegin = new Date();

		const vFuncProcess 	= [];
		vFuncProcess.push((() => { return apiLoadreservasi(vHeaderObj); })());

		if(uTokenObj.userhak==="FREEROLE" || uTokenObj.userhak==="ADMIN") {
			vFuncProcess.push((() => { return apiLoadonline(vHeaderObj); })());
			vFuncProcess.push((() => { return apiLoadsatusehat(vHeaderObj); })());
			vFuncProcess.push((() => { return apiLoadwaktu(vHeaderObj); })());
			vFuncProcess.push((() => { return apiLoadgrafikomzet(vHeaderObj); })());
			vFuncProcess.push((() => { return apiLoadfeedokter(vHeaderObj); })());
			vFuncProcess.push((() => { return apiLoadpoinperawat(vHeaderObj); })());
			vFuncProcess.push((() => { return apiLoadtopterapi(vHeaderObj); })());
			vFuncProcess.push((() => { return apiLoadpieusia(vHeaderObj); })());
			vFuncProcess.push((() => { return apiLoadgender(vHeaderObj); })());
			vFuncProcess.push((() => { return apiLoadbarulama(vHeaderObj); })());
			vFuncProcess.push((() => { return apiLoadpasienaktif(vHeaderObj); })());
			//-*/
		} else if(uTokenObj.userhak==="DOKTER") {
			vFuncProcess.push((() => { return apiLoadsatusehat(vHeaderObj); })());
			vFuncProcess.push((() => { return apiLoadwaktu(vHeaderObj); })());
			vFuncProcess.push((() => { return apiLoadfeedoktersingle(vHeaderObj); })());
			vFuncProcess.push((() => { return apiLoadgrafikpasien(vHeaderObj); })());
		} else {
			vFuncProcess.push((() => { return apiLoadsatusehat(vHeaderObj); })());
			vFuncProcess.push((() => { return apiLoadbarulama(vHeaderObj); })());
			vFuncProcess.push((() => { return apiLoadwaktu(vHeaderObj); })());
			vFuncProcess.push((() => { return apiLoadgrafikpasien(vHeaderObj); })());
			vFuncProcess.push((() => { return apiLoadbirthday(vHeaderObj); })());
			vFuncProcess.push((() => { return apiLoadpiepayment(vHeaderObj); })());
		}

		Promise.all(vFuncProcess).then(() => {
			const vTimeEnd = new Date();
			const vTimeDiff = vTimeEnd - vTimeBegin; //in ms

			const vFilterprosesapiArr = uDatamainArr.filter(vItems=>(vItems.init_prosesapi||"TIDAK")==="YA");
			//console.log("(Dashboard - apiLoaddata) vFilterprosesapiArr : "+vFilterprosesapiArr.length);
			if(vFilterprosesapiArr.length > 0) {
				setSessionaktif(false);
				let vTimeout = setTimeout(()=>{
					clearTimeout(vTimeout);
					uDatamainArr.map(vItems=>vItems.init_prosesapi = undefined);

					setSessionaktif(true);
					//console.log("(Dashboard - apiLoaddata) setSessionaktif [3]: "+UFunc.JamMenitDetik());
				},1000);
			}

			setLoading(false);
			vElperiode && (vElperiode.disabled = false)
			vElcabang && (vElcabang.disabled = false)

		    //--GET_VALUE_TIMESTAMP--/
			const vDatadashboardObj	= JSON.parse(localStorage.getItem("dashboard_data") || "{}");
			const vTimestampTS		= vDatadashboardObj.timestampTS || (new Date()).valueOf();
		    //--END GET_VALUE_TIMESTAMP--/

			vDatadashboardObj.timestampTS 	= vTimestampTS;
			localStorage.setItem("dashboard_data",JSON.stringify(vDatadashboardObj));

			console.log("(Dashboard - apiLoaddata) => then [vTimeDiff = "+vTimeDiff+"ms] LEWAT 2x")
		}).catch((vErrorlogs)=>{
			uDatamainArr.map(vItems => vItems.is_loading = undefined);
			uDatamainArr.map(vItems=> vItems.init_prosesapi = undefined);
			setLoading(false);

			console.log("(Dashboard - apiLoaddata) => catch LEWAT 3")
			vElperiode && (vElperiode.disabled = false)
			//setHtml500msg(pjson.mydefault.msg500str.join(" "));
		});
	}
	const apiLoadpartial = (_NAMACARD) => {
		const vNamavars	= _NAMACARD || "";

		let vFilterObj = (uKeywordObj.filter_dashboard||{});
		if(JSON.stringify(vFilterObj)==="{}") {
			const vTglNow	= new Date();
			vFilterObj = {
				id_cabang: localStorage.getItem("lastcabangid"),
				tgl_filter: UFunc.DbDate(new Date(vTglNow.getFullYear(),
					vTglNow.getMonth(),1)),
			}
		}
		const vDATAS    = JSON.stringify({
			send_datalist: JSON.stringify(vFilterObj),
			send_tokenauth : uTokenObj.userinit
		});
		const vHeaderObj	= {
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST", mode: "cors",
			cache: "default", body: vDATAS,
		}

		console.log("(Dashboard - apiLoadpartial) =>vNamavars: "+vNamavars	);
		let vFuncProcess;
		switch (vNamavars.toUpperCase()) {
			case ("widget_reservasi").toUpperCase():
				vFuncProcess = () => apiLoadreservasi(vHeaderObj);
				break;
			case ("widget_user").toUpperCase():
				vFuncProcess = () => apiLoadonline(vHeaderObj);
				break;
			case ("widget_satusehat").toUpperCase():
				vFuncProcess = () => apiLoadsatusehat(vHeaderObj);
				break;
			case ("widget_barulama").toUpperCase():
				vFuncProcess = () => apiLoadbarulama(vHeaderObj);
				break;
			case ("widget_waktu").toUpperCase():
				vFuncProcess = () => apiLoadwaktu(vHeaderObj);
				break;
			case ("grafik_omzet").toUpperCase():
				vFuncProcess = () => apiLoadgrafikomzet(vHeaderObj);
				break;
			case ("grafik_pasien").toUpperCase():
				vFuncProcess = () => apiLoadgrafikpasien(vHeaderObj);
				break;
			case ("progress_terapi").toUpperCase():
				vFuncProcess = () => apiLoadtopterapi(vHeaderObj);
				break;
			case ("progress_feedokter").toUpperCase():
				vFuncProcess = () => apiLoadfeedokter(vHeaderObj);
				break;
			case ("progress_nurse").toUpperCase():
				vFuncProcess = () => apiLoadpoinperawat(vHeaderObj);
				break;
			case ("pie_usia").toUpperCase():
				vFuncProcess = () => apiLoadpieusia(vHeaderObj);
				break;
			case ("widget_gender").toUpperCase():
				vFuncProcess = () => apiLoadgender(vHeaderObj);
				break;
			case ("widget_aktif").toUpperCase():
				vFuncProcess = () => apiLoadpasienaktif(vHeaderObj);
				break;
			case ("widget_birthday").toUpperCase():
				vFuncProcess = () => apiLoadbirthday(vHeaderObj);
				break;
			case ("pie_payment").toUpperCase():
				vFuncProcess = () => apiLoadpiepayment(vHeaderObj);
				break;
			case ("card_feedokter").toUpperCase():
				vFuncProcess = () => apiLoadpiepayment(vHeaderObj);
				break;
			default:
				vFuncProcess = undefined;
		}

		const vElperiode 	= document.getElementById("inpperiode");
		const vElcabang 	= document.getElementById("inpcabangid");
		vElperiode && (vElperiode.disabled = true)
		vElcabang && (vElcabang.disabled = true)

		console.log("(Dashboard - apiLoadpartial) => Start LEWAT 1: "+UFunc.JamMenitDetik());
		const vTimeBegin = new Date();

		vFuncProcess().then(() => {
			const vTimeEnd = new Date();
			const vTimeDiff = vTimeEnd - vTimeBegin; //in ms

			uDatamainArr.map(vItems=>vItems.init_prosesapi = undefined);
			vElperiode && (vElperiode.disabled = false)
			vElcabang && (vElcabang.disabled = false)

			console.log("(Dashboard - apiLoadpartial) => then [vTimeDiff = "+vTimeDiff+"ms] LEWAT 2")
		}).catch((vErrorlogs)=>{
			uDatamainArr.map(vItems=> vItems.init_prosesapi = undefined);
			vElperiode && (vElperiode.disabled = false)
			vElcabang && (vElcabang.disabled = false)

			console.log("(Dashboard - apiLoadpartial) => catch LEWAT 3 => "+vErrorlogs)
		});
	}
	//--END REST_API--/

	React.useEffect(()=>{
		uDispatch({type: "set", effectApp: !uEfekapp});
		uDispatch({type: "set", gUrlbefore: window.location.pathname + window.location.search});

		const vTanggalNowDT = new Date();
		const vTgl1NowDT 	= new Date(vTanggalNowDT.getFullYear(),vTanggalNowDT.getMonth(),1);
		const vTmpfilterObj	= {
			tgl_filter: (UFunc.DbDate(vTgl1NowDT)),
			id_cabang: parseInt(uTokenObj.user_cabangid),
		}
		uKeywordObj.filter_dashboard = vTmpfilterObj;
		localStorage.setItem("listkeyword",JSON.stringify(uKeywordObj));//-*/

		//apiLoaddata();

		return () => { 
			uDispatch({type: "set", gInitHeaderAction: {}});

			//---RESET_KEYWORD--/
			uKeywordObj.filter_dashboard = undefined; 
	    	localStorage.setItem("listkeyword",JSON.stringify(uKeywordObj));
			//---END RESET_KEYWORD--/
			setDlgviewshow(false);
			setShowdokterfee(false);

			/*//--TESTING--/
			document.getElementById("inpperiode") &&
				(document.getElementById("inpperiode").value = UFunc.DbDate(vTgl1NowDT));

			uHeaderActionObj.isHeaderView = true;
			uDispatch({type: "set", gInitHeaderAction: uHeaderActionObj});
			//--END TESTING--*/
		}
	},[])
	React.useEffect(()=>{
		if(!uHandelView) return;

		uHeaderActionObj.isHeaderView = false;
		uDispatch({type: "set", gInitHeaderAction: uHeaderActionObj});

		apiLoaddata();
	},[uHandelView])
	React.useEffect(()=>{
		if(!uISDlgviewshow) {
			setDlgviewObj({}); return
		}
	},[uISDlgviewshow])
	
	//console.log("(Dashboard) uTokenObj.userhak => "+(uTokenObj.userhak));
	
	if(UFunc.isEmpty(uTokenObj)) { prosesExpired(); return ""; }
	if(uHtml500msg)  return (
		<CCard>
		<CCardHeader className="d-flex justify-content-between">
			<b>{uBahasaObj.caption_page500||"Page 500"}</b>
			<CLink className="classcursorpointer classikon classikonreset"
				onClick={()=>apiLoaddata()}/>
		</CCardHeader>
		<CCardBody>{UFunc.renderHTML(uHtml500msg)}</CCardBody>
		</CCard>
	)//>

	if(uTokenObj.userhak === "FREEROLE" || uTokenObj.userhak === "ADMIN") 
	return (
	<>
		<div className="mb-3 d-none">{JSON.stringify(uKeywordObj)}</div>
		<CRow className="align-items-stretch">
		<CCol xs="12" md="6" lg="3" className="pb-3">{contentWidgetreservasi()}</CCol>
		<CCol xs="6" lg="3" className="pb-3">{contentWidgetuser()}</CCol>
		<CCol xs="6" lg="3" className="pb-3">{contentWidgetsatusehat()}</CCol>
		<CCol xs="12" md="6" lg="3" className="pb-3">{contentWidgetwaktu()}</CCol>
		</CRow>

		<CRow className="">
		<CCol>
			<CCard className="mb-3 classbgcard mx-0">
			<CCardHeader className="d-flex justify-content-between">
			<div>
				<small className="fw-bolder">Pendapatan</small>

				<span className="ms-1">&middot;</span>

				<CLink onClick={()=>hdlKlikRefreshparsial("grafik_omzet")} className="classcursorpointer">
				<CIcon icon="cilSync" className="classikonbox" height={18}/>
				</CLink>
			</div>
			<Link to="/suboperasional/laplabarugi" className="text-decoration-none classfontsmaller text-muted">
				<small>
					Lihat Detil
					<CIcon icon="cilArrowRight" className="align-middle"/>
				</small>
			</Link>
			</CCardHeader>

			<CCardBody className="px-0 px-md-3">
			{(uContentgrafikObj.msg_error||"")!=="" ? (
				<>{contentGrafikomzet()}</>
			) : (
				<CRow className="mx-0">
				<CCol md="7" lg="8">{contentGrafikomzet()}</CCol>
				<CCol>
					{contentWidgetomzethari()}
					{contentWidgetomzettotal()}
				</CCol>
				</CRow>
			)}
			</CCardBody>
			</CCard>
		</CCol>
		</CRow>

		<CRow className="align-items-stretch">
		<CCol xs="12" md="4" lg="4" className="pb-3">{contentProgressdokter()}</CCol>
		<CCol xs="12" md="4" lg="4" className="pb-3">{contentProgressnurse()}</CCol>
		<CCol xs="12" md="4" lg="4" className="pb-3">{contentProgressterapi()}</CCol>

		<CCol xs="12" md="6" lg="3" className="pb-3">{contentPieusia()}</CCol>
		<CCol xs="12" md="6" lg="3" className="pb-3">{contentWidgetgender()}</CCol>
		<CCol xs="12" md="6" lg="3" className="pb-3">{contentWidgetlamabaru()}</CCol>
		<CCol xs="12" md="6" lg="3" className="pb-3">{contentWidgetaktif()}</CCol>
		</CRow>
	</>
	)//>

	if(uTokenObj.userhak === "DOKTER") return (
	<>
		<CRow className="align-items-stretch">
		<CCol xs="12" md="6" lg="3" className="pb-3">{contentWidgetreservasi()}</CCol>
		<CCol xs="12" md="6" lg="3" className="pb-3">{contentWidgetsatusehat()}</CCol>
		<CCol xs="12" md="6" lg="3" className="pb-3">{contentCardfeedokter()}</CCol>
		<CCol xs="12" md="6" lg="3" className="pb-3">{contentWidgetwaktu()}</CCol>
		</CRow>

		<CCard className="mb-3 classbgcard">
		<CCardHeader className="d-flex justify-content-between">
			<div>
				<small className="fw-bolder">Jumlah Pasien</small>

				<span className="ms-1">&middot;</span>

				<CLink onClick={()=>hdlKlikRefreshparsial("grafik_pasien")} className="classcursorpointer">
				<CIcon icon="cilSync" className="classikonbox" height={18}/>
				</CLink>
			</div>
			<Link to="/subantrian/statuspasien"
				className="text-decoration-none classfontsmaller text-muted">
				<small>
					Lihat Detil
					<CIcon icon="cilArrowRight" className="align-middle"/>
				</small>
			</Link>
		</CCardHeader>

		<CCardBody>
		{(uContentgrafikObj.msg_error||"")!=="" ? (
			<>{contentGrafikpasien()}</>
		) : (
			<CRow className="">
			<CCol md="7" lg="8">{contentGrafikpasien()}</CCol>

			<CCol>
				{contentWidgetpasienhari()}
				{contentWidgetpasientotal()}
			</CCol>
			</CRow>
		)}
		</CCardBody>
		</CCard>
	</>
	)//>

	return (
	<>
		<CRow className="align-items-stretch">
		<CCol xs="12" md="6" lg="3" className="pb-3">{contentWidgetreservasi()}</CCol>
		<CCol xs="6" md="6" lg="3" className="pb-3">{contentWidgetsatusehat()}</CCol>
		<CCol xs="6" md="6" lg="3" className="pb-3">{contentWidgetlamabaru()}</CCol>
		<CCol xs="12" md="6" lg="3" className="pb-3">{contentWidgetwaktu()}</CCol>
		</CRow>

		<CCard className="mb-3 classbgcard">
		<CCardHeader className="d-flex justify-content-between">
			<div>
				<small className="fw-bolder">Jumlah Pasien</small>

				<span className="ms-1">&middot;</span>

				<CLink onClick={()=>hdlKlikRefreshparsial("grafik_pasien")} className="classcursorpointer">
				<CIcon icon="cilSync" className="classikonbox" height={18}/>
				</CLink>
			</div>
			<Link to="/subantrian/statuspasien"
				className="text-decoration-none classfontsmaller text-muted">
				<small>
					Lihat Detil
					<CIcon icon="cilArrowRight" className="align-middle"/>
				</small>
			</Link>
		</CCardHeader>

		<CCardBody>
		{(uContentgrafikObj.msg_error||"")!=="" ? (
			<>{contentGrafikpasien()}</>
		) : (
			<CRow className="">
			<CCol md="7" lg="8">{contentGrafikpasien()}</CCol>

			<CCol>
				{contentWidgetpasienhari()}
				{contentWidgetpasientotal()}
			</CCol>
			</CRow>
		)}
		</CCardBody>
		</CCard>

		<CRow className="align-items-stretch">
		<CCol md="6" className="mb-3">
			<CCard className={"h-100 classbgcard"+(uISMonthnotNOW?" text-muted":"")}>
			<CCardHeader className="d-flex justify-content-between">
				<small>
				<b>Pasien Berulang Tahun Hari Ini{(()=>{
					const vTglfilterDT 	= UFunc.toDate((uKeywordObj.filter_dashboard||{}).tgl_filter || UFunc.DbDate());
					const vTglsatuDT	= new Date(uTglnowDT.getFullYear(),uTglnowDT.getMonth(),1);
					const vISBulanlalu	= vTglfilterDT.valueOf() < vTglsatuDT.valueOf();

					if(vISBulanlalu) return (<span className="ms-1">(Bulan Lalu)</span>)
				})()}
				</b>
				{(uContentbirthdayObj.msg_error||"")!=="" && (
				<>
				<span className="ms-1">&middot;</span>

				<CLink onClick={()=>hdlKlikRefreshparsial("widget_birthday")} className="classcursorpointer">
				<CIcon icon="cilSync" className="classikonbox" height={18}/>
				</CLink>
				</>
				)}
				</small>

				<CLink onClick={()=>hdlKlikDetilBirthday()}
					className="classcursorpointer text-decoration-none classfontsmaller">
					<small className="text-muted">
						Lihat Detil
						<CIcon icon="cilArrowRight" className="align-middle"/>
					</small>
				</CLink>
			</CCardHeader>

			<CCardBody style={{backgroundColor:(uISMonthnotNOW?"#f0f0f055":"inherit")}}
				className="">{contentWidgetbirthday()}</CCardBody>
			</CCard>
		</CCol>

		<CCol md="6" className="mb-3">{contentPiepayment()}</CCol>
		</CRow>

		<MyDialogview
			options={{size:uDlgviewObj.size,centered:true}}
			show={uISDlgviewshow} 
			dialogHeader={uDlgviewObj.headers}
			toggle={()=>setDlgviewshow(false)}
			renderContent={contentDlgview()}
			{...props}/>
	</>
	)//>
}	

export default Dashboard