import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import {
	CRow,
	CCol,
	CContainer,
	CForm,CFormInput,CFormSelect,CInputGroup,CButton,CFormCheck,
	CInputGroupText,CFormTextarea,
	CCard,
	CCardHeader,
	CCardBody,
	CCardFooter,
	CImage,
	CPagination,
	CPaginationItem,
	CLink,
	CTooltip,CBadge,
	CDropdown,CDropdownToggle,CDropdownMenu,CDropdownItem,CDropdownDivider,
	CSpinner,
} from '@coreui/react';
import CIcon from '@coreui/icons-react'
//import NumberFormat from 'react-number-format';
import { isMobile } from "react-device-detect";
import {
	MyDialoglookup,
	MyDialogform,
} from '../components/index'
import { Konfirm } from '../helpers/onConfirm'
import { UFunc } from '../helpers/functions'
import useSatusehat from '../helpers/useSatusehat'

const pjson 		= require('../../package.json')
const Antrianpasien = (props) => {
	const { 
		setLoading,showToast,prosesExpired,setSessionaktif 
	} = props; 

	//--DOM--/
	const { loadToken } 		= useSatusehat(props);
	const uNavigate 			= useNavigate();
	const uDispatch 			= useDispatch();
	//--END DOM--/

	const uBahasaObj  				= useSelector(state => state.listBahasa);
	const uActiveroute  			= useSelector(state => state.activeRoute);
	const uSettingObj 				= useSelector(state => state.gListUserSetting);
	const uIsScrollBottom			= useSelector(state => state.gIsScrollBottom);
	const uEfekapp					= useSelector(state => state.effectApp);
	const [uEfekview,setEfekview] 	= React.useState(false);
	const uMaxData					= isMobile ? (uSettingObj.jml_mobile || 10) : (uSettingObj.jml_tabel || 30);
	const uKeywordObj				= JSON.parse(localStorage.getItem("listkeyword")) || {};
	const uTokenObj					= JSON.parse(localStorage.getItem("token")||"{}");
	const uISHapusallow				= (uTokenObj.userhak!=="DOKTER") ? true : false;
	const [uHtml500msg,setHtml500msg]	= React.useState(); 
	const [uTimeelapse,setTimeelapse]	= React.useState(0); 
	const uSystemsettingObj			= useSelector(state => state.gSystemsetting);
	
	const uHeaderActionObj  			= useSelector(state => state.gInitHeaderAction);
	const uHandelView 					= uHeaderActionObj.isHeaderView || false;
	const uHandelToggle 				= uHeaderActionObj.isHeaderToggle || false;
	const uLastinitObj 					= useSelector(state => state.gInitTabelSelected);// || {};
	const [uInitselectedObj,setInitselectedObj] = React.useState({});

	//--VIEWS_VARS--/
	const uTokensatusehatObj			= JSON.parse(localStorage.getItem("tokensatusehat") || "{}");
	const uFilterObj 					= uKeywordObj.filter_antrianpasien || {};
	const uTglAntri 					= uFilterObj.tgl_antri || UFunc.DbDate();
	const uCabangid 					= uFilterObj.cabang_id || (parseInt(uTokenObj.user_cabangid)||0);
	const uTglNow 						= UFunc.DbDate();
	const [uFirstload,setFirstload]		= React.useState("YA"); 
	const [uDatamainArr,setDatamainArr]			= React.useState([]);
	const [uDatajamantriArr,setDatajamantriArr]	= React.useState([]);
	const [uRowumumselect,setRowumumselect]		= React.useState(-1);
	const [uRowantrianselect,setRowantrianselect]= React.useState(-1);
	const [uIDselect,setIDselect]				= React.useState(0);
	const uInitshowruang				= useSelector(state => state.gInitshowruang);
	const [uISRuangshow,setRuangshow]	= React.useState(uInitshowruang 
		? (uInitshowruang==="YA" ? true : false) 
		: (uTokenObj.userhak==="ENTRI" ? true : false));

	const uDatacabangArr					= JSON.parse(localStorage.getItem("cabanglist")||"[]")
	const uCabangselectedObj 				= useSelector(state => state.gCabangatribut) || {};
	const [uCabanginitObj,setCabanginitObj] = React.useState(uCabangselectedObj);
	//--END VIEWS_VARS--/

	//---DLGFORM_VARS--/
	const [uDatadokterArr,setDatadokterArr]		= React.useState([]);
	const [uISDlgformshow,setDlgformshow]		= React.useState(false);
	const [uDlgformObj,setDlgformObj]			= React.useState({});
	//---END DLGFORM_VARS--/

	//---DLG_LOOKUP--/
	const [uISDlglookupshow,setDlglookupshow]	= React.useState(false);
	const [uDlglookupObj,setDlglookupObj]		= React.useState({});
	//---END DLG_LOOKUP--/

	/*//--DLG_FULLSCREEN--/
	const [uISDlgfullscreenshow,setDlgfullscreenshow]		= React.useState(false);
	const [uDlgfullscreenshowObj,setDlgfullscreenshowObj]	= React.useState({});
	//--END DLG_FULLSCREEN--*/

	//--DOM_VARS--/
	//const vContent	= document.getElementById("idmaincontent");
	const uScreenwidth		= window.outerWidth;
	const uISMobilescreen	= uScreenwidth <= 768;
	//--END DOM_VARS--/

	//--HANDEL--/
	const hdlKlikPasien = (_IDTABEL) => {
		_IDTABEL = parseInt(_IDTABEL)||0; if(_IDTABEL <= 0) return;

		//console.log("(Antrianpasien - hdlKlikPasien) uDatamainArr => "+JSON.stringify(uDatamainArr))
		//console.log("(Antrianpasien - hdlKlikPasien) _IDTABEL => "+(_IDTABEL))
		const vArridx	= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL)
		const uPasienID	= uDatamainArr[vArridx].pasien_id || 0;

		const vTmpObj	= { initID:_IDTABEL }
		if(uTokenObj.userhak === "DOKTER") {
			const vISHadir = (uDatamainArr[vArridx].jam_hadir||"") !== "";

			if(vISHadir)
				uNavigate("/subantrian/statuspasien");
			else {
				uDispatch({type: "set", gInitTabelSelected: vTmpObj})
				uNavigate("/subpasien/pasien/rekammedis/"+uPasienID);
			}
			return;
		}

		uDispatch({type: "set", gInitTabelSelected: vTmpObj})
		uNavigate("/subpasien/pasien/daftarantri/"+uPasienID);
	}
	const hdlKDownDoktercaption = (_EV) => {
		uDlgformObj.dokter_id 		= "0";
		if(_EV.keyCode !== 13)  return;
		if((uDlgformObj.dokter_caption||"").trim() === "") return;

		_EV.preventDefault()
		_EV.stopPropagation()

		const vElfocus = document.getElementById("btnDialogSimpan");
		const vfilterArr = uDatadokterArr.filter(vItems => 
			(vItems.dokter_caption||"").toUpperCase().
				includes((uDlgformObj.dokter_caption||"").trim().toUpperCase())
		)

		if(vfilterArr.length === 1) {
			uDlgformObj.dokter_id 		= (vfilterArr[0].id||"0");
			uDlgformObj.dokter_caption 	= (vfilterArr[0].dokter_caption||"");
			uDlgformObj.dokter_ihs 		= (vfilterArr[0].ihs_dokter||"");

			vElfocus && vElfocus.focus();
			return
		}

		hdlKlikDokterlookup();
	}
	const hdlKlikDokterlookup = () => {
		const vDatalookupArr = uDatadokterArr.map(vItems =>{ 
			return {id: vItems.id,caption:vItems.dokter_caption} 
		})

		uDlglookupObj.items_arr		= vDatalookupArr;
		uDlglookupObj.dlg_headers	= (uBahasaObj.word_lookup||"Lookup")+": "+
			(uBahasaObj.caption_datadokter||"Data Dokter");

		uDlglookupObj.inputvalue 	= uDlgformObj.dokter_caption||"";
		uDlgformObj.dokter_caption 	= "";
		uDlgformObj.dokter_id 		= "0";
		uDlgformObj.dokter_ihs 		= "";

		setDlglookupshow(true)
	}
	const hdlKlikDlglookuppilih = (_IDITEM) => {
		_IDITEM = parseInt(_IDITEM)||0; if(_IDITEM <= 0) return

		setDlglookupshow(false);

		const vArridx 	= UFunc.getObjectIndeks(uDatadokterArr,_IDITEM)
		uDlgformObj.dokter_id 		= (uDatadokterArr[vArridx].id||"0");
		uDlgformObj.dokter_caption 	= (uDatadokterArr[vArridx].dokter_caption||"");
		uDlgformObj.dokter_ihs 		= (uDatadokterArr[vArridx].ihs_dokter||"");

		let vTimeout = setTimeout(function(){
			clearTimeout(vTimeout)

			const vElfocus = document.getElementById("btnDialogSimpan");
			if(!isMobile) vElfocus && vElfocus.focus();
		},350)
	} 
	const hdlKlikKonfirmwa = async(_IDTABEL) => {
		_IDTABEL = parseInt(_IDTABEL)||0; if(_IDTABEL <= 0) return;

		setIDselect(_IDTABEL);
		const vArridx		= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL)
		const vCaptionupper = UFunc.Usercaptionfilter(uDatamainArr[vArridx].pasien_caption||"").toUpperCase();

		let vRowidx	= UFunc.getTabelRowIndeks("idtabelumum","idtr"+_IDTABEL)
		if(vRowidx >= 0)
			setRowumumselect(vRowidx);
		else {
			vRowidx	= UFunc.getTabelRowIndeks("idtabeljanjian","idtr"+_IDTABEL)
			setRowantrianselect(vRowidx);
		}

		let vHPPasien		= (uDatamainArr[vArridx]||{}).pasien_telp || "";
		let vIsipesan		= UFunc.br2nl(uCabanginitObj.wa_template_rsvp || "");

		//--GANTI_PARAMETER_PESAN--/
		const vPenghubung	= (parseInt(uDatamainArr[vArridx].dokter_id)||0) > 0 ? " -" : "";
		vIsipesan			= vIsipesan.replace(/\[NAMAPASIEN\]/g,(uDatamainArr[vArridx].pasien_caption||"").toUpperCase()); 
		vIsipesan			= vIsipesan.replace(/\[NAMADOKTER\]/g,(uDatamainArr[vArridx].dokter_caption||"").toUpperCase()+vPenghubung); 
		vIsipesan			= vIsipesan.replace(/\[NAMAKLINIK\]/g,(uCabanginitObj.nama_klinik||"").toUpperCase()); 
		vIsipesan			= vIsipesan.replace(/\[TANGGAL\]/g,UFunc.HariAngka(uDatamainArr[vArridx].tgl_antri)); 
		vIsipesan			= vIsipesan.replace(/\[TELPKLINIK\]/g,"+62"+(uCabanginitObj.wa_device||"")); 

		vIsipesan			= vIsipesan.replace(/\[JAM\]/g,(uDatamainArr[vArridx].jam_awal||"--").substring(0,5)); 
		//--END GANTI_PARAMETER_PESAN--/

		//--VALIDASI_HPPASIEN--/
		if(vHPPasien.charAt(0) === '0')
			vHPPasien		= vHPPasien.substring(1);
		vHPPasien		= vHPPasien.replace("+62","");
		vHPPasien		= vHPPasien.replace(/ /g,"");
		vHPPasien		= vHPPasien.replace(/-/g,"");
		//--END VALIDASI_HPPASIEN--/

		//--JIKA_TOKEN_DEVICE_DAN_URLTARGET_KOSONG--/
		if((uCabanginitObj.wa_device||"")===""
			|| (uCabanginitObj.wa_token||"")===""
			|| (uCabanginitObj.wa_target_url||"")===""
			) {
			if(await Konfirm(
				"<b>Token Whatsapp API Gateway Belum DISETTING</b>.."+
				"<br/>Konfirmasi WA Akan DIALIHKAN ke Aplikasi Lainnya.."+
				"<p class='px-2'><small>Untuk Dapat Menggunakan WA API Gateway, silahkan Setting Melalui <b>Master Data</b> >> <b>Setting</b> >> <b>KONFIGURASI KLINIK</b>.</small></p>"+
				"Apakah Anda Akan Tetap Melanjutkan ?"+
				"")) {
				const vUrlnext	= "https://api.whatsapp.com/send/?"+
					"phone=62"+vHPPasien+
					"&text="+UFunc.br2nl(vIsipesan)+
					"";
				window.open(vUrlnext, '_blank').focus();
			}
			return;
		}
		//--END JIKA_TOKEN_DEVICE_DAN_URLTARGET_KOSONG--*/

		setDlgformObj({...uDlgformObj,
			dlg_headers: "Konfirmasi WA: <b>"+vCaptionupper+"</b>..",
			dlg_jenis: "KIRIMWA",
			tabelid: _IDTABEL,
			dlg_size: "lg",
			no_hp_pasien: vHPPasien,
			isi_pesan: vIsipesan,
			no_hp_sender: uCabanginitObj.wa_device || "",
		})
		setDlgformshow(true);

		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout);
			const vButton = document.getElementById("btnDialogSimpan");
			vButton.innerText = vButton.textContent = "Kirim";
		},250);
	}
	const hdlKlikStatus = async(_IDTABEL) => {
		_IDTABEL = parseInt(_IDTABEL)||0; if(_IDTABEL <= 0) return;

		setIDselect(_IDTABEL);
		const vArridx		= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL)
		const vCaptionupper = UFunc.Usercaptionfilter(uDatamainArr[vArridx].pasien_caption||"").toUpperCase();

		let vRowidx	= UFunc.getTabelRowIndeks("idtabelumum","idtr"+_IDTABEL)
		if(vRowidx >= 0)
			setRowumumselect(vRowidx);
		else {
			vRowidx	= UFunc.getTabelRowIndeks("idtabeljanjian","idtr"+_IDTABEL)
			setRowantrianselect(vRowidx);
		}

		let vStatushadir		= (uDatamainArr[vArridx].jam_hadir||"")==="" ? "BELUM" : "HADIR";
		if(UFunc.isEmpty(uDatamainArr[vArridx].periksa_init)===false) vStatushadir = "PERIKSA";

		if(vStatushadir === "HADIR") {
			if(await(Konfirm("Pembatalan Kehadiran: <b>"+vCaptionupper+"</b>.."+
				"<br/>Apakah Anda Yakin ?"+
				""))) {
				//setDlgformObj({...uDlgformObj,tabelid : _IDTABEL});
				uDlgformObj.tabelid = _IDTABEL;
				apiProseshadir();
			}

			return;
		}
		if(vStatushadir === "PERIKSA") {
			if(await(Konfirm("Pembatalan Status PERIKSA: <B>"+vCaptionupper+"</B>.."+
				"<p class='classfontsmaller px-2'>Pasien Tersebut Masih Dalam Status Periksa oleh Perangkat Lain, "+
				"dan Perangkat Tersebut Sudah Tidak Lagi Aktif Untuk melakukan BILLING PERIKSA."+
				"</p>"+
				"<br/><br/>Apakah Anda Yakin ?"+
				""))) apiProsesbatalperiksa(_IDTABEL);
			return;
		}

		const vDokterID 	= parseInt(uDatamainArr[vArridx].dokter_id)||0;
		setDlgformObj({...uDlgformObj,
			dlg_headers: "SET Kehadiran: <b>"+vCaptionupper+"</b>..",
			dlg_jenis: "STATUSRSVP",
			tabelid: _IDTABEL,
			is_satusehat: false,
			status_init: "HADIR",
			dokter_id: vDokterID > 0 ? vDokterID : undefined,
			dokter_caption: vDokterID > 0 ? uDatamainArr[vArridx].dokter_caption : undefined,
			dokter_ihs: vDokterID > 0 ? uDatamainArr[vArridx].dokter_ihs : undefined,
		})

		if(uDatadokterArr.length <= 0) {
			apiLoaddokter().then(()=>{
				setDlgformshow(true);
			}).catch((vErrorlogs)=>{
				console.log("(Antrianpasien - hdlKlikStatus) => catch LEWAT => "+vErrorlogs)
			});
			return;
		}

		setDlgformshow(true);
	}
	const hdlKlikStatusx = async(_IDTABEL) => {//--TIDAK_DIPAKAI--/
		_IDTABEL = parseInt(_IDTABEL)||0; if(_IDTABEL <= 0) return;

		setIDselect(_IDTABEL);
		const vArridx		= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL)
		const vCaptionupper = UFunc.Usercaptionfilter(uDatamainArr[vArridx].pasien_caption||"").toUpperCase();

		let vRowidx	= UFunc.getTabelRowIndeks("idtabelumum","idtr"+_IDTABEL)
		if(vRowidx >= 0)
			setRowumumselect(vRowidx);
		else {
			vRowidx	= UFunc.getTabelRowIndeks("idtabeljanjian","idtr"+_IDTABEL)
			setRowantrianselect(vRowidx);
		}

		if((uDatamainArr[vArridx].jam_hadir||"")!=="") {
			if(await(Konfirm("Pembatalan Kehadiran: <b>"+vCaptionupper+"</b>.."+
				"<br/>Apakah Anda Yakin ?"+
				""))) {
				uDlgformObj.tabelid = _IDTABEL;
				apiProseshadir();//-*/
			}
			return;
		}

		if(uDatadokterArr.length <= 0) {
			apiLoaddokter().then(()=>{
				setDlgformshow(true);
			}).catch((vErrorlogs)=>{
				console.log("(Antrianpasien - hdlKlikStatusupdate) => catch LEWAT 3 => "+vErrorlogs)
			});
			return;
		}

		initSetkehadiran(_IDTABEL);
	}
	const hdlKlikBatalperiksa = async(_IDTABEL) => {//--TIDAK_DIPAKAI--/
		_IDTABEL = parseInt(_IDTABEL)||0; if(_IDTABEL <= 0) return;
		
		const vArridx		= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL)
		const vCaptionupper = (uDatamainArr[vArridx].pasien_caption||"").toUpperCase();
		setIDselect(_IDTABEL);

		let vRowidx	= UFunc.getTabelRowIndeks("idtabelumum","idtr"+_IDTABEL)
		if(vRowidx >= 0)
			setRowumumselect(vRowidx);
		else {
			vRowidx	= UFunc.getTabelRowIndeks("idtabeljanjian","idtr"+_IDTABEL)
			setRowantrianselect(vRowidx);
		}
	
		if(await(Konfirm("Pembatalan Status PERIKSA: <B>"+vCaptionupper+"</B>.."+
			"<br/>Pasien Tersebut Masih Dalam Status Periksa oleh Perangkat Lain, "+
			"dan Perangkat Tersebut Sudah Tidak Lagi Aktif Untuk melakukan BILLING PERIKSA."+
			"<br/><br/>Apakah Anda Yakin ?"+
			""))) apiProsesbatalperiksa(_IDTABEL);
	}
	const hdlKlikHapus = async(_IDTABEL) => {
		_IDTABEL = parseInt(_IDTABEL)||0; if(_IDTABEL <= 0) return;
		
		setIDselect(_IDTABEL);
		const vArridx	= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL)
		const vCaptionupper = (uDatamainArr[vArridx].pasien_caption||"").toUpperCase();

		let vRowidx	= UFunc.getTabelRowIndeks("idtabelumum","idtr"+_IDTABEL)
		if(vRowidx >= 0)
			setRowumumselect(vRowidx);
		else {
			vRowidx	= UFunc.getTabelRowIndeks("idtabeljanjian","idtr"+_IDTABEL)
			setRowantrianselect(vRowidx);
		}
	
		if(await(Konfirm("Hapus Antrian: <B>"+vCaptionupper+"</B>.."+
			"<br/><br/>Apakah Anda Yakin ?"+
			""))) apiProseshapus(_IDTABEL);
	}
	const hdlKlikDlgformsimpan = () => {
		if(uDlgformObj.dlg_jenis === "STATUSRSVP") return prosesStatussvp();
		if(uDlgformObj.dlg_jenis === "KIRIMWA") return prosesKiriimwa();
	}

	const prosesStatussvp = async() => {
		const vArridx 	= UFunc.getObjectIndeks(uDatamainArr,uDlgformObj.tabelid);

		let vElfocus	= document.getElementById("inpdoktercaption");
		if((parseInt(uDlgformObj.dokter_id)||0) <= 0) {
			vElfocus && vElfocus.focus();
			showToast("Dokter Rujukan Harus Ditentukan Terlebih Dahulu !"); return;
		}//-*/
		
		if((uDlgformObj.is_satusehat||false)===true) {
			if(((uDatamainArr[vArridx]||{}).ihs_pasien ||"") === ""
				&& (uDlgformObj.dokter_ihs||"") === "" 
			) {
				if(await Konfirm(
				"<b>Pasien dan Dokter Belum Memiliki IHS Number</b>.."+
				"<small class='fst-italic'>"+
				"<p>Jika IHS Number  Belum Diset, Data Tidak Dapat Dikirim ke SERVER SATUSEHAT.</p>"+
				"</small>"+
				"Apakah Tetap Akan Melanjutkan (TANPA IHS NUMBER) ?"+
				"")) apiProseshadir();
				return;
			}

			if(((uDatamainArr[vArridx]||{}).ihs_pasien ||"") === "") {
				if(await Konfirm(
				"<b>Pasien Belum Memiliki IHS Number</b>.."+
				"<small class='fst-italic'>"+
				"<p>Jika IHS Pasien Belum Diset, Data Tidak Dapat Dikirim ke SERVER SATUSEHAT.</p>"+
				"<p>Anda Dapat melakukan Setting IHS Pasien melalui Menu: Pasien >> Data Pasien >> <b>EDIT DATA</b>.</p>"+
				"</small>"+
				"Apakah Tetap Akan Melanjutkan (TANPA IHS PASIEN) ?"+
				"")) apiProseshadir();

				return;
			}
			
			if((uDlgformObj.dokter_ihs||"") === "") {
				if(await Konfirm(
				"<b>Dokter Rujukan Belum Memiliki IHS Number</b>.."+
				"<small class='fst-italic'>"+
				"<p>Jika IHS DOKTER Belum Diset, Data Tidak Dapat Dikirim ke SERVER SATUSEHAT.</p>"+
				"<p>Anda Dapat melakukan Setting IHS Dokter melalui Menu: Master Data >> Data Dokter >> <b>EDIT DATA</b>.</p>"+
				"</small>"+
				"Apakah Tetap Akan Melanjutkan (TANPA IHS DOKTER) ?"+
				"")) apiProseshadir();

				return;
			}
		}

		apiProseshadir();
	}
	const prosesKiriimwa = () => {
		//--HARUS_LOGIN_DG_CABANG_DAN_CABANGFILTER_YG_SAMA--/
		const vCabangidfilter 	= parseInt(uCabangid)||0;
		const vCabangiduser		= (parseInt(uTokenObj.user_cabangid)||0);
		if(vCabangiduser !== vCabangidfilter) {
			showToast("<b>Anda Login pada Cabang yang BERBEDA</b>.."+
				"<br/><br/>Pengiriman Konfirmasi WA Hanya Dapat Dilakukan jika Cabang yang dipilih Sesuai Login Anda !"+
				"");return
		}
		//--END HARUS_LOGIN_DG_CABANG_DAN_CABANGFILTER_YG_SAMA--/

		let vElfocus = document.getElementById("inpnohptujuan");
		if((uDlgformObj.no_hp_pasien||"").trim()==="") {
			vElfocus && vElfocus.focus();
			showToast("Nomor HP Tujuan (Pasien) Harus Diisi !"); return;
		}
		vElfocus = document.getElementById("inpnohpsender");
		if((uDlgformObj.no_hp_sender||"").trim()==="") {
			vElfocus && vElfocus.focus();
			showToast("Nomor HP Sender Harus Diisi !"); return;
		}
		vElfocus = document.getElementById("inpisipesan");
		if((uDlgformObj.isi_pesan||"").trim()==="") {
			vElfocus && vElfocus.focus();
			showToast("Isi Pesan Harus Diisi !"); return;
		}

		let vHPPasien 	= (uDlgformObj.no_hp_pasien || "").trim();
		if(uDlgformObj.no_hp_pasien.charAt(0) === '0')
			vHPPasien		= vHPPasien.substring(1);
		vHPPasien		= vHPPasien.replace("+62","");
		vHPPasien		= vHPPasien.replace(/ /g,"");
		vHPPasien		= vHPPasien.replace(/-/g,"");
		setDlgformObj({...uDlgformObj,no_hp_pasien: vHPPasien});

		apiProseskirimwa();
	}

	/*const hdlKlikRuangan = () => {//--TIDAK_DIPAKAI--/
		uDlgfullscreenshowObj.dlg_headers	= "View Reserved Ruangan";
		uDlgfullscreenshowObj.tgl_ruangan	= uTglAntri;
		uDlgfullscreenshowObj.cabang_id		= uCabangid;
		uDlgfullscreenshowObj.data_arr		= uDatamainArr.filter(vItems => 
			parseInt(vItems.no_ruang||"0") > 0).
			map(vItems => {
				vItems.tgl_awal = UFunc.toDatetime(UFunc.DbDate()+" "+vItems.jam_awal);
				return vItems;
			}).
			sort((a,b) => a.tgl_awal - b.tgl_awal).map(vItems => {
				vItems.tgl_awal = undefined;
				return vItems;
			});

		setDlgfullscreenshow(true);
	}
	const hdlKlikViewruangan = () => {//--TIDAK_DIPAKAI--/
		//--VALIDASI_TANGGAL--/
		//--END VALIDASI_TANGGAL--/

		apiLoadruangan();
	}
	const hdlKlikRuangpasien = (_IDTABEL) => {//--TIDAK_DIPAKAI--/
		_IDTABEL = parseInt(_IDTABEL)||0; if(_IDTABEL <= 0) return;

		const vDataviewArr	= uDlgfullscreenshowObj.data_arr || [];
		const vArridx	= UFunc.getObjectIndeks(vDataviewArr,_IDTABEL)
		const vPasienID	= vDataviewArr[vArridx].pasien_id || 0;

		if(uTokenObj.userhak === "DOKTER") {
			const vTglantri		= uDlgfullscreenshowObj.tgl_ruangan || UFunc.DbDate();
			const vTglnow		= UFunc.DbDate();

			if(vTglantri !== vTglnow) 
				{ return; }
			if((vDataviewArr[vArridx].dokter_id||"0").toString() !== (uTokenObj.user_dokterid||"0").toString())
				{ return; }

			uNavigate("/subpasien/pasien/rekammedis/"+vPasienID);
			return;
		}

		uNavigate("/subpasien/pasien/daftarantri/"+vPasienID);
	}//-*/
	//--END HANDEL--/

	//--INIT--/
	const initSetkehadiran = async(_IDTABEL) => {//--TIDAK_DIPAKAI--/
		_IDTABEL = parseInt(_IDTABEL)||0; if(_IDTABEL <= 0) return;

		setIDselect(_IDTABEL);
		const vArridx	= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL)
		const vCaptionupper = UFunc.Usercaptionfilter(uDatamainArr[vArridx].pasien_caption||"").toUpperCase();

		uDlgformObj.dlg_headers	= "SET Kehadiran: <b>"+vCaptionupper+"</b>..";
		uDlgformObj.tabelid 	= _IDTABEL;

		if((parseInt(uDatamainArr[vArridx].dokter_id)||0) > 0) {
			uDlgformObj.dokter_id 		= uDatamainArr[vArridx].dokter_id;
			uDlgformObj.dokter_caption 	= uDatamainArr[vArridx].dokter_caption;
			uDlgformObj.dokter_ihs 		= uDatamainArr[vArridx].dokter_ihs;
		}
		/*uDlgformObj.is_satusehat 	= (parseInt(uDatamainArr[vArridx].dokter_id)||0) > 0 &&
			((uTokensatusehatObj.access_token||"")!=="");//-*/

		uDlgformObj.is_satusehat 	= false
		uDlgformObj.status_init 	= "HADIR";
		setDlgformshow(true);
	}
	//--END INIT--/

	//--CONTENT--/
	const contentCardheaderumum = () => {
		if(uHtml500msg) return "";

		return (
		<CCardHeader className="d-flex justify-content-between align-items-center text-white"
				style={{backgroundColor:"#993366"}}>
			<b>{uBahasaObj.caption_reservasiumum||"Reservasi Umum"}</b>

			<small>(
				{(()=>{
					const vDataviewArr = uDatamainArr.filter(vItems=>(parseInt(vItems.dokter_id)||0)<=0);
					return UFunc.formatAngka(vDataviewArr.length)
				})()}
			)</small>
		</CCardHeader>
		)//->
	}
	const contentCardheaderjanjian = () => {
		if(uHtml500msg) return "";

		return (
			<CCardHeader className="d-flex justify-content-between align-items-center text-white"
				style={{backgroundColor:"#666699"}}>
			<b>{uBahasaObj.caption_reservasijanjian||"Reservasi PERJANJIAN"}</b>
			
			<div>
			<small>(
				{(()=>{
					let vDataviewArr	= uDatamainArr.filter(vItems=>(parseInt(vItems.dokter_id)||0)>0);
					if(uTokenObj.userhak==="DOKTER")
						vDataviewArr	= uDatamainArr.filter(vItems=>
							(parseInt(vItems.dokter_id)||0)===(parseInt(uTokenObj.user_dokterid)||0)
						);
					return UFunc.formatAngka(vDataviewArr.length)
				})()}
			)</small>

			<span className="mx-1">&middot;</span>

			<span className="classfontsmaller"><small>{UFunc.formatAngka(uTimeelapse)}ms</small></span>
			</div>
			</CCardHeader>
		)//->
	}
	const contentDataumum = () => {
		if(uHtml500msg) return "";

		const vTglnow		= UFunc.TglAngka();
		const vCabangiduser	= (parseInt(uTokenObj.user_cabangid)||0);
		const vDataviewArr	= uDatamainArr.filter(vItems=>(parseInt(vItems.dokter_id)||0)<=0);
		const vTglfilter	= UFunc.TglAngka(uTglAntri);
		const vCabangidfilter= parseInt(uCabangid);

		if (uScreenwidth <= 425) return (
		<table className="table table-borderless table-striped table-hover" id="idtabelumum">
			<thead>
			<tr className="text-center align-top classfontsmaller">
				<th width="10%" className="p-0"/>
				<th className="p-0"/>
			</tr>
			</thead>

			<tbody>
			{vDataviewArr.map((vItems,vKeys) => {
				const {
					id,pasien_id,pasien_caption,pasien_telp,no_urut,jam_hadir,keterangan,
					proses_init,periksa_init,status_piutang
				} = vItems;

				const vNumber 		= vKeys+1;
				const vCaptionupper	= (pasien_caption||"").toUpperCase();

				let vStatushadir		= (jam_hadir||"")==="" ? "BELUM" : "HADIR";
				if(UFunc.isEmpty(periksa_init)===false) vStatushadir = "PERIKSA";

				let vStatuscolor		= "secondary";
				let vKehadirancaption 	= "BELUM HADIR";
				let vStatusclass 		= "";
				let vStatustooltip		= "--Klik Untuk SET HADIR";
				let vActionstatus		= "Set KEHADIRAN";
				if(vStatushadir==="HADIR") {
					vStatuscolor 		= "primary";
					vKehadirancaption 	= "HADIR &middot; "+(jam_hadir||"00:00").substring(0,5);
					vStatustooltip		= "--Klik Untuk MEMBATALKAN Kehadiran";
					vActionstatus		= "BATALKAN Kehadiran";
				} else if(vStatushadir==="PERIKSA") {
					vStatuscolor 		= "warning";
					vKehadirancaption 	= "PERIKSA";
					vStatusclass 		= " classblinking";
					vStatustooltip		= "--Klik Untuk MEMBATALKAN PERIKSA";
					vActionstatus		= "BATALKAN PERIKSA";
				}

				return (
				<tr id={"idtr"+id} key={vKeys} 
					className={"align-top text-end "+(uRowumumselect===vKeys?"classrowselect border border-warning":"")}>
					<td className="px-1 text-center"><b>{(no_urut)}</b></td>

					<td className="px-1">
					<CRow className="mx-0">
					<CCol xs="12" md="10" className="px-1 text-start d-flex justify-content-between align-items-start">
						<div>
							<b className="text-capitalize text-info">
								{(pasien_caption||"UNDF")}
							</b>
							{(status_piutang||"TIDAK")==="YA" && (
							<>
								<span className="mx-1">&middot;</span>
								<CTooltip content={"--Status ADA PIUTANG"}>
								<CImage src={pjson.homepage+"api/images/icon_piutang.png"} className="align-top" height={30}/>
								</CTooltip>
							</>
							)}

							{(keterangan||"")!=="" && (
							<div className="classfontsmaller"><small className="fst-italic">{keterangan}</small></div>
							)}
						</div>

						<CDropdown>
						<CDropdownToggle caret={false} className="p-0" color="transparent">
							<CIcon icon="cilOptions" className="me-0" height={18}/>
						</CDropdownToggle>
						<CDropdownMenu>
							<CDropdownItem onClick={()=>hdlKlikPasien(id)}>
								{uTokenObj.userhak==="DOKTER" ? (
								<CImage src={pjson.homepage+"api/images/icon_rekammedis.png"} className="align-middle mx-0 me-2" height={25}/>
								) : (
								<CImage src={pjson.homepage+"api/images/icon_booking.png"} className="align-middle mx-0 me-2" height={25}/>
								)}
								Proses Antrian
							</CDropdownItem>

							<CDropdownItem onClick={()=>hdlKlikKonfirmwa(id)}>
								<CIcon icon="cibWhatsapp" className="classikonbox bg-success text-white p-1 mx-0 me-2" height={23}/>
								Konfirmasi WA
							</CDropdownItem>

							{uISHapusallow && (
							<>
								{(vCabangidfilter === vCabangiduser
									&& vTglnow===vTglfilter
								) && (
								<>
								<CDropdownItem onClick={()=>hdlKlikStatus(id)}>
									<CIcon icon="cilTouchApp" className="classikonbox mx-0 me-2" height={23}/>
									{vActionstatus}
								</CDropdownItem>
								</>
								)}

								<CDropdownDivider/>
								<CDropdownItem onClick={()=>hdlKlikHapus(id)}>
									<CIcon className="classikonhapus align-middle mx-0 me-2" height={25}/>
									{uBahasaObj.caption_hapusantrian||"Hapus Antrian"}
								</CDropdownItem>
							</>
							)}
						</CDropdownMenu>
						</CDropdown>
					</CCol>

					{(uTokenObj.userhak!=="DOKTER" && (pasien_telp||"").trim()!=="") && (
					<>
					<CCol xs="12" className="px-1 my-3 classborderbottom"/>

					<CCol xs="4" className="px-1 text-start">{uBahasaObj.caption_notelp||"No.Telp"}</CCol>
					<CCol xs="8" className="px-1">
						<b className="text-success">{pasien_telp||"-"}</b>
					</CCol>
					</>
					)}

					{(vTglnow===vTglfilter) && (
					<>
					<CCol xs="12" className="px-1 my-3 classborderbottom"/>

					<CCol xs="6" className="px-1 text-start">Status RSVP</CCol>
					<CCol xs="6" className="px-1">
						<CBadge className="py-2" color={vStatuscolor}>{UFunc.renderHTML(vKehadirancaption)}</CBadge>
					</CCol>

					</>
					)}
					</CRow>
					</td>
				</tr>
				)
			})}
			</tbody>
		</table>
		)//->

		return (
		<table className="table table-borderless table-striped table-hover" id="idtabelumum">
			<thead>
			<tr className="d-none d-md-table-row text-center align-top classfontsmaller">
				<th width="10%" className="px-1"><small>No<br/>Urut</small></th>
				<th className="px-1 text-start"><small>{uBahasaObj.caption_namapasien||"Nama Pasien"}</small></th>
				<th width="5%" className="px-1">&middot;</th>
				<th width="25%" className="px-1"><small>Status RSVP</small></th>
			</tr>
			</thead>

			<tbody>
			{vDataviewArr.map((vItems,vKeys) => {
				const {
					id,pasien_id,pasien_caption,pasien_telp,no_urut,jam_hadir,keterangan,
					proses_init,periksa_init,status_piutang
				} = vItems;

				const vNumber 		= vKeys+1;
				const vCaptionupper	= (pasien_caption||"").toUpperCase();

				let vStatushadir		= (jam_hadir||"")==="" ? "BELUM" : "HADIR";
				if(UFunc.isEmpty(periksa_init)===false) vStatushadir = "PERIKSA";

				let vStatuscolor		= "secondary";
				let vKehadirancaption 	= "BELUM";
				let vStatusclass 		= "";
				let vStatustooltip		= "--Klik Untuk SET HADIR";
				if(vStatushadir==="HADIR") {
					vStatuscolor 		= "primary";
					vKehadirancaption 	= "HADIR &middot; "+(jam_hadir||"00:00").substring(0,5);
					vStatustooltip		= "--Klik Untuk MEMBATALKAN Kehadiran";
				} else if(vStatushadir==="PERIKSA") {
					vStatuscolor 		= "warning";
					vKehadirancaption 	= "PERIKSA";
					vStatusclass 		= " classblinking";
					vStatustooltip		= "--Klik Untuk MEMBATALKAN PERIKSA";
				}

				return (
				<tr id={"idtr"+id} key={vKeys} 
					className={"align-top "+(uRowumumselect===vKeys?"classrowselect border border-warning":"")}>
					<td className="px-1 text-center"><b>{(no_urut)}</b></td>

					<td className="px-1">
						<b onClick={()=>hdlKlikPasien(id)}
							className="text-capitalize text-info classcursorpointer d-none d-md-inline">
							{(pasien_caption||"UNDF")}
						</b>
						{(status_piutang||"TIDAK")==="YA" && (
						<>
							<span className="mx-1">&middot;</span>
							<CTooltip content={"--Status ADA PIUTANG"}>
							<CImage src={pjson.homepage+"api/images/icon_piutang.png"} className="align-top" height={30}/>
							</CTooltip>
						</>
						)}

						{(keterangan||"")!=="" && (
							<div className="classfontsmaller"><small className="fst-italic text-capitalize">{keterangan}</small></div>
						)}

						{(uTokenObj.userhak!=="DOKTER" && (pasien_telp||"").trim()!=="") && (
							<small className="text-success d-block">
							Telp/HP. <b>{pasien_telp||"-"}</b>
							</small>
						)}
					</td>

					<td className="px-1 text-center">
						<CDropdown>
						<CDropdownToggle caret={false} className="p-0" color="transparent">
							<CIcon icon="cilChevronBottom" className="classikonbox mx-0" height={25}/>
						</CDropdownToggle>
						<CDropdownMenu>
							<CDropdownItem className="classcursorpointer" onClick={()=>hdlKlikKonfirmwa(id)}>
								<CIcon icon="cibWhatsapp" className="classikonbox bg-success text-white p-1 mx-0 me-2" height={25}/>
								Konfirmasi WA
							</CDropdownItem>

							{uISHapusallow && (
							<>
							<CDropdownDivider/>
							<CDropdownItem className="classcursorpointer" onClick={()=>hdlKlikHapus(id)}>
								<CIcon className="classikonhapus align-middle mx-0 me-2" height={25}/>
								{uBahasaObj.caption_hapusreservasi||"Hapus Reservasi"}
							</CDropdownItem>
							</>
							)}
						</CDropdownMenu>
						</CDropdown>
					</td>

					<td className="px-1 text-center">
						{(vTglnow===vTglfilter) ? (
						<>
						{(uISHapusallow 
							&& vCabangiduser===vCabangidfilter
							) ? (
							<CTooltip className="d-none d-lg-block" content={vStatustooltip}>
							<CLink className="classcursorpointer" onClick={()=>hdlKlikStatus(id)}>
								<CBadge className={"py-2"+vStatusclass} color={vStatuscolor}>{UFunc.renderHTML(vKehadirancaption)}</CBadge>
							</CLink>
							</CTooltip>
						) : (
							<CBadge className="py-2" color={vStatuscolor}>{UFunc.renderHTML(vKehadirancaption)}</CBadge>
						)}
						</>
						) : (<>&middot;</>) }
					</td>
				</tr>
				)
			})}
			</tbody>
		</table>
		)//>
	}
	const contentDatajanjian = () => {
		if(uHtml500msg) return ""

		const vTglnow		= UFunc.TglAngka();
		const vCabangiduser	= (parseInt(uTokenObj.user_cabangid)||0);
		const vTglfilter	= UFunc.TglAngka(uTglAntri);
		const vCabangidfilter= parseInt(uCabangid);
		const vArrcolor  	= ["#FE100111","#33FE0111","#AA01FE11","#0174FE11"] 

		let vDataviewArr	= uDatamainArr.filter(vItems=>(parseInt(vItems.dokter_id)||0)>0);
		if(uTokenObj.userhak==="DOKTER")
			vDataviewArr	= uDatamainArr.filter(vItems=>
				(parseInt(vItems.dokter_id)||0)===(parseInt(uTokenObj.user_dokterid)||0)
			);

		let vNumber 		= 0;
		let vDokteridbefore	= 0;
		let vColoridx		= -1;

		if (uScreenwidth <= 425) return (
		<table className="table table-borderless table-hover" id="idtabeljanjian">
			<thead>
				<tr className="text-center align-top classfontsmaller">
				<th width="7%" className="p-0"/>
				<th className="p-0"/>
				</tr>
			</thead>

			<tbody>
			{vDataviewArr.map((vItems,vKeys) => {
				const {
					id,pasien_id,pasien_caption,pasien_telp,dokter_id,dokter_caption,
					jam_awal,jam_hadir,keterangan,status_piutang,
					proses_init,periksa_init,
				} = vItems;

				vNumber++;
				const vCaptionupper	= (pasien_caption||"").toUpperCase();

				let vStatushadir		= (jam_hadir||"")==="" ? "BELUM" : "HADIR";
				if(UFunc.isEmpty(periksa_init)===false) vStatushadir = "PERIKSA";

				let vStatuscolor		= "secondary";
				let vKehadirancaption 	= "BELUM HADIR";
				let vStatusclass 		= "";
				let vStatustooltip		= "--Klik Untuk SET HADIR";
				let vActionstatus		= "Set KEHADIRAN";
				if(vStatushadir==="HADIR") {
					vStatuscolor 		= "primary";
					vKehadirancaption 	= "HADIR &middot; "+(jam_hadir||"00:00").substring(0,5);
					vStatustooltip		= "--Klik Untuk MEMBATALKAN Kehadiran";
					vActionstatus		= "BATALKAN Kehadiran";
				} else if(vStatushadir==="PERIKSA") {
					vStatuscolor 		= "warning";
					vKehadirancaption 	= "PERIKSA";
					vStatusclass 		= " classblinking";
					vStatustooltip		= "--Klik Untuk MEMBATALKAN PERIKSA";
					vActionstatus		= "BATALKAN PERIKSA";
				}

				if(vDokteridbefore !== (dokter_id||"0").toString()) vColoridx++;
				vColoridx		= vColoridx%(vArrcolor.length);

				const vBgcolor 	= vArrcolor[vColoridx];
				vDokteridbefore	= (dokter_id||"0").toString();

				return (
				<tr id={"idtr"+id} key={vKeys} 
					style={{backgroundColor:vBgcolor}}
					className={"align-top text-end"+(uRowantrianselect===vKeys?" bg-light border border-warning":" classborderbottom")}>
					<td className="px-1 pe-0">{vNumber}.</td>

					<td className="px-1">
					<CRow className="mx-0">
					<CCol xs="12" md="5" className="px-1 text-start d-flex justify-content-between align-items-start">
						<div>
							<b className="text-capitalize text-info">
								{(pasien_caption||"UNDF")}
							</b>
							{(status_piutang||"TIDAK")==="YA" && (
							<>
								<span className="mx-1">&middot;</span>
								<CTooltip content={"--Status ADA PIUTANG"}>
								<CImage src={pjson.homepage+"api\/images\/icon_piutang.png"} 
									className="align-top" height={30}/>
								</CTooltip>
							</>
							)}

							{(keterangan||"")!=="" && (
							<div className="classfontsmaller"><small className="fst-italic text-capitalize">{keterangan}</small></div>
							)}
						</div>

						<CDropdown>
						<CDropdownToggle caret={false} className="p-0" color="transparent">
							<CIcon icon="cilOptions" className="me-0" height={18}/>
						</CDropdownToggle>
						<CDropdownMenu>
							<CDropdownItem onClick={()=>hdlKlikPasien(id)}>
								{uTokenObj.userhak==="DOKTER" ? (
								<CImage src={pjson.homepage+"api/images/icon_rekammedis.png"} className="align-middle mx-0 me-2" height={25}/>
								) : (
								<CImage src={pjson.homepage+"api/images/icon_booking.png"} className="align-middle mx-0 me-2" height={25}/>
								)}
								Proses Antrian
							</CDropdownItem>

							<CDropdownItem onClick={()=>hdlKlikKonfirmwa(id)}>
								<CIcon icon="cibWhatsapp" className="classikonbox bg-success text-white p-1 mx-0 me-2" height={23}/>
								Konfirmasi WA
							</CDropdownItem>

							{uISHapusallow && (
							<>
								{(vCabangidfilter === vCabangiduser
									&& vTglnow===vTglfilter
								) && (
								<>
								<CDropdownItem onClick={()=>hdlKlikStatus(id)}>
									<CIcon icon="cilTouchApp" className="classikonbox mx-0 me-2" height={23}/>
									{vActionstatus}
								</CDropdownItem>
								</>
								)}

								<CDropdownDivider/>
								<CDropdownItem onClick={()=>hdlKlikHapus(id)}>
									<CIcon className="classikonhapus align-middle mx-0 me-2" height={25}/>
									{uBahasaObj.caption_hapusantrian||"Hapus Antrian"}
								</CDropdownItem>
							</>
							)}
						</CDropdownMenu>
						</CDropdown>
					</CCol>

					<CCol xs="12" className="px-1 my-3 classborderbottom"/>

					{(uTokenObj.userhak!=="DOKTER" && (pasien_telp||"").trim()!=="") && (
					<>
					<CCol xs="4" className="px-1 text-start">{uBahasaObj.caption_notelp||"No.Telp"}</CCol>
					<CCol xs="8" className="px-1">
						<b className="text-success">{pasien_telp||"-"}</b>
					</CCol>
					</>
					)}

					<CCol xs="4" className="px-1 text-start">{uBahasaObj.word_dokter||"Dokter"}</CCol>
					<CCol xs="8" md="3" className="px-1 text-md-start">
						<small className="fst-italic">{UFunc.Usercaptionfilter((dokter_caption||"UNDF"),3)}</small>
					</CCol>

					<CCol xs="5" className="px-1 text-start">Jam Janjian</CCol>
					<CCol xs="7" md="2" className="px-1 text-md-center text-danger fw-bolder">{(jam_awal||"").substring(0,5)}</CCol>

					{(vTglnow===vTglfilter) && (
					<>
					<CCol xs="12" className="px-1 my-3 classborderbottom"/>

					<CCol xs="6" className="px-1 text-start">Status RSVP</CCol>
					<CCol xs="6" className="px-1">
						<CBadge className="py-2" color={vStatuscolor}>{UFunc.renderHTML(vKehadirancaption)}</CBadge>
					</CCol>

					</>
					)}
					</CRow>
					</td>
				</tr>
				)
			})}
			</tbody>
		</table>
		)//->

		return (
		<table className="table table-borderless table-hover" id="idtabeljanjian">
			<thead>
				<tr className="d-none d-md-table-row text-center align-top classfontsmaller">
				<th width="5%" className="px-1"><small>No</small></th>
				<th className="px-1 text-start"><small>{uBahasaObj.caption_namapasien||"Nama Pasien"}</small></th>
				<th width="5%" className="px-1">&middot;</th>
				<th width="25%" className="px-1 text-start"><small>{uBahasaObj.word_dokter||"Dokter"}</small></th>
				<th width="7%" className="px-1"><small>Jam<br/>Janjian</small></th>
				<th width="18%" className="px-1"><small>Status RSVP</small></th>
				</tr>
			</thead>
			<tbody>
			{vDataviewArr.map((vItems,vKeys) => {
				const {
					id,pasien_id,pasien_caption,pasien_telp,dokter_id,dokter_caption,
					jam_awal,jam_hadir,keterangan,status_piutang,
					proses_init,periksa_init,
				} = vItems;

				vNumber++;
				const vCaptionupper	= (pasien_caption||"").toUpperCase();

				let vStatushadir		= (jam_hadir||"")==="" ? "BELUM" : "HADIR";
				if(UFunc.isEmpty(periksa_init)===false) vStatushadir = "PERIKSA";

				//vStatushadir = "HADIR";

				let vStatuscolor		= "secondary";
				let vKehadirancaption 	= "BELUM";
				let vStatusclass 		= "";
				let vStatustooltip		= "--Klik Untuk SET HADIR";
				if(vStatushadir==="HADIR") {
					vStatuscolor 		= "primary";
					vKehadirancaption 	= "HADIR &middot; "+(jam_hadir||"00:00").substring(0,5);
					vStatustooltip		= "--Klik Untuk MEMBATALKAN Kehadiran";
				} else if(vStatushadir==="PERIKSA") {
					vStatuscolor 		= "warning";
					vKehadirancaption 	= "PERIKSA";
					vStatusclass 		= " classblinking";
					vStatustooltip		= "--Klik Untuk MEMBATALKAN PERIKSA";
				}

				if(vDokteridbefore !== (dokter_id||"0").toString()) vColoridx++;
				vColoridx		= vColoridx%(vArrcolor.length);

				const vBgcolor 	= vArrcolor[vColoridx];
				vDokteridbefore	= (dokter_id||"0").toString();

				return (
				<tr id={"idtr"+id} key={vKeys} 
					style={{backgroundColor:vBgcolor}}
					className={"align-top text-start"+(uRowantrianselect===vKeys?" bg-light border border-warning":" classborderbottom")}>
					<td className="px-1 text-end">{vNumber}.</td>

					<td className="px-1">
						<b onClick={()=>hdlKlikPasien(id)}
							className="text-capitalize text-info classcursorpointer d-none d-md-inline">
							{(pasien_caption||"UNDF")}
						</b>
						{(status_piutang||"TIDAK")==="YA" && (
						<>
							<span className="mx-1">&middot;</span>
							<CTooltip content={"--Status ADA PIUTANG"}>
							<CImage src={pjson.homepage+"ap\/images\/icon_piutang.png"} 
								className="align-top" height={30}/>
							</CTooltip>
						</>
						)}

						{(keterangan||"")!=="" && (
						<div className="classfontsmaller"><small className="fst-italic text-capitalize">{keterangan}</small></div>
						)}

						{(uTokenObj.userhak!=="DOKTER" && (pasien_telp||"").trim()!=="") && (
						<small className="text-success d-block">
						Telp/HP. <b>{pasien_telp||""}</b>
						</small>
						)}
					</td>

					<td className="px-1 text-center">
						<CDropdown>
						<CDropdownToggle caret={false} className="p-0" color="transparent">
							<CIcon icon="cilChevronBottom" className="classikonbox mx-0" height={25}/>
						</CDropdownToggle>
						<CDropdownMenu>
							<CDropdownItem className="classcursorpointer" onClick={()=>hdlKlikKonfirmwa(id)}>
								<CIcon icon="cibWhatsapp" className="classikonbox bg-success text-white p-1 mx-0 me-2" height={25}/>
								Konfirmasi WA
							</CDropdownItem>

							{uISHapusallow && (
							<>
							<CDropdownDivider/>
							<CDropdownItem className="classcursorpointer" onClick={()=>hdlKlikHapus(id)}>
								<CIcon className="classikonhapus align-middle mx-0 me-2" height={25}/>
								{uBahasaObj.caption_hapusantrian||"Hapus Antrian"}
							</CDropdownItem>
							</>
							)}
						</CDropdownMenu>
						</CDropdown>
					</td>

					<td className="px-1 text-start">
						<small className="fw-bolder">{UFunc.Usercaptionfilter((dokter_caption||"UNDF"),3)}</small>
					</td>

					<td className="px-1 text-center text-danger fw-bolder">{(jam_awal||"").substring(0,5)}</td>
					
					<td className="px-1 text-center">
						{(vTglnow===vTglfilter) ? (
						<>
						{(uISHapusallow 
							&& vCabangiduser===vCabangidfilter
							) ? (
							<CTooltip className="d-none d-lg-block" content={vStatustooltip}>
							<CLink className="classcursorpointer" onClick={()=>hdlKlikStatus(id)}>
								<CBadge className={"py-2"+vStatusclass} color={vStatuscolor}>{UFunc.renderHTML(vKehadirancaption)}</CBadge>
							</CLink>
							</CTooltip>
						) : (
							<CBadge className="py-2" color={vStatuscolor}>{UFunc.renderHTML(vKehadirancaption)}</CBadge>
						)}
						</>
						) : (<>&middot;</>) }
					</td>
				</tr>
				)
			})}
			</tbody>
		</table>
		)//->
	}
	const contentRuangumum = () => {
		if(uHtml500msg) return ""
		if(!uISRuangshow) return "";

		const vDataviewArr	= uDatamainArr.filter(vItems=>(parseInt(vItems.dokter_id)||0)<=0);
		const vWidthtabel 	= 950;
		const vWidthcell 	= 200;
		const vStyleBGbox	= "#00008011";
		const vTglantriDT	= UFunc.toDate(uTglAntri);

		return (
		<>
			<div className="d-none">{JSON.stringify(vDataviewArr)}</div>
			<table className="table mx-auto" id="idtabelumum">
			<thead>
				<tr className="text-center classfontsmaller">
				<th className="p-0"/>
				</tr>
			</thead>

			<tbody>
			<tr className="classfontsmaller align-middle">
				<td className="p-0 text-center d-flex"
					style={{minHeight:100,height:"auto",flexWrap: "wrap"}}>
				{vDataviewArr.map((vItems,vKeys)=>{
					const {
						id,pasien_id,pasien_caption,pasien_telp,no_urut,jam_hadir,keterangan,
						proses_init,periksa_init,status_piutang,tgl_lahir
					} = vItems;

					const vPasienArr = (pasien_caption||"").split(" ");

					let vStatushadir		= (jam_hadir||"")==="" ? "BELUM" : "HADIR";
					if(UFunc.isEmpty(periksa_init)===false) vStatushadir = "PERIKSA";

					let vStatuscolor		= "secondary";
					let vKehadirancaption 	= "BELUM HADIR";
					let vStatusclass 		= "";
					let vStatustooltip		= "--Klik Untuk SET HADIR";
					let vActionstatus		= "Set KEHADIRAN";
					if(vStatushadir==="HADIR") {
						vStatuscolor 		= "primary";
						vKehadirancaption 	= "HADIR &middot; "+(jam_hadir||"00:00").substring(0,5);
						vStatustooltip		= "--Klik Untuk MEMBATALKAN Kehadiran";
						vActionstatus		= "BATALKAN Kehadiran";
					} else if(vStatushadir==="PERIKSA") {
						vStatuscolor 		= "warning";
						vKehadirancaption 	= "PERIKSA";
						vStatusclass 		= " classblinking";
						vStatustooltip		= "--Klik Untuk MEMBATALKAN PERIKSA";
						vActionstatus		= "BATALKAN PERIKSA";
					}

					let vISbirthday	= false;
					if((tgl_lahir||"")!=="") {
						const vTgllahirDT 	= UFunc.toDate(tgl_lahir);

						vISbirthday = vTgllahirDT.getDate()===vTglantriDT.getDate() &&
							vTgllahirDT.getMonth()===vTglantriDT.getMonth();
					}
					//vISbirthday	= true;//--TESTING--/

					return (
					<div className="px-1 py-2 m-1 position-relative"
						style={{width:vWidthcell,border:"3px dashed navy",borderRadius:7,backgroundColor:vStyleBGbox}}
						id={"idumum"+id}
						key={vKeys}>
						<b className="text-info text-uppercase d-block pt-2">{(vPasienArr[0]+" "+(vPasienArr[1]||"")).trim()}</b>
						<div className="">(Urutan ke-<b>{no_urut}</b>)</div>
						<small className="text-danger d-none">[{UFunc.Usercaptionfilter(vItems.inputlabel,1)}]</small>

						<div className="my-2 classborderbottom"/>

						<div>
							<CBadge color={vStatuscolor} className={"py-2 "+vStatusclass}>{UFunc.renderHTML(vKehadirancaption)}</CBadge>

							{vISbirthday && (
							<>
							<span className="mx-1">&middot;</span>
							<CTooltip className="d-none d-lg-block" content="--Pasien Berulang Tahun (Siapkan Kejutan..!)">
							<CImage src={pjson.homepage+"api\/images\/icon_birthday.png"} className="classikonbox mx-0 classblinking p-0" height={25}/>
							</CTooltip>
							</>
							)}
						</div>

						<CDropdown className="position-absolute" style={{top:2,right:5,zIndex:"1000 !important"}}>
							<CDropdownToggle caret={false} className="p-0" color="transparent">
								<CIcon icon="cilChevronBottom" className="me-0 classikonbox" height={23}/>
							</CDropdownToggle>
							<CDropdownMenu>
								<CDropdownItem onClick={()=>hdlKlikPasien(vItems.id)} className="classcursorpointer">
									{uTokenObj.userhak==="DOKTER" ? (
									<CImage src={pjson.homepage+"api/images/icon_rekammedis.png"} className="align-middle mx-0 me-2" height={25}/>
									) : (
									<CImage src={pjson.homepage+"api/images/icon_booking.png"} className="align-middle mx-0 me-2" height={25}/>
									)}
									Proses Reservasi
								</CDropdownItem>

								{uISHapusallow && (
								<>
								<CDropdownItem onClick={()=>hdlKlikKonfirmwa(vItems.id)} className="classcursorpointer">
									<CIcon icon="cibWhatsapp" className="classikonbox text-white bg-success p-1 mx-0 me-2" height={23}/>
									Konfirmasi Whatsapp
								</CDropdownItem>

								<CDropdownItem onClick={()=>hdlKlikStatus(vItems.id)} className="classcursorpointer">
									<CIcon icon="cilTouchApp" className="classikonbox mx-0 me-2" height={23}/>
									{vActionstatus}
								</CDropdownItem>

								<CDropdownDivider/>

								<CDropdownItem onClick={()=>hdlKlikHapus(vItems.id)} className="classcursorpointer">
									<CIcon className="classikonhapus align-middle mx-0 me-2" height={25}/>
									{uBahasaObj.caption_hapusantrian||"Hapus Antrian"}
								</CDropdownItem>
								</>
								)}
							</CDropdownMenu>
						</CDropdown>
					</div>
					)//->
				})}
				</td>
			</tr>
			</tbody>
			</table>
		</>
		)//->
	}
	const contentRuangjanjian = () => {
		if(uHtml500msg) return ""
		if(!uISRuangshow) return "";

		let vDataviewArr	= uDatamainArr.filter(vItems=>(parseInt(vItems.dokter_id)||0)>0);
		if(uTokenObj.userhak==="DOKTER")
			vDataviewArr	= uDatamainArr.filter(vItems=>
				(parseInt(vItems.dokter_id)||0)===(parseInt(uTokenObj.user_dokterid)||0)
			);

		const vTglantriDT	= UFunc.toDate(uTglAntri);
		const vJmlruangan	= parseInt(uCabanginitObj.jml_ruangan) || 2;
		let vArrruangan		= [];
		for(let i=1;i<=vJmlruangan;i++) vArrruangan.push(i);

		let vNumber			= 0;

		const vWidthno		= 30;
		const vWidthFirstkol= 155;
		const vWidthkolrg	= 225;
		const vWidthtabel	= vWidthno+vWidthFirstkol+(vWidthkolrg*vJmlruangan)

		const vStylekolfixed = {
			position: "sticky",
			left: 0,
			background: "white",
			zIndex: 1,
		}

		return (
		<div style={{overflowX:"auto"}} id="iddivtabel">
			<div className="d-none">{JSON.stringify(vDataviewArr)}</div>
			<table 
				style={{width:vWidthtabel,borderCollapse:"separate"}}
				className="table " 
				id="idtabeljanjian">
			<thead>
				<tr className="d-none d-md-table-row text-center classfontsmaller">
				<th width={vWidthno} className="bg-white" style={vStylekolfixed}><small>No</small></th>
				<th width={vWidthFirstkol} className="bg-white" style={{...vStylekolfixed,left:vWidthno}}><small>Jam Reservasi</small></th>
				{vArrruangan.map((vItemruang,vKeyruang)=>{
					return (
					<th width={vWidthkolrg} key={vKeyruang}>
						<small>Ruang {vItemruang}</small>
					</th>
					)
				})}
				</tr>

				<tr className="d-md-none text-center classfontsmaller">
				<th width={vWidthno} className="p-0" style={vStylekolfixed}/>
				<th width={vWidthFirstkol} className="p-0" style={{...vStylekolfixed,left:vWidthno}}/>
				<th colSpan={vJmlruangan} className="p-0"/>
				</tr>
			</thead>

			<tbody>
			{uDatajamantriArr.map((vItemjam,vKeyjam)=>{
				const {
					jam_awal,jam_akhir,id
				} = vItemjam;

				vNumber++;

				const vJamAwalDT	= UFunc.toDatetime(uTglAntri+" "+vItemjam.jam_awal);
				const vJamAkhirDT	= UFunc.toDatetime(uTglAntri+" "+vItemjam.jam_akhir);

				return (
				<tr id={"idtr"+id} key={vKeyjam} style={{height:100}} className="align-middle text-center">
				<td className="border text-end bg-light" style={vStylekolfixed}>{vNumber}.</td>
				<td className="border text-center bg-light" style={{...vStylekolfixed,left:vWidthno}}>
					{UFunc.JamMenit(vJamAwalDT)} - {UFunc.JamMenit(vJamAkhirDT)}
				</td>
				{vArrruangan.map((vItemruang,vKeyruang)=>{
					const vFiltercontentArr = vDataviewArr.filter(vItems=>{
						const vJamRgawalDT = UFunc.toDatetime(
							uTglAntri+" "+vItems.jam_awal
							);
						const vJamRgakhirDT = UFunc.toDatetime(
							uTglAntri+" "+vItems.jam_akhir
							);

						return (parseInt(vItems.no_ruang||0)===vItemruang)
							&& ((vJamRgawalDT >= vJamAwalDT) && (vJamRgawalDT < vJamAkhirDT) 
								|| (vJamRgakhirDT <= vJamAkhirDT) && (vJamRgakhirDT > vJamAwalDT) 
								|| (vJamAkhirDT > vJamRgawalDT) && (vJamAkhirDT < vJamRgakhirDT) 
						)
					});

					let vStyleBGbox	= "#00008011";
					if(vFiltercontentArr.length > 0)
						if(uTokenObj.userhak==="DOKTER")
							if((vFiltercontentArr[0].dokter_id||"0").toString() === (uTokenObj.user_dokterid||"0").toString())
								vStyleBGbox = "#FE100111"

					return (
					<td className={"border text-center classfontsmaller "+(vFiltercontentArr.length <= 0 ? "align-middle" : "align-top")} key={vKeyruang}>
					{vFiltercontentArr.length <= 0 ? (
						<b className="text-light">RG-{vItemruang}</b>
					)//> 
					: (
					<>
					{vFiltercontentArr.map((vItems,vKeys)=>{
						const vPasienArr = (vItems.pasien_caption||"").split(" ");

						let vStatushadir		= (vItems.jam_hadir||"")==="" ? "BELUM" : "HADIR";
						if(UFunc.isEmpty(vItems.periksa_init)===false) vStatushadir = "PERIKSA";

						let vStatuscolor		= "secondary";
						let vKehadirancaption 	= "BELUM HADIR";
						let vStatusclass 		= "";
						let vStatustooltip		= "--Klik Untuk SET HADIR";
						let vActionstatus		= "Set KEHADIRAN";
						if(vStatushadir==="HADIR") {
							vStatuscolor 		= "primary";
							vKehadirancaption 	= "HADIR &middot; "+(vItems.jam_hadir||"00:00").substring(0,5);
							vStatustooltip		= "--Klik Untuk MEMBATALKAN Kehadiran";
							vActionstatus		= "BATALKAN Kehadiran";
						} else if(vStatushadir==="PERIKSA") {
							vStatuscolor 		= "warning";
							vKehadirancaption 	= "PERIKSA";
							vStatusclass 		= " classblinking";
							vStatustooltip		= "--Klik Untuk MEMBATALKAN PERIKSA";
							vActionstatus		= "BATALKAN PERIKSA";
						}

						let vISbirthday	= false;
						if((vItems.tgl_lahir||"")!=="") {
							const vTgllahirDT 	= UFunc.toDate(vItems.tgl_lahir);

							vISbirthday = vTgllahirDT.getDate()===vTglantriDT.getDate() &&
								vTgllahirDT.getMonth()===vTglantriDT.getMonth();
						}
						//vISbirthday	= true;//--TESTING--/

						return (
						<div className="px-1 py-2 position-relative "
							key={vKeys}
							style={{border:"3px dashed navy",borderRadius:7,backgroundColor:vStyleBGbox}}
							id={"idbox"+vItems.id}>

							<b className="text-info text-uppercase d-block pt-2">{(vPasienArr[0]+" "+(vPasienArr[1]||"")).trim()}</b>

							<div className="">
							({(vItems.jam_awal||"").substring(0,5)} - {(vItems.jam_akhir||"").substring(0,5)})
							</div>

							<div className="text-success fst-italic">{UFunc.Usercaptionfilter(vItems.dokter_caption,2)}</div>
							<small className="text-danger d-none">[{UFunc.Usercaptionfilter(vItems.inputlabel,1)}]</small>

							<div className="my-2 classborderbottom"/>

							<div>
								<CBadge color={vStatuscolor} className={"py-2 "+vStatusclass}>{UFunc.renderHTML(vKehadirancaption)}</CBadge>
								{vISbirthday && (
								<>
								<span className="mx-1">&middot;</span>
								<CTooltip className="d-none d-lg-block" content="--Pasien Berulang Tahun (Siapkan Kejutan..!)">
								<CImage src={pjson.homepage+"api\/images\/icon_birthday.png"} className="classikonbox mx-0 classblinking p-0" height={25}/>
								</CTooltip>
								</>
								)}
							</div>

							<CDropdown style={{top:2,right:5}} className="position-absolute">
							<CDropdownToggle caret={false} className="p-0" color="transparent">
								<CIcon icon="cilChevronBottom" className="me-0 classikonbox" height={23}/>
							</CDropdownToggle>
							<CDropdownMenu>
								<CDropdownItem onClick={()=>hdlKlikPasien(vItems.id)} className="classcursorpointer">
									{uTokenObj.userhak==="DOKTER" ? (
									<CImage src={pjson.homepage+"api/images/icon_rekammedis.png"} className="align-middle mx-0 me-2" height={25}/>
									) : (
									<CImage src={pjson.homepage+"api/images/icon_booking.png"} className="align-middle mx-0 me-2" height={25}/>
									)}
									Proses Reservasi
								</CDropdownItem>

								{uISHapusallow && (
								<>
								<CDropdownItem onClick={()=>hdlKlikKonfirmwa(vItems.id)} className="classcursorpointer">
									<CIcon icon="cibWhatsapp" className="classikonbox text-white bg-success p-1 mx-0 me-2" height={23}/>
									Konfirmasi Whatsapp
								</CDropdownItem>

								<CDropdownItem onClick={()=>hdlKlikStatus(vItems.id)} className="classcursorpointer">
									<CIcon icon="cilTouchApp" className="classikonbox mx-0 me-2" height={23}/>
									{vActionstatus}
								</CDropdownItem>

								<CDropdownDivider/>

								<CDropdownItem onClick={()=>hdlKlikHapus(vItems.id)} className="classcursorpointer">
									<CIcon className="classikonhapus align-middle mx-0 me-2" height={25}/>
									{uBahasaObj.caption_hapusantrian||"Hapus Antrian"}
								</CDropdownItem>
								</>
								)}
							</CDropdownMenu>
							</CDropdown>
						</div>
						)
						})}
						</>
						)}
					</td>
					);
				})}
				</tr>
				)
			})}
			</tbody>
			</table>
		</div>
		)//->
	}
	const contentDlgform = () => {
		if(!uISDlgformshow) return (<></>)//>

		if(uDlgformObj.dlg_jenis === "STATUSRSVP") return contentFormstatus();
		if(uDlgformObj.dlg_jenis === "KIRIMWA") return contentFormkonfirmwa();

		return (<></>)//>
	}
	const contentFormstatus = () => {
		if(uDlgformObj.dlg_jenis !== "STATUSRSVP") return (<></>)//>

		return (
		<CForm className="">
		<CRow className="my-2">
			<CCol className="d-none d-md-block">
				<span className="d-none d-md-block">{uBahasaObj.caption_confirmkehadiran||"Konfirmasi Kehadiran"}</span>
				<small className="d-md-none">{uBahasaObj.caption_confirmkehadiran||"Konfirmasi Kehadiran"}</small>
			</CCol>
			<CCol className="fst-italic d-none">
			<CFormCheck size="sm" label="KIRIMKAN ENCOUNTER ke SATUSEHAT"
				value="YA"
				className="d-none"
				checked={uDlgformObj.is_satusehat || false}
				onChange={(e)=>{
					uDlgformObj.is_satusehat = !(uDlgformObj.is_satusehat || false);
					setEfekview(!uEfekview);
				}}
				id="inpcheckkehadiran"/>
			</CCol>
		</CRow>

		<div className="my-3 d-none d-md-block classborderbottom"/>

		<CRow className="my-2">
			<CCol md="4" className="fw-bolder">
				<span className="d-none d-md-block">{uBahasaObj.caption_dokterrujukan||"Dokter Rujukan"}</span>
				<small className="d-md-none">{uBahasaObj.caption_dokterrujukan||"Dokter Rujukan"}</small>
			</CCol>
			<CCol>
			<CInputGroup size="sm">
			<CFormInput size="sm"
				placeholder="--Pilih Dokter Rujukan"
				value={uDlgformObj.dokter_caption||""}
				onChange={(e)=>{uDlgformObj.dokter_caption=e.target.value;setEfekview(!uEfekview)}}
				onKeyDown={(e)=>hdlKDownDoktercaption(e)}
				id="inpdoktercaption"/>
			<CButton size="sm" color="dark"
				onClick={()=>hdlKlikDokterlookup()}
				id="btndokterlookup">
				<CIcon icon="cilSearch"/>
			</CButton>
			</CInputGroup>
			</CCol>
		</CRow>

		</CForm>
		)//>
	}
	const contentFormkonfirmwa = () => {
		if(uDlgformObj.dlg_jenis !== "KIRIMWA") return (<></>)//>

		return (
		<CForm className="mx-lg-5">
			<div className="d-none">{JSON.stringify(uCabangselectedObj)}</div>
			<CRow className="my-2">
				<CCol md="3">No.Tujuan</CCol>

				<CCol md="5">
				<CInputGroup size="sm">
				<CInputGroupText>+62</CInputGroupText>
				<CFormInput size="sm"
					value={uDlgformObj.no_hp_pasien}
					onChange={(e)=>setDlgformObj({...uDlgformObj,no_hp_pasien:e.target.value})}
					id="inpnohptujuan"/>
				</CInputGroup>
				</CCol>
			</CRow>

			<CRow className="my-2">
				<CCol md="3">Isi Pesan</CCol>

				<CCol>
				<CFormTextarea size="sm"
					rows={7}
					value={UFunc.br2nl(uDlgformObj.isi_pesan)}
					onChange={(e)=>setDlgformObj({...uDlgformObj,isi_pesan: UFunc.nl2br(e.target.value)})}
					id="inpisipesan"/>
				<div className="classpetunjuk">
				*) {uBahasaObj.petunjuk_textarea}
				</div>
				</CCol>
			</CRow>

			<div className="my-3 classborderbottom"/>

			<CRow className="my-2">
				<CCol md="3">No.Sender</CCol>

				<CCol md="5">
				<CInputGroup size="sm">
				<CInputGroupText>+62</CInputGroupText>
				<CFormInput size="sm" readOnly={true}
					value={uDlgformObj.no_hp_sender}
					onChange={(e)=>setDlgformObj({...uDlgformObj,no_hp_sender:e.target.value})}
					onKeyDown={(e)=>UFunc.hdlKeyDownInput(e,"btnDialogSimpan")}
					id="inpnohpsender"/>
				</CInputGroup>
				</CCol>
			</CRow>
		</CForm>
		)//>
	}


	/*const contentDlgfullscreen = () => {//--TIDAK_DIPAKAI--/
		if(!uISDlgfullscreenshow) return (<></>)//>

		const vDataviewArr	= uDlgfullscreenshowObj.data_arr || [];
		const vTglantri		= uDlgfullscreenshowObj.tgl_ruangan || UFunc.DbDate();
		const vIsDoktershow	= (uDlgfullscreenshowObj.status_doktershow || "YA")==="YA";
		const vIsPerawatshow= (uDlgfullscreenshowObj.status_perawatshow || "TIDAK")==="YA";

		const vJmlruangan	= parseInt(uCabanginitObj.jml_ruangan) || 2;
		const vJamstartidx	= 8;		
		const vJamfinishidx	= 21;

		const vWidthno		= 50;
		const vWidthFirstkol= 175;
		const vWidthkolrg	= 220;

		const vWidthtabel	= vWidthno+vWidthFirstkol+(vWidthFirstkol*vJmlruangan)

		const vStylekolfixed = {
			position: "sticky",
			left: 0,
			background: "white",
			zIndex: 1,
		}

		return (
		<>
		<CCard className="classbgcard d-md-none">
		<CCardHeader className="fw-bolder">Invalid View</CCardHeader>
		<CCardBody>
			<p>Maaf,</p>
			<p className="fw-bolder">Data Antrian Ruang Hanya Dapat Dilihat Melalui Mode Tampilan Tablet.</p>
			<p>Saat Ini Mode Tampilan Anda adalah Mode Tampilan Celuler.<br/><br/>Mohon Ubah Mode Tampilan Anda !</p>
		</CCardBody>
		</CCard>

		<CCard className="classbgcard d-none d-md-block">
		<CCardHeader className="d-flex justify-content-between align-items-center">
			<div>
			<CForm className="d-inline-block">
			<CInputGroup
				style={{minWidth:295,width:"auto"}} 
				size="sm">
			<CFormInput size="sm" type="date"
				value={uDlgfullscreenshowObj.tgl_ruangan}
				onChange={(e)=>{uDlgfullscreenshowObj.tgl_ruangan=e.target.value;setEfekview(!uEfekview)}}
				id="inptglruangan"/>
			<CButton size="sm" color="dark" onClick={()=>hdlKlikViewruangan()} id="btnCariruangan">
				<CIcon icon="cilSearch"/>
			</CButton>
			</CInputGroup>
			</CForm>
			</div>

			<div className="classfontsmaller">
			<small>
			<CInputGroup size="sm">
			<span className="text-info d-none d-md-inline">{uCabanginitObj.nama}</span>

			<span className="mx-2 d-none d-md-inline">&middot;</span>

			<CFormCheck	value="YA" size="sm"
				label="Tampilkan DOKTER"
				checked={vIsDoktershow}
				onChange={(e)=>{uDlgfullscreenshowObj.status_doktershow=((uDlgfullscreenshowObj.status_doktershow||"YA")==="YA"?"TIDAK":"YA");setEfekview(!uEfekview);}}
				id="chbwithdokter"/>

			<span className="mx-2">&middot;</span>

			<CFormCheck	value="YA" size="sm"
				label="Tampilkan PERAWAT"
				checked={vIsPerawatshow}
				onChange={(e)=>{uDlgfullscreenshowObj.status_perawatshow=((uDlgfullscreenshowObj.status_perawatshow||"TIDAK")==="YA"?"TIDAK":"YA");setEfekview(!uEfekview);}}
				id="chbwithnurse"/>
			</CInputGroup>
			</small>
			</div>
		</CCardHeader>
		
		<CCardBody>
		<div className="d-none">vDataviewArr = {JSON.stringify(vDataviewArr)}</div>
		{(uDlgfullscreenshowObj.is_loading||false)===true ? (
			<div className="py-5 text-center">
			<CSpinner color="primary" className="mx-auto my-5"/>
			</div>
		) : (
			<div style={{overflowX:"auto"}}
				className=""
				id="iddivtabel">
			<table 
				style={{width:vWidthtabel,borderCollapse:"separate"}}
				className="table mx-auto" 
				id="idtabeldata">
			<thead>
				<tr className="d-none d-md-table-row text-center">
				<th width={vWidthno} className="bg-white" style={vStylekolfixed}>No</th>
				<th width={vWidthFirstkol} className="bg-white" style={{...vStylekolfixed,left:vWidthno}}>Waktu Antrian</th>
				{(() => {
					let vHTMLth = [];
					for (var vIdx=1;vIdx<=vJmlruangan;vIdx++){
						vHTMLth.push(<th width={vWidthkolrg} key={vIdx}
							className="">Ruang {vIdx}</th>)
					}
					return vHTMLth;
				})()}
				</tr>
			</thead>
			<tbody>
			{(() => {
			let vHTMLtr = [];
			let vNumber	= 0;
			for (var vIdxrow=vJamstartidx;vIdxrow<=vJamfinishidx;vIdxrow++){
				vNumber++;
				const vJamAwalDT	= UFunc.toDatetime(
					vTglantri+" "+UFunc.leadZero(vIdxrow)+":00:00"
					);
				const vJamAkhirDT	= UFunc.toDatetime(
					vTglantri+" "+UFunc.leadZero(vIdxrow+1)+":00:00"
					);
				vHTMLtr.push(
				<tr className="align-middle" style={{height:100}}>
				<td className="border text-end bg-light" style={vStylekolfixed}>{vNumber}.</td>
				<td className="border text-center bg-light" style={{...vStylekolfixed,left:vWidthno}}>
					{UFunc.JamMenit(vJamAwalDT)} - {UFunc.JamMenit(vJamAkhirDT)}
				</td>
				
				{(() => {
					let vHTMLtd = [];
					for (var vIdxkol=1;vIdxkol<=vJmlruangan;vIdxkol++){
						const vFiltercontentArr = vDataviewArr.filter(vItems=>{
							const vJamRgawalDT = UFunc.toDatetime(
								vTglantri+" "+vItems.jam_awal
								);
							const vJamRgakhirDT = UFunc.toDatetime(
								vTglantri+" "+vItems.jam_akhir
								);

							return (parseInt(vItems.no_ruang||0)===vIdxkol)
							&& ((vJamRgawalDT >= vJamAwalDT) && (vJamRgawalDT < vJamAkhirDT) 
								|| (vJamRgakhirDT <= vJamAkhirDT) && (vJamRgakhirDT > vJamAwalDT) 
								|| (vJamAkhirDT > vJamRgawalDT) && (vJamAkhirDT < vJamRgakhirDT) 
							)
						});

						let vStyleBGbox	= "#00008011";
						if(vFiltercontentArr.length > 0)
						if(uTokenObj.userhak==="DOKTER")
							if((vFiltercontentArr[0].dokter_id||"0").toString() === (uTokenObj.user_dokterid||"0").toString())
								vStyleBGbox = "#FE100111"

						vHTMLtd.push(
						<td className={"border text-center "+(vFiltercontentArr.length <= 0 ? "align-middle" : "align-top")}>
						{vFiltercontentArr.length <= 0 && (
							<b className="text-light">RG-{vIdxkol}</b>
						)}
						{vFiltercontentArr.map((vItems,vKeys)=>{
							const vPasienArr = (vItems.pasien_caption||"").split(" ");
							return (
							<div className="px-1 py-1 classfontsmaller text-center classcursorpointer"
								onClick={()=>hdlKlikRuangpasien(vItems.id)}
								key={vKeys}
								style={{border:"3px dashed navy",borderRadius:7,backgroundColor:vStyleBGbox}}
								id={"idbox"+vItems.id}>
							<b className="text-primary">{(vPasienArr[0]+" "+(vPasienArr[1]||"")).trim()}</b>

							<div className="text-danger">
							({(vItems.jam_awal||"").substring(0,5)} - {(vItems.jam_akhir||"").substring(0,5)})
							</div>

							{vIsDoktershow && (
							<div className="text-success fst-italic">{UFunc.Usercaptionfilter(vItems.dokter_caption,3)}</div>
							)}

							{(vIsPerawatshow && (vItems.perawat_caption||"").trim()!=="") && (
							<div className="">&middot;&middot; {vItems.perawat_caption} &middot;&middot;</div>
							)}
							</div>
							)
						})}
						</td>
						);
					}
					return vHTMLtd;
				})()}
				</tr>
				);
			}
			return vHTMLtr;
			})()}
			</tbody>
			</table>
			</div>
		)}
		</CCardBody>
		</CCard>
		</>
		)//>
	}//-*/
	//--END CONTENT--/

	//--API--/
	const apiLoaddata = () => {
		setHtml500msg();

		setDatamainArr([]);
		setIDselect(0);
		setRowumumselect(-1);
		setRowantrianselect(-1);
		setLoading(true);

		/*//--TESTING_FRONTEND--/
		console.log("(Antrianpasien - apiLoaddata) uTglAntri => "+uTglAntri);
		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout);
			setLoading(false);
		},2000); return
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_cabangid : uCabangid,
			send_tglantri : uTglAntri,
			send_firstload: uFirstload,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_antrian/ld_data";

		setTimeelapse(0);
		const vTimeBegin = new Date();
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) {setSessionaktif(false); return response.json();}})
		.then((output_string) => {
			setSessionaktif(true);
			setLoading(false);
			if(output_string.tampil) {
				const vTmpArr = JSON.parse(output_string.dataobject||"[]");
				setDatamainArr(vTmpArr);
				
				const vTmpObj 	= JSON.parse(output_string.cabangatribut || JSON.stringify(uCabangselectedObj));
				setCabanginitObj(vTmpObj);

				if(JSON.stringify(uLastinitObj||{}) !== "{}") 
					setInitselectedObj(uLastinitObj);

				if(uFirstload == "YA") {
					const vJamantristr	= JSON.stringify(uDatajamantriArr);
					setDatajamantriArr(JSON.parse(output_string.datajamantri || vJamantristr));
				}

				setFirstload("TIDAK");
				const vTimeEnd = new Date();
				const vTimeDiff = vTimeEnd - vTimeBegin; //in ms
				setTimeelapse(vTimeDiff);
			} else if(output_string.info) {
				setHtml500msg(output_string.info);
			} else if(output_string.errors) {
				console.log("(Antrianpasien - apiLoaddata) output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				setHtml500msg("<div class='text-danger'>"+vMsg+"</div>");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			setLoading(false);
			console.log("(Antrianpasien - apiLoaddata) catch-error : "+error);
			setHtml500msg(pjson.mydefault.msg500str.join(" "));
		});
	}
	const apiLoaddokter = () => {
		return new Promise((onResolve,onReject)=>{
			setLoading(true);

			/*//--TESTING_FRONTEND--/
			console.log("(Antrianpasien - apiLoaddata) uTglAntri => "+uTglAntri);
			let vTimeout = setTimeout(()=>{
				clearTimeout(vTimeout);
				setLoading(false);
			},2000); return
			//--END TESTING_FRONTEND--*/

			const vDATAS	= JSON.stringify({
				send_tokenauth : uTokenObj.userinit
			});
			const vURLs	= pjson.homepage+"api/api_dokter/ld_dokterlookup";

			fetch(vURLs,{
				headers: { Accept: "application/json", "Content-Type": "application/json" },
				method: "POST",
				mode: "cors",
				cache: "default",
				body: vDATAS,
			}).then((response)=> { if (response.status === 200) {setSessionaktif(false); return response.json();}})
			.then((output_string) => {
				setSessionaktif(true);
				setLoading(false);
				if(output_string.tampil) {
					setDatadokterArr(JSON.parse(output_string.datadokter || JSON.stringify(uDatadokterArr)));
					onResolve();
				} else if(output_string.info) {
					setHtml500msg(output_string.info);
				} else if(output_string.errors) {
					console.log("(Antrianpasien - apiLoaddokter) output_string.errors : "+output_string.errors);
					const vMsg = (pjson.mydefault.environment==="development")
						? output_string.errors : pjson.mydefault.msgFetchError;
					showToast(vMsg,"ERROR");
				} else if(output_string.expired) {
					setLoading(true); prosesExpired();
				}
			})
			.catch((error) =>{
				setLoading(false);
				console.log("(Antrianpasien - apiLoaddokter) catch-error : "+error);
				showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
				onReject(error);
			});
		});
	}
	/*const apiLoadruangan = () => {//--TIDAK_DIPAKAI--/
		const vElbutton	= document.getElementById("btnCariruangan");
		uDlgfullscreenshowObj.data_arr 		= [];
		uDlgfullscreenshowObj.is_loading 	= true;
		vElbutton && (vElbutton.disabled = true);

		const vDATAS	= JSON.stringify({
			send_tglantri : uDlgfullscreenshowObj.tgl_ruangan || uTglAntri,
			send_cabangid : uCabangid || uTokenObj.user_cabangid,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_antrian/ld_janjian";

		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200){setSessionaktif(false);return response.json(); }})
		.then((output_string) => {
			setSessionaktif(true);
			uDlgfullscreenshowObj.is_loading 	= false;
			vElbutton && (vElbutton.disabled = false);
			if(output_string.tampil) {
				const vTmpArr 					= JSON.parse(output_string.dataobject||"[]");
				uDlgfullscreenshowObj.data_arr 	= vTmpArr;

				setEfekview(!uEfekview);
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("(Antrianpasien - apiLoadruangan) output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			uDlgfullscreenshowObj.is_loading 	= false;
			vElbutton && (vElbutton.disabled = false);
			console.log("(Antrianpasien - apiLoadruangan) catch-error : "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}//-*/
	const apiProseskirimwa = () => {
		const vTabelID	= parseInt(uDlgformObj.tabelid)||0; if(vTabelID <= 0) return;
		const vArridx	= UFunc.getObjectIndeks(uDatamainArr,vTabelID);

		//alert("(uDlgformObj.no_hp_pasien) "+uDlgformObj.no_hp_pasien); return;
		const vElbutton	= document.getElementById("btnDialogSimpan");
		vElbutton && (vElbutton.disabled = true);
		setLoading(true)

		/*//--TESTING_FRONTEND--/
		let vTimeout = setTimeout(()=>{ clearTimeout(vTimeout); 
			setLoading(false)
				showToast("<b>BERHASIL</b>.."+
					"<br/>Konfirmasi WA Berhasil Dikirim !"+
					"<div class='classborderbottom my-2'></div>"+
					"<div>"+
					"<pre>"+JSON.stringify(uDlgformObj,undefined,2)+"</pre>"+
					"</div>"+
					"","SUKSES");
				setDlgformshow(false);
		},2000); return
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_datajson : JSON.stringify({...uDlgformObj,
				cabangid: uCabangid,
				modulname: "Antrianpasien",
				dlg_headers:undefined,
				dlg_jenis:undefined,
				dlg_size:undefined
			}),
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_kirimwa/pr_kirimwa";

		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) {setSessionaktif(false); return response.json();}})
		.then((output_string) => {
			setSessionaktif(true);
			vElbutton && (vElbutton.disabled = false);
			setLoading(false);
			if(output_string.sukses) {
				showToast("<b>BERHASIL</b>.."+
					"<br/>Konfirmasi WA Berhasil Dikirim !"+
					"<div class='classborderbottom my-2'></div>"+
					"<pre>"+JSON.stringify(JSON.parse(output_string.response_str||"{}"),undefined,2)+"</pre>"+
					"","SUKSES");
				setDlgformshow(false);
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("(Antrianpasien - apiProseskirimwa) output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			vElbutton && (vElbutton.disabled = false);
			setLoading(false);
			//setDlgformshow(false);
			console.log("(Antrianpasien - apiProseskirimwa) catch-error : "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	const apiProseshadir = () => {
		//_IDTABEL 		= parseInt(_IDTABEL)||0; if(_IDTABEL <= 0) return;
		const vTabelID	= parseInt(uDlgformObj.tabelid)||0; if(vTabelID <= 0) return;
		const vArridx	= UFunc.getObjectIndeks(uDatamainArr,vTabelID);

		//console.log("(Antrianpasien - apiProseshadir) vTabelID : "+vTabelID);
		let vAccesstoken	= "";
		/*if(((uDatamainArr[vArridx]||{}).ihs_pasien ||"") !== ""
			&& (uDlgformObj.dokter_ihs||"") !== "" 
			&& (uDlgformObj.is_satusehat||false)===true
		) {
			vAccesstoken 		= uTokensatusehatObj.access_token;
		
	 		if(!vAccesstoken) {
	 			loadToken(apiProseshadir); return;
	 		}
			const vLimitedsec		= parseInt(uTokensatusehatObj.expires_in || "0");
			const vTimestampnow 	= new Date();
			const vTimetoken		= new Date(parseInt(uTokensatusehatObj.issued_at || "0"));
			const vSelisih			= UFunc.Datetimeselisih(vTimetoken,vTimestampnow).dtk;

			if(vSelisih > vLimitedsec) {
	 			loadToken(apiProseshadir); return;
			}

			uDlgformObj.ihs_pasien 		= (uDatamainArr[vArridx]||{}).ihs_pasien ||"";
			uDlgformObj.caption_pasien 	= (uDatamainArr[vArridx]||{}).caption_pasien ||"";
		}//-*/

		uDlgformObj.status_satusehat = vAccesstoken!=="" ? "YA" : "TIDAK";

		const vElbutton	= document.getElementById("btnDialogSimpan");
		vElbutton && (vElbutton.disabled = true);
		setLoading(true)

		/*//--TESTING_FRONTEND--/
		let vTimeout = setTimeout(()=>{
		},2000); return
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_tabelid : vTabelID,
			send_cabangid : uCabangid,
			send_accesstoken : vAccesstoken,
			send_datajson : JSON.stringify({...uDlgformObj,
				dlg_headers: undefined,
				dlg_size: undefined,
			}),
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_antrian/pr_hadir";//--SEKALIAN_ENCOUNTER_SATUSEHAT--/

		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) {setSessionaktif(false); return response.json();}})
		.then((output_string) => {
			setSessionaktif(true);
			vElbutton && (vElbutton.disabled = false);
			setLoading(false);
			if(output_string.sukses) {
				uDatamainArr[vArridx].jam_hadir = (uDatamainArr[vArridx].jam_hadir||"") === ""
					? UFunc.JamMenit() : "";
			
				setDlgformshow(false);
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("(Antrianpasien - apiProseshadir) output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			vElbutton && (vElbutton.disabled = false);
			setLoading(false);
			console.log("(Antrianpasien - apiProseshadir) catch-error : "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	const apiProsesbatalperiksa = (_IDTABEL) => {
		_IDTABEL = parseInt(_IDTABEL)||0; if(_IDTABEL <= 0) return;

		const vArridx	= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL)
		setLoading(true)

		/*//--TESTING_FRONTEND--/
		let vTimeout = setTimeout(()=>{
		},2000); return
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_tabelid : uDatamainArr[vArridx].pasien_id || 0,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_pasien/pr_prosesbatal";

		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200){setSessionaktif(false);return response.json(); }})
		.then((output_string) => {
			setSessionaktif(true);
			setLoading(false);
			if(output_string.sukses) {
				uDatamainArr[vArridx].periksa_init = "";
			
				setEfekview(!uEfekview);
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("(Antrianpasien - apiProsesbatalperiksa) output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			setLoading(false);
			console.log("(Antrianpasien - apiProsesbatalperiksa) catch-error : "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	const apiProseshapus = (_IDTABEL) => {
		_IDTABEL = parseInt(_IDTABEL)||0; if(_IDTABEL <= 0) return;

		const vArridx	= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL)
		setLoading(true)

		/*//--TESTING_FRONTEND--/
		let vTimeout = setTimeout(()=>{
			setEfekview(!uEfekview);
		},2000); return
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_tabelid : _IDTABEL,
			send_cabangid : uCabangid,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_antrian/pr_hapus";

		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) {setSessionaktif(false); return response.json(); }})
		.then((output_string) => {
			setSessionaktif(true);
			setLoading(false);
			if(output_string.sukses) {
				uDatamainArr.splice(vArridx,1);
				setRowumumselect(-1);
				setRowantrianselect(-1);
			
				setEfekview(!uEfekview);
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("(Antrianpasien - apiProseshapus) output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			setLoading(false);
			console.log("(Antrianpasien - apiProseshapus) catch-error : "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	//--END API--/

	React.useEffect(()=>{
		uDispatch({type: "set", effectApp: !uEfekapp});
		uDispatch({type: "set", gUrlbefore: window.location.pathname + window.location.search});
		uDispatch({type: "set", gBillingperiksaObj: undefined});

    	apiLoaddata();

    	return () => {
			uDispatch({type: "set", gInitHeaderAction: {}});
    		setDatamainArr([]);setDatadokterArr([]);setInitselectedObj({});
    		setDlgformshow(false);setDlglookupshow(false);
    		setFirstload("YA");
    		setRuangshow(uInitshowruang ? (uInitshowruang==="YA" ? true : false) : (uTokenObj.userhak==="ENTRI" ? true : false));
    	}
	},[])
	React.useEffect(()=>{
		if(!uHandelView) return;

		apiLoaddata();

		/*const vIntervalMS	= 1 * 60 * 1000;
		const vTimeinterval	= setInterval(()=>{ 
			console.log("(Antrianpasien - [uHandelView]) vTimeinterval 1");
			if(uTglNow === uTglAntri) {
				console.log("(Antrianpasien - [uHandelView]) vTimeinterval 2");
				apiLoaddata(); 
			}
		},vIntervalMS)//-*/

		uHeaderActionObj.isHeaderView = false;
		uDispatch({type: "set", gInitHeaderAction: uHeaderActionObj});
	},[uHandelView])
	React.useEffect(()=>{
		if(!uHandelToggle) return;

		uHeaderActionObj.isHeaderToggle = false;
		uDispatch({type: "set", gInitHeaderAction: uHeaderActionObj});

		const vValueinit = uISRuangshow ? "TIDAK" : "YA";
		uDispatch({type: "set", gInitshowruang: vValueinit});

		setRuangshow(!uISRuangshow);
	},[uHandelToggle])
	React.useEffect(()=>{
		uDispatch({type: "set", gInitTabelSelected: {}});
		if(JSON.stringify(uInitselectedObj||{}) === "{}") return;

		let vRowidx	= UFunc.getTabelRowIndeks("idtabelumum","idtr"+(uInitselectedObj.initID||0))
		if(vRowidx >= 0)
			setRowumumselect(vRowidx);
		else {
			vRowidx	= UFunc.getTabelRowIndeks("idtabeljanjian","idtr"+(uInitselectedObj.initID||0))
			setRowantrianselect(vRowidx);
		}

		const vElscroll	= document.getElementById("idtr"+(uInitselectedObj.initID||0));
		const vRectdimensi = vElscroll && (vElscroll.getBoundingClientRect());
		vRectdimensi && (window.scrollTo({ top: (vRectdimensi.top||0) - 150, behavior: "smooth" }))
		//console.log("(Pasien - [uInitselectedObj]) vRectdimensi => "+JSON.stringify(vRectdimensi))

		setInitselectedObj({});
	},[uInitselectedObj])
	React.useEffect(()=>{
		if(!uISDlgformshow) { setDlgformObj({}); return }

		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout);

			if(!isMobile) {
				document.getElementById("inpdoktercaption") &&
					document.getElementById("inpdoktercaption").focus();
			}
		},350);
	},[uISDlgformshow])
	React.useEffect(()=>{
		if(!uISDlglookupshow) { setDlglookupObj({}); return }
	},[uISDlglookupshow])

	//console.log("Antrianpasien - uPageActive => "+JSON.stringify(uPageActive));

	if(UFunc.isEmpty(uTokenObj)) { prosesExpired(); return ""; }
	if(uHtml500msg)  return (
		<CCard className="classbgcard mb-3">
		<CCardHeader>
		<strong>{uBahasaObj.caption_page500||"Page 500"}</strong>
		</CCardHeader>
		<CCardBody>{UFunc.renderHTML(uHtml500msg)}</CCardBody>
		</CCard>
	)

	return (
		<>
		<div className="d-none">{JSON.stringify(uSystemsettingObj)}</div>
		<div style={{margin: (uISMobilescreen ? "" : "auto -30px")}}>
		{uISRuangshow ? (
		<CRow>
		<CCol className="px-lg-0">
			<CCard className="classbgcard mb-3">
			{contentCardheaderjanjian()}

			<CCardBody className="px-0">{contentRuangjanjian()}</CCardBody>
			</CCard>

			<CCard className="classbgcard">
			{contentCardheaderumum()}

			<CCardBody className="px-0">{contentRuangumum()}</CCardBody>
			</CCard>
		</CCol>
		</CRow>
		) : (
		<CRow>
		<CCol xs="12" lg="7" className="px-lg-0 pe-lg-1">
			<CCard className="classbgcard mb-3">
			{contentCardheaderjanjian()}

			<CCardBody className="px-0">{contentDatajanjian()}</CCardBody>
			</CCard>
		</CCol>

		<CCol className="px-lg-0 ps-lg-1">
			<CCard className="classbgcard">
			{contentCardheaderumum()}

			<CCardBody className="px-0">{contentDataumum()}</CCardBody>
			</CCard>
		</CCol>
		</CRow>
		)}
		</div>

		<MyDialoglookup
			show={uISDlglookupshow}
			toggle={()=>setDlglookupshow(false)}
			dialogHeader={uDlglookupObj.dlg_headers||"Lookup Data"}
			inputValue={uDlglookupObj.inputvalue||""}
			dataItemArr={uDlglookupObj.items_arr||[]} 
			onKlikPilihItem={hdlKlikDlglookuppilih}
			id="iddialoglookup"
			{...props}/>

		<MyDialogform
			options={{size:uDlgformObj.dlg_size,centered:false}}
			onSimpan={()=>hdlKlikDlgformsimpan()}
			show={uISDlgformshow} 
			dialogHeader={uDlgformObj.dlg_headers}
			toggle={()=>setDlgformshow(false)}
			renderContent={contentDlgform()}/>
		</>
	)//>
}	

export default Antrianpasien