import React from 'react'
import { NavLink,useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import {
	CContainer,
	CHeader,
	CHeaderDivider,
	CImage,
	CRow,
	CCol,
	CForm,
	CFormSelect,
	CButton,
	CFormInput,
	CInputGroup,
	CInputGroupText,
	CLink,
	CTooltip,
	CDropdown,CDropdownToggle,CDropdownMenu,CDropdownItem,
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { Konfirm } from '../../helpers/onConfirm'
import { UFunc } from '../../helpers/functions'

const pjson 	= require('../../../package.json')
const Headersubantrianpasien = (props) => {
	const { showToast } = props 

	//--DOM--/
	const uNavigate 			= useNavigate()
	const uDispatch 			= useDispatch()
	//--END DOM--/

	const uHeaderActionObj  	= useSelector(state => state.gInitHeaderAction)
	const uBahasaObj  			= useSelector(state => state.listBahasa)
	const uActiveroute			= useSelector(state => state.activeRoute)
	const uKeywordObj			= JSON.parse(localStorage.getItem("listkeyword")) || {};
	const uTokenObj				= JSON.parse(localStorage.getItem("token")||"{}");
	const uEfekapp				= useSelector(state => state.effectApp);
	const uDatacabangArr		= JSON.parse(localStorage.getItem("cabanglist")||"[]")
	const uCabangfilterArr		= uTokenObj.userhak==="FREEROLE" ? uDatacabangArr
		: uDatacabangArr.filter(vItems=>(uTokenObj.user_cabangarr || []).includes(vItems.id.toString()))
	
	const vFilterview			= uKeywordObj.filter_antrianpasien || {};
	const [uInpCabangid,setInpCabangid]	= React.useState(vFilterview.cabang_id||uTokenObj.user_cabangid);
	const [uInpTglantri,setInpTglantri]	= React.useState(vFilterview.tgl_antri||UFunc.DbDate());
	const [uISFirstload,setFirstload]	= React.useState(true);

	const hdlKlikPev = () => {
		const vTglantriDT	= UFunc.toDate(uInpTglantri);
		vTglantriDT.setDate(vTglantriDT.getDate() - 1);

		setInpTglantri(UFunc.DbDate(vTglantriDT));
	}
	const hdlKlikNext = () => {
		const vTglantriDT	= UFunc.toDate(uInpTglantri);
		vTglantriDT.setDate(vTglantriDT.getDate() + 1);

		setInpTglantri(UFunc.DbDate(vTglantriDT));
	}
	const hdlKlikReset = async() => {
		const vProses = () => {
			//---CLEAR_KEYWORD--/
			uKeywordObj.filter_antrianpasien = undefined; 
	    	localStorage.setItem("listkeyword",JSON.stringify(uKeywordObj));
	    	setInpTglantri(UFunc.DbDate());
	    	setInpCabangid(uTokenObj.user_cabangid);
			//---END CLEAR_KEYWORD--/
			
			//setHeaderView(true);

			uHeaderActionObj.isHeaderView = true;
			uDispatch({type: "set", gInitHeaderAction: uHeaderActionObj});
			uDispatch({type: "set", effectApp: !uEfekapp});
		}

		if(uInpTglantri !== UFunc.DbDate()
			|| uInpCabangid !== uTokenObj.user_cabangid
		) {
			if(await Konfirm(uBahasaObj.confirm_reset||"Apakah Yakin Akan Direset ?")) vProses();
		} else vProses();	
	}
	const hdlKlikToggleruang = () => {
		uHeaderActionObj.isHeaderToggle = true;
		uDispatch({type: "set", gInitHeaderAction: uHeaderActionObj});
		uDispatch({type: "set", effectApp: !uEfekapp});
	}
	const initProsestanggal = () => {
		const vTglnowDT		= UFunc.toDate();
		const vTglantriDT	= UFunc.toDate(uInpTglantri);

		const vElButtonback	= document.getElementById("btnPrev");
		if(UFunc.DbDate(vTglantriDT) <= UFunc.DbDate(vTglnowDT))
			vElButtonback && (vElButtonback.disabled = true);
		else 
			vElButtonback && (vElButtonback.disabled = false);

		if(UFunc.DateIsMundur(vTglantriDT)) {
			showToast("<b>Tanggal Antrian Sudah Expired</b>.."+
				"<br/>Mohon Diperbaiki !"+
				"")
			return;
		}
		if(parseInt(uInpCabangid) <= 0) {
			showToast("[] UNDEFINED.."); return
		}

		//---SAVE_KEYWORD--/
		uKeywordObj.filter_antrianpasien = {
			cabang_id: uInpCabangid,
			tgl_antri: uInpTglantri,
		}
    	localStorage.setItem("listkeyword",JSON.stringify(uKeywordObj));
		//---END SAVE_KEYWORD--/

		uHeaderActionObj.isHeaderView = true;
		uDispatch({type: "set", gInitHeaderAction: uHeaderActionObj});
		uDispatch({type: "set", effectApp: !uEfekapp});
	}

	React.useEffect(()=>{
		setFirstload(false);

    	return () => { setFirstload(true); }
	},[])
	React.useEffect(()=>{ initProsestanggal() },[uInpTglantri,uInpCabangid])

	//console.log("Headersubantrianpasien - uActiveroute => "+(uActiveroute));
	return (
		<>
		<div className="d-none d-lg-block">
		<CImage src={pjson.homepage+"api\/images\/menus_"+(uActiveroute||"").toLowerCase()+".png"} 
			className="me-2"
			height={30}/>
		{uBahasaObj["menus_"+(uActiveroute||"").toLowerCase()]||""}
		</div>

		<CForm className="d-flex justify-content-end"
			style={{
				minWidth:((uTokenObj.userhak==="FREEROLE" 
						|| uTokenObj.userhak==="ADMIN")
					&& uDatacabangArr.length > 1
					) ? 300 : 200,
				width:"auto"
			}}
			id="idformfilter">
			{uDatacabangArr.length > 1 && (
			<>
				{(uTokenObj.userhak==="FREEROLE" || uTokenObj.userhak==="ADMIN") && (
				<>
				<CFormSelect size="sm"
					className="d-none d-md-inline"
					value={uInpCabangid} 
					onChange={(e) => setInpCabangid(e.target.value)} 
					id="inpcabangid">
					{uCabangfilterArr.map((vItems,vKeys)=>{
						return (<option value={vItems.id} key={vKeys}>{vItems.cabang_nama.toUpperCase()}</option>)
					})}
				</CFormSelect>

				<CDropdown className="d-md-none">
					<CDropdownToggle caret={true} className="px-1 border fw-bolder" color="light">
						{(uDatacabangArr.filter(vItems => 
							parseInt(vItems.id) === parseInt(uInpCabangid)
							)[0].cabang_kode || "").toUpperCase()
						}
					</CDropdownToggle>
					<CDropdownMenu>
						{uCabangfilterArr.map((vItems,vKeys)=>{
							return (
							<CDropdownItem key={vKeys} onClick={()=>setInpCabangid(vItems.id)} className="classcursorpointer">
								{vItems.cabang_kode.toUpperCase()}
								<span className="mx-1">-</span>
								{vItems.cabang_nama.toUpperCase()}
							</CDropdownItem>
							)
						})}
					</CDropdownMenu>
				</CDropdown>

				<span className="ms-1"/>
				</>
				)}
			</>
			)}

			<CInputGroup style={{minWidth:200,maxWidth:225,width:"auto"}}
				className=""
				size="sm">
			<CButton size="sm" color="light" className="border-secondary"
				onClick={()=>hdlKlikPev()} id="btnPrev">
				<CIcon icon="cilChevronLeft"/>
			</CButton>
			<CFormInput size="sm" type="date"
				className="w-50"
				value={uInpTglantri} 
				onChange={(e) => setInpTglantri(e.target.value)} 
				id="inptglantri"/>
			<CButton size="sm" color="light" className="border-secondary"
				onClick={()=>hdlKlikNext()} id="btnCari">
				<CIcon icon="cilChevronRight"/>
			</CButton>
			</CInputGroup>
		</CForm>

		<div className="ms-1">
			<CTooltip className="d-none d-lg-block" content="--Toggle Display Mode..">
			<CLink onClick={()=>hdlKlikToggleruang()} className="classcursorpointer d-none d-md-inline-block">
				<CImage src={pjson.homepage+"api\/images\/menus_antrianruang.png"} className="classikonbox" height={30}/>
			</CLink>
			</CTooltip>
			
			<span className="mx-1 d-none d-lg-inline">&middot;</span>

			<CTooltip className="d-none d-lg-block" content={"--"+(uBahasaObj.caption_resetdata||"Reset View Tampilan")}>
				<CLink onClick={()=>hdlKlikReset()} className="classikon classikonreset classcursorpointer" />
			</CTooltip>
		</div>
		</>
	)//->
}

export default Headersubantrianpasien